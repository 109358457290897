<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Track record Section</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="updateconference">
                <div class="form-group">
                  <label for="welcomeTitle">Conference Title:</label>
                  <input v-model="updatedData.trackrecord_title" id="welcomeTitle" type="text" class="form-control bordered-input" />
                </div>
                <div class="form-group">
                  <label for="mainTitle">Main Title:</label>
                  <input v-model="updatedData.trackrecord_sub_title" id="mainTitle" type="text" class="form-control bordered-input" />
                </div>
                <div class="form-group">
                  <label for="mainTitle">Main Sub Title:</label>
                  <input v-model="updatedData.trackrecord_description" id="mainTitle" type="text" class="form-control bordered-input" />
                </div>


                <!-- Add other form fields as needed -->

                <div class="form-group">
                  <label for="iconFile1">Icon File 1 (Image size 996 x 166 px):</label>
                  <input type="file" @change="handleFileUpload('imageFile', $event)" class="form-control bordered-input" />
                  <img v-if="imageSource1" :src="imageSource1" class="mt-2" style="max-width: 100%; max-height: 150px;" />
                </div>
                <div class="form-group">
                  <label for="iconFile2">Icon File 2 (Image size 996 x 166 px):</label>
                  <input type="file" @change="handleFileUpload('imageFile2', $event)" class="form-control bordered-input" />
                  <img v-if="imageSource2" :src="imageSource2" class="mt-2" style="max-width: 100%; max-height: 150px;" />
                </div>
                <div class="form-group">
                  <label for="bgFile">Background File (Background size 1440 x 645 px):</label>
                  <input type="file" @change="handleFileUpload('imageFile3', $event)" class="form-control bordered-input" />
                  <img v-if="imageSource3" :src="imageSource3" class="mt-2" style="max-width: 100%; max-height: 150px;" />
                </div>

                <button type="submit" class="btn btn-outline-danger">Update Data</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';
const baseURL = "https://api2.logizall.com";
const token = localStorage.getItem('token');
const trackrecordData = ref({});
const imageSource1 = ref('')
const imageSource2 = ref('')
const imageSource3 = ref('')
const updatedData = reactive({
  trackrecord_title: '',
  trackrecord_sub_title: '',
  trackrecord_description: '',
  imageFile: null,
  imageFile2: null,
  imageFile3: null,
  // Add other form fields as needed
});

const getconferenceData = async () => {
  try {
    const response = await axios.get(`${baseURL}/landingpage/trackrecord/gettrackrecord`);
    trackrecordData.value = response.data[0];
    updatedData.trackrecord_title = trackrecordData.value.trackrecord_title;
    updatedData.trackrecord_sub_title = trackrecordData.value.trackrecord_sub_title;
    updatedData.trackrecord_description = trackrecordData.value.trackrecord_description;
    imageSource1.value = `${baseURL}/landingpage/trackrecord/images/${trackrecordData.value.icon_image_1}`
    imageSource2.value = `${baseURL}/landingpage/trackrecord/images/${trackrecordData.value.icon_image_2}`
    imageSource3.value = `${baseURL}/landingpage/trackrecord/images/${trackrecordData.value.bg_image_url}`
    
    // Assign other data fields as needed
    //console.log(trackrecordData.value);
  } catch (error) {
    console.error('Error fetching trackrecord data:', error);
  }
};

const handleFileUpload = (type, event) => {
  //console.log(event.target.files[0]);
  updatedData[type] = event.target.files[0];
  //console.log(updatedData[type]);
  //console.log("icon_image_1", updatedData[type]);
  //console.log("icon_image_11", updatedData.imageFile);
};

const updateconference = async () => {
  //console.log("icon_image_11", updatedData.imageFile);
  try {
    const formData = new FormData();
    formData.append('trackrecord_title', updatedData.trackrecord_title);
    formData.append('trackrecord_sub_title', updatedData.trackrecord_sub_title);
    formData.append('trackrecord_description', updatedData.trackrecord_description);
    formData.append('icon_image_1', updatedData.imageFile);
    formData.append('icon_image_2', updatedData.imageFile2);
    formData.append('bg_image_url', updatedData.imageFile3);
    // Add other form fields as needed
    //console.log("formData", formData);
    const response = await axios.put(`${baseURL}/landingpage/trackrecord/updatetrackrecord`, formData, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    //console.log(response.data.message);

    if (response.data.message === "Data updated successfully") {
      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Update Successful!',
        text: response.data.message,
      });

      // Refresh data after successful update
      getconferenceData();
    } else {
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: response.data.message,
      });
    }
  } catch (error) {
    console.error('Error updating trackrecord data:', error);
  }
};

onMounted(() => {
  getconferenceData();
});
</script>


<style scoped>
/* Add additional styles as needed */
.card {
  margin-top: 20px;
}
.bordered-input {
    border: 1px solid #b6b6b6;
  }
</style>
