<template>
  <span
    >Welcome to <b>Admin DashBoard</b> </span
  >
</template>

<script>
export default {
  name: "notification-template",
};
</script>
