<template>
  <div class="row">
    <div class="col-md-12">
      <Card :title="table1.title" :subTitle="table1.subTitle">
        <div slot="raw-content" class="table-responsive">
         
          <div class="mb-3 d-flex justify-content-end">

            <form class="form-inline">
              <div class="form-group mr-3">
                <v-select v-model="selectedOption" :options="filteredOptions" label="company_name_en"
                  placeholder="Select account" @input="handleChange" style="width: 250px;"></v-select>
              </div>

              <div class="form-group mr-3">
                <select v-model="selectedFilter"
                  class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                  style="width: 200px; height: 38px;">
                  <option value="selected_month">Selected Month</option>
                  <option value="selected_year">Selected Year</option>
                  <option value="selected_day">Selected Day</option>

                </select>
              </div>

              <div class="form-group mr-3">
                <div v-if="selectedFilter === 'selected_day'">
                  <input type="date" v-model="searchQuery" placeholder="Select Date..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" />
                </div>

                <div v-else-if="selectedFilter === 'selected_month'">
                  <input type="month" v-model="searchQuery" placeholder="Select Month..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" />
                </div>
                <div v-else-if="selectedFilter === 'selected_year'">
                  <input type="number" v-model="searchQuery" placeholder="Select year..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" />
                </div>
              </div>

              <button @click="search(selectedFilter, searchQuery)" class="btn btn-outline-danger mr-2 ">
                <i class="ti-search"></i> Search
              </button>
              <button @click="reload()" class="btn btn-outline-info mr-2 ">
                <i class="ti-reload"></i>
              </button>

              <!-- <button
                  @click="getDataAndGenerateFpsPDF"
                  class="btn btn-outline-default mr-2 "
                >
                  <i class="ti-printer"></i> Print Report
                </button> -->
            </form>
          </div>
          <table class="table table-bordered">
            <thead class="bg-light  bold-text">
              <tr>
                <th class="py-2 pl-6" style="font-weight: bold;">#</th>
                <th class="py-2 pl-6" style="font-weight: bold;">Reference Number</th>
                
                <th class="py-2 pl-6" style="font-weight: bold;">Company</th>
                <th class="py-2 pl-6" style="font-weight: bold;">IBAN/SWIFT CODE</th>
                
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Currency</th>
                <th class="py-2 pl-6" style="font-weight: bold;">Amount</th>
                
                <th class="py-2 pl-6" style="font-weight: bold;">Withdraw Date</th>
                <th class="py-2 pl-6" style="font-weight: bold;">Note</th>
                <th class="py-2 pl-6" style="font-weight: bold;">Remark</th>
                <!-- <th class="py-2 pl-6" style="font-weight: bold;">Submit By</th> -->

                <th class="py-2 pl-6" style="font-weight: bold;">Slip/File</th> 
                <th class="py-2 pl-6" style="font-weight: bold;">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction, index) in Mywithdraw" :key="transaction.id">
                <td class="py-2 pl-6 md-2 " style="width: 2%; ">{{ index + 1 }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width: 8%; ">{{ transaction.referent_number }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width: 15%; ">{{ transaction.company_name }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width: 15%; ">{{ transaction.iban }}</td>
                <td class="py-2 pl-6 md-2 text-center" style="width: 5%; ">{{ transaction.currency }}</td>
                <td class="py-2 pl-6 md-2 text-center" style="width: 5%;">{{ parseFloat(transaction.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width: 10%;">{{ formatDate(transaction.date_transfer) }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width:10%;">{{ transaction.note }}</td>
                <td v-if="(transaction.approve_status == 3)" class="py-2 pl-6 md-2 text-left" style="width:10%;">{{ transaction.remark }} - Cancel by {{ transaction.cancel_by }}</td>
                <td v-else-if="(transaction.approve_status == 1)" class="py-2 pl-6 md-2 text-left" style="width:10%;"> - Approve by {{ transaction.submit_by }}</td>
                <td v-else-if="(transaction.approve_status == 2)" class="py-2 pl-6 md-2 text-left" style="width:10%;"> - Approve by {{ transaction.approve_by }}<br>
                    - Submit by {{ transaction.submit_by }}</td>
                    <td v-else class="py-2 pl-6 md-2 text-left" style="width:10%;"> - </td>
                <td v-if="(transaction.approve_status == 0 && isAdmin == 2 )" style="text-align: center; width: 5%;">
                  <button  @click="openEditwithdrawModal(index)" class="btn btn-outline-default ">
                    <!-- <i class="ti-pencil"></i> -->
                    Add file
                  </button>

                </td>
                <td v-else style="text-align: center; width: 5%;">
                  <button v-if="transaction.attached_file" @click="openFileModal(getImageUrlFPS(transaction.attached_file))"
                    class="btn btn-outline-default">
                    <i class="ti-eye"></i> View File
                  </button>

                </td>
                <!-- <td v-if="(transaction.status == 1 ) " class="py-2 pl-6 md-2 text-left" style="width: 15%;">- Approve by {{ transaction.approveBy }}</td>
                <td v-else-if="( transaction.status == 0 ) " class="py-2 pl-6 md-2 text-left" style="width: 15%;">-</td>
                <td v-else class="py-2 pl-6 md-2 text-left" style="width: 15%;">- Cancel by {{ transaction.approveBy }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width:10%;">- Submit by {{ transaction.submitBy }}</td> -->
                <!-- <td style="text-align: center; width: 5%;">
                  <button v-if="transaction.image" @click="openFileModal(getImageUrl(transaction.image))"
                    class="btn btn-outline-info">
                    <i class="ti-eye"></i> View File
                  </button>
                  <span v-else class="text-muted">No File</span>
                </td> -->
                <td style="text-align: center; width: 5%;">
                  <button :style="{
        backgroundColor: getStatusColor(transaction.approve_status),
        color: 'white',
        padding: '0.5rem',
        borderRadius: '5px',
        border: '1px solid ' + getStatusColor(transaction.approve_status),
        cursor: 'pointer'
      }" class="btn btn-outline" @click="changeStatus(transaction)"
                    :disabled="(isAdmin == 2 && transaction.approve_status == '1') || (isAdmin == 3 && transaction.approve_status == '0') || transaction.approve_status == '2' || transaction.approve_status == '3'">

                    {{ transaction.approve_status == 2 ? 'success' : (transaction.approve_status == 0 && isAdmin == 2) ? 'pending' : (transaction.approve_status == 0 && isAdmin == 3) ? 'pending': (transaction.approve_status == 1 && isAdmin == 3) ? 'pending' : transaction.approve_status == 1 ? 'In progress' : transaction.approve_status ==
        3 ? 'cancel' : 'cancel' }}
                  </button>
                </td>

              </tr>
            </tbody>
          </table>
          <div class="main-container">
            <!-- ... (your existing template code) ... -->
            <div class="pagination-container">
              <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
                Prev
              </div>
              <div v-else class="btn btn-secondary" disabled>
                Prev
              </div>
              <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
              <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
                Next
              </div>
              <div v-else @click="nextPage()" class="btn btn-outline-danger">
                Next
              </div>
            </div>
          </div>
          <div v-if="isUpdateStatusModalOpen" class="modal-overlay" @click="closeUpdateStatusModal"></div>
          <RefundModal v-if="isUpdateStatusModalOpen" @closeModal="closeUpdateStatusModal" />
        </div>
      </Card>
      <div v-if="isEditwithdrawModalOpen" class="modal-overlay" @click="closeEditwithdrawModal"></div>
      <Modal v-if="isEditwithdrawModalOpen" @close="closeEditwithdrawModal">
        <h3>Edit withdraw</h3>
        <form @submit.prevent="submitEditwithdrawForm">
          <div class="form-group">
            <label for="editwithdrawFormData">Withdraw File:</label>
            <input type="file" @change="handleAddFileUpload" class="form-control" />
            <div v-if="editwithdrawFormData.attached_file">
              <p>File: {{ editwithdrawFormData.attached_file }}</p>
            </div>
            <button v-if="editwithdrawFormData.attached_file"
              @click.prevent="openFileModal(getImageUrlFPS(editwithdrawFormData.attached_file))"
              class="btn btn-outline-default">
              <i class="ti-eye"></i> View File
            </button>
          </div>



        </form>
        <button @click="submitEditwithdrawForm" class="btn btn-outline-success mr-2 mb-3 w-full">Save
          Changes</button>

      </Modal>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { Card, PaperTable } from "@/components";
import Swal from 'sweetalert2';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
import RefundModal from '@/components/Modal/RefundModal.vue';
import Modal from '@/components/Modal/MembershipsIcon.vue';

const selectedFilter = ref('selected_month');
const baseURL = "https://api2.logizall.com";
const Mywithdraw = ref([]);
const Quertsearch = ref()
const token = localStorage.getItem('token');
const table1 = ref({
  title: "Withdraw",
  subTitle: "Withdraw",

});





const currentPage = ref(1);
const pageSize = 10; // Set your desired page size
const totalPages = ref(1);
const totalRecords = ref(0);
const isAdmin = ref(null);
const isSearch = ref(false);
const isUpdateStatusModalOpen = ref(false);
let searchQuery

// Method to open the update status modal
const openUpdateStatusModal = () => {
  isUpdateStatusModalOpen.value = true;
};

// Method to close the update status modal
const closeUpdateStatusModal = () => {
  isUpdateStatusModalOpen.value = false;
};

const checkAdminRole = async () => {

  try {

    const response = await axios.get('https://api2.logizall.com/auth/admin/verify-token', {
      headers: {
        Authorization: token,
      },
    });

    // Check if user is admin with role 1

    console.log("res", response.data.role_admin)
    isAdmin.value = response.data.role_admin
    // return isAdmin ;
  } catch (error) {
    console.error('Failed to check admin role:', error.message);
    return false; // Default to false if role check fails
  }
}

const changeStatus = async (transaction) => {
  try {
  if ( transaction.attached_file !== null) {
  const result = await Swal.fire({
    title: 'Change Status',
    text: 'Select the new status:',
    icon: 'warning',
    showCancelButton: true,
    showDenyButton: true,
    showConfirmButton: true,
    confirmButtonText: 'Approve',
    denyButtonText: 'Denied',
    cancelButtonText: 'Cancel',
  });

  if (result.isConfirmed) {
    updateStatus(transaction, (isAdmin.value - 1));
  } 
  // else if (result.isDenied) {
  //   updateStatus(transaction, '3');
  // } 
  else if (result.isDenied) {
    // Show required input prompt for text note
    const { value: note } = await Swal.fire({
      title: "Cancel",
      input: "text",
      inputLabel: "Provide a Remark (required)",
      inputPlaceholder: "Type your Remark here...",
      showCancelButton: true,
      inputValidator: (value) => {
        return !value && "Remark is required!";
      },
    });

    if (note !== undefined) {
      updateStatus(transaction, '3',note);
    }
  } else if (result.isDismissed && result.dismiss == "cancel") {
  } else {
  }
} else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please enter attached file before activating.',
      });
    }
  } catch (error) {
    console.error('Error updating status:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while updating the status.',
    });
  }
};

const updateStatus = async (transaction, newStatus,note) => {
  try {
    //console.log(token);
    //console.log("log", transaction.id, newStatus);

    const response = await axios.put(
      `${baseURL}/admin/withdraw/changeStatus/${transaction.id}`,
      {
        newStatus: newStatus,
        note:note
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    console.log(response.data.message)

    let message = '';
    let type = '';
    if (response.data.message !== 'insufficient Balance to Refund ') {
      if (newStatus == 1) {
        message = 'The status has been changed to success successfully.';
        type = 'success';
      }else if (newStatus == 2) {
        message = 'The status has been changed to success successfully.';
        type = 'success'; // Using 'error' type for the "Cancel" message
      } else if (newStatus == 3) {
        message = 'The status has been changed to cancel.';
        type = 'success'; // Using 'error' type for the "Cancel" message
      }
      Swal.fire('Success', message, type).then(() => {
        // Reload the page after the user clicks "OK" on the success message
        window.location.reload();
      });
    } else if (response.data.message == 'insufficient Balance to Refund') {
      message = 'insufficient Balance to Refund.';
      type = 'Error';
    } else {
      message = 'Error something went wrong.';
      type = 'Error';
    }
  } catch (error) {
    console.error('Error updating status:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while updating the status.',
    });
  }
};



const getwithdraw = async () => {
  try {

    const response = await axios.get(`${baseURL}/admin/getwithdraw`, {
      params: {
        page: currentPage.value,
        pageSize: pageSize,
      },
      headers: {
        Authorization: token,
      },
    });
    console.log("response", response)
    Mywithdraw.value = response.data.data || [];
    totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
    totalRecords.value = response.data.totalRecords || [];
  } catch (error) {
    console.error('Error fetching My Wallet data:', error);
    // Handle the error appropriately, e.g., show an error message to the user.
  }
};
const prevPage = () => {
  prevOrNextPage('prev');
};

const nextPage = () => {
  prevOrNextPage('next');
};

const prevOrNextPage = (action) => {
  if (action == 'prev') {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
  } else if (action == 'next') {

    //console.log("pageSize",pageSize)
    //console.log("totalRecords",totalRecords.value)
    if (currentPage.value * pageSize <= totalRecords.value) {
      currentPage.value++;
    }
    //console.log("currentPage",currentPage.value)
  }


  // Check if it's a search or regular page navigation
  if (isSearch.value) {
    //console.log("isSearch",isSearch.value)
    search(selectedFilter.value, Quertsearch.value);
  } else {
    getwithdraw();
  }
};

const search = async (dateFilter, searchQuery) => {
  //console.log("search");
  //console.log("dateFilter", dateFilter);
  //console.log("selectedDate", searchQuery);
  Quertsearch.value = searchQuery;
  //console.log("Quertsearch", Quertsearch.value);
  //console.log("selectedOption", selectedOption.value);
  try {
    // Check if selectedFilter is null
    if (selectedOption.value === null) {
      // Perform the search using the original API
      const response = await axios.get(`${baseURL}/admin/getwithdrawbysearch`, {
        params: { dateFilter, selectedDate: searchQuery, page: currentPage.value, pageSize: pageSize },
        headers: {
          Authorization: token,
        },
      });

      // Destructure the response data
      const { data, total } = response.data;


      // Update values
      Mywithdraw.value = data || [];
      totalRecords.value = total || [];

      // Set the search flag to true
      isSearch.value = true;
      totalPages.value = Math.ceil(response.data.total / pageSize);
      // Update the current page to 1, as the search results start from the first page

    } else {
      // Perform the search using the new API
      //console.log("selectedOption.value",selectedOption.value.id)
      const response = await axios.get(`${baseURL}/admin/getwithdrawbysearchmid/${selectedOption.value.id}`, {
        params: { dateFilter, selectedDate: searchQuery, page: currentPage.value, pageSize: pageSize },
        headers: {
          Authorization: token,
        },
      });

      // Destructure the response data
      const { data, total } = response.data;

      // Update values
      Mywithdraw.value = data || [];
      totalRecords.value = total || [];

      // Set the search flag to true
      isSearch.value = true;
      totalPages.value = Math.ceil(response.data.total / pageSize);

      // Update the current page to 1, as the search results start from the first page

    }
  } catch (error) {
    console.error('Error fetching accounting data:', error);
    // Handle the error appropriately, e.g., show an error message to the user.
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while fetching data.',
    });
  }
};

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Asia/Bangkok',
  };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};
const openFileModal = (fileUrl) => {
  console.log(fileUrl);
  if (fileUrl) {
    // Check if the file is an image (JPEG, PNG, GIF)
    const isImage = /\.(jpe?g|png|gif)$/i.test(fileUrl);

    if (isImage) {
      // Display the image in a smaller SweetAlert modal
      Swal.fire({
        imageUrl: fileUrl,
        imageAlt: 'Refund',
        showCloseButton: true,
        customClass: {
          popup: 'smaller-modal', // Add a custom CSS class for the modal
        },
      });
    } else {
      // Open the PDF in a new window or tab
      window.open(fileUrl, '_blank');
    }
  } else {
    // Handle the case where there is no file
    Swal.fire({
      icon: 'info',
      title: 'No File',
      text: 'There is no file available for this transaction.',
    });
  }
};


const getImageUrl = (filename) => {
  console.log("admin/images/withdraw/", filename)
  // Replace 'localhost:4000' with your actual server address
  return `${baseURL}/admin/images/withdraw/${filename}`;
};
const getStatusColor = (status) => {
  switch (status) {
    case '0':
      return 'orange';
      case '1':
      return 'orange';
    case '2':
      return 'green';
    case '3':
      return 'red';
    default:
      return 'red';
  }
};

const selectedOption = ref(null);
const filteredOptions = ref([]);

const handleChange = (value) => {
  // Handle the selected value here
  console.log('Selected Option:', value);
  // You can perform additional actions or update other variables as needed
};
const fetchTransfer = async () => {
  try {
    let currentPage = 1;
    let totalPages = 1;
    let allOptions = []; // เพิ่มตัวแปรเก็บข้อมูลทั้งหมด

    do {
      const response = await axios.get(`https://api.logizall.com/api/customers?pagination%5Bpage%5D=${currentPage}&pagination%5BpageSize%5D=100`);
      //console.log("response", response.data.meta.pagination.pageCount);

      totalPages = response.data.meta.pagination.pageCount;

      const options = response.data.data
        .filter(option => option.attributes.status === "bronze") // Filter out only active data
        .map(option => ({
          id: option.id,
          company_name_en: option.attributes.companyNameEn
        }));

      allOptions = allOptions.concat(options); // เพิ่ม options เข้าไปใน allOptions

      currentPage++;

    } while (currentPage <= totalPages);

    filteredOptions.value = allOptions; // ใส่ข้อมูลทั้งหมดลงใน filteredOptions.value
    //console.log(filteredOptions.value)
  } catch (error) {
    console.error('Error fetching balance types:', error);
  }
};
const reload = async () => {
  fetchTransfer();
  getwithdraw();
  checkAdminRole();
};
onMounted(() => {
  fetchTransfer();
  getwithdraw();
  checkAdminRole();
});


const isEditwithdrawModalOpen = ref(false);
const editwithdrawFormData = {
  id: '',
  withdraw: null,
  attached_file: null
};
const openEditwithdrawModal = (index) => {
  const withdraw = Mywithdraw.value[index];
  editwithdrawFormData.id = withdraw.id;
  editwithdrawFormData.attached_file = withdraw.attached_file;
  isEditwithdrawModalOpen.value = true;
  console.log(editwithdrawFormData.attached_file)
};

const closeEditwithdrawModal = () => {
  resetEditwithdrawFormData();
  isEditwithdrawModalOpen.value = false;
};
const resetEditwithdrawFormData = () => {
  editwithdrawFormData.id = '';
  editwithdrawFormData.attached_file = null;
};
const submitEditwithdrawForm = async () => {
  try {
    const confirmed = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'You are about to edit this withdraw.',
      showCancelButton: true,
      confirmButtonText: 'Yes, edit it!',
      cancelButtonText: 'No, cancel!',
    });
    console.log(editwithdrawFormData)
    if (confirmed.isConfirmed) {
      const formData = new FormData();
      formData.append('id', editwithdrawFormData.id);
      formData.append('attached_file', editwithdrawFormData.attached_file);
      const response = await axios.put(`${baseURL}/admin/withdraw/updatewithdraw/${editwithdrawFormData.id}`, formData, {
        headers: {
          Authorization: `${token}`,
        },
      });
      getwithdraw();
      closeEditwithdrawModal();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
    }
  } catch (error) {
    console.error('Error updating withdraw:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to update withdraw. Please try again.',
    });
  }
};

const handleAddFileUpload = (event) => {
  // Logic to handle file upload and preview for adding new data
  const file = event.target.files[0];
  editwithdrawFormData.attached_file = event.target.files[0];
  if (file) {
    addPreviewImage.value = URL.createObjectURL(file);
  }
};
const getImageUrlFPS = (filename) => {
  console.log("/admin/images/withdraw/", filename)
  // Replace 'localhost:4000' with your actual server address
  return `${baseURL}/admin/images/withdraw/${filename}`;
};

</script>


<style>
.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.search-container {
  display: flex;
  /* align-items: right; */
  margin-left: auto;
}


.pagination-container {
  display: flex;
  justify-content: flex-end;
  /* Align items at the start of the container */
  align-items: stretch;
  /* Stretch items vertically */
  text-align: center;
  /* Center text within each item */
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.smaller-modal {
  max-width: 50%;
  /* Adjust the maximum width as needed */
}

.bea-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  /* Adjust the font size as needed */
  color: #333;
  /* Text color */

  width: 200px;
}

.bea-select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  /* Adjust the font size as needed */
  color: #333;
  /* Text color */
  width: 200px;
}

.bold-text {
  font-weight: bold;
}
</style>