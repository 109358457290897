<template>
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Download Manual</h4>
              </div>
              <div class="card-body">
                <button @click="openPDF" class="btn btn-outline-danger">Open PDF</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  const baseURL = "https://api2.logizall.com";
//   const baseURL = "https://api2.logizall.com";
  const fileUrl = `${baseURL}/admin/manual/manual.pdf`; // Path to your PDF file
  
  const openPDF = () => {
    window.open(fileUrl, '_blank'); // Open PDF in new window
  }
  </script>
  
  <style scoped>
  /* Add additional styles as needed */
  .card {
    margin-top: 20px;
  }
  
  .bordered-input {
    border: 1px solid #b6b6b6;
  }
  </style>
  