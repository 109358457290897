<template>
  <div class="col-md-12">
    <Card>
      <div class="d-flex justify-content-end mb-3">
        <button @click="openAddAdminModal" class="btn btn-outline-primary">
          <i class="ti-plus"></i> Add Admin
        </button>
      </div>

      <div slot="raw-content" class="table-responsive">
        <table class="table table-bordered">
          <thead class="bg-light bold-text">
            <tr>
              <th style="font-weight: bold;">#</th>
              <th style="font-weight: bold;">First Name</th>
              <!-- <th style="font-weight: bold;">Password</th> -->
              <th style="font-weight: bold;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(admin, index) in Admins" :key="admin.id">
              <td class="py-2 pl-6 md-2">{{ index + 1 }}</td>
              <td>{{ admin.firstname }}</td>
              <!-- <td>{{ admin.password }}</td> -->
              <td style="text-align: center;">
                <button @click="openEditAdminModal(index)" class="btn btn-outline-primary mr-2">
                  <i class="ti-pencil"></i>
                  Edit
                </button>
                <button v-if="admin.id !== 1" @click="deleteAdmin(admin.id)" class="btn btn-outline-danger mr-2">
    <i class="ti-trash"></i>
    Delete
</button>

              </td>
            </tr>
          </tbody>
        </table>
        <div class="main-container">
          <div class="pagination-container">
            <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
              Prev
            </div>
            <div v-else class="btn btn-secondary" disabled>
              Prev
            </div>
            <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
            <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
              Next
            </div>
            <div v-else @click="nextPage()" class="btn btn-outline-danger">
              Next
            </div>
          </div>
        </div>
      </div>
    </Card>

    <!-- Add Admin Modal -->
    <div v-if="isAddAdminModalOpen" class="modal-overlay" @click="closeAddAdminModal"></div>
    <Modal v-if="isAddAdminModalOpen" @close="closeAddAdminModal">
  <h3>Add New Admin</h3>
  <form @submit.prevent="submitAddAdminForm">
    <div class="form-group">
      <label for="firstname">First Name:</label>
      <input type="text" v-model="addAdminFormData.firstname" class="form-control bordered-input p-2" placeholder="Enter first name" required />
    </div>

    <div class="form-group">
      <label for="password">Password:</label>
      <input type="password" v-model="addAdminFormData.password" class="form-control bordered-input p-2" placeholder="Enter password" required />
    </div>

    <div class="form-group">
      <label for="Role">Role:</label>
    <select class="form-control  bordered-input p-2" placeholder="please select.." v-model="addAdminFormData.role" required>
      <option value="">please select..</option>
      <option value="1">Admin</option>
      <option value="2">Accounting Department</option>
      <option value="3">Manager</option>
    </select>
  </div>

    <button type="submit" class="btn btn-primary mt-3">Add Admin</button>
  </form>
</Modal>


    <!-- Edit Admin Modal -->
    <div v-if="isEditAdminModalOpen" class="modal-overlay" @click="closeEditAdminModal"></div>
    <Modal v-if="isEditAdminModalOpen" @close="closeEditAdminModal">
  <h3>Edit Admin</h3>
  <form @submit.prevent="submitEditAdminForm">
    <div class="form-group">
      <label for="firstname">First Name:</label>
      <input type="text" v-model="editAdminFormData.firstname" class="form-control bordered-input p-2" placeholder="Enter first name" required disabled />
    </div>

    <div class="form-group">
      <label for="password">Password:</label>
      <input type="password" v-model="editAdminFormData.password" class="form-control bordered-input p-2" placeholder="Enter new password (optional)" />
    </div>

    <div class="form-group">
      <label for="Role">Role:</label>
    <select class="form-control  bordered-input p-2" placeholder="please select.." v-model="editAdminFormData.role" required>
      <option value="">please select..</option>
      <option value="1">Admin</option>
      <option value="2">Accounting Department</option>
      <option value="3">Manager</option>
    </select>
  </div>

    <button type="submit" class="btn btn-primary">Save Changes</button>
  </form>
</Modal>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Card, PaperTable } from "@/components";
import Modal from '@/components/Modal/MembershipsIcon.vue'; 
const Admins = ref([]);
const isAddAdminModalOpen = ref(false);
const isEditAdminModalOpen = ref(false);
const token = localStorage.getItem('token');

const addAdminFormData = {
  firstname: '',
  password: '',
  role:''
};

const editAdminFormData = {
  id: '',
  firstname: '',
  password: '',
  role:''
};

const currentPage = ref(1);
const pageSize = 10;
const totalPages = ref(1);
const totalRecords = ref(0);

const baseURL = 'https://api2.logizall.com';

onMounted(() => {
  fetchAdmins(); // Uncommented this line
});

const openAddAdminModal = () => {
  isAddAdminModalOpen.value = true;
};

const closeAddAdminModal = () => {
  resetAddAdminFormData();
  isAddAdminModalOpen.value = false;
};

const resetAddAdminFormData = () => {
  addAdminFormData.firstname = '';
  addAdminFormData.password = '';
  addAdminFormData.role = '';
};

const openEditAdminModal = (index) => {
  const admin = Admins.value[index];
  editAdminFormData.id = admin.id;
  editAdminFormData.firstname = admin.firstname;
  editAdminFormData.password = ''; // Don't expose password in the form
  editAdminFormData.role = admin.role;
  isEditAdminModalOpen.value = true;
};

const closeEditAdminModal = () => {
  resetEditAdminFormData();
  isEditAdminModalOpen.value = false;
};

const resetEditAdminFormData = () => {
  editAdminFormData.id = '';
  editAdminFormData.firstname = '';
  editAdminFormData.password = '';
  editAdminFormData.role = '';
};

const submitAddAdminForm = async () => {
  try {
    const response = await axios.post(`${baseURL}/auth/admin/create`, addAdminFormData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    fetchAdmins();
    closeAddAdminModal();
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: response.data.message,
    });
  } catch (error) {
    console.error('Error adding admin:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to add admin. Please try again.',
    });
  }
};

const submitEditAdminForm = async () => {
  try {
    const confirmed = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'You are about to edit this admin.',
      showCancelButton: true,
      confirmButtonText: 'Yes, edit it!',
      cancelButtonText: 'No, cancel!',
    });

    if (confirmed.isConfirmed) {
      const response = await axios.put(`${baseURL}/auth/admin/editadmin/`, editAdminFormData, {
        headers: {
          Authorization: `${token}`,
        },
      });
      fetchAdmins();
      closeEditAdminModal();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
    }
  } catch (error) {
    console.error('Error updating admin:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to update admin. Please try again.',
    });
  }
};

const deleteAdmin = async (id) => {
  try {
    if (id === 1) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Admin with id 1 cannot be deleted.',
      });
      return; // Exit the function early if id is 1
    }

    const confirmed = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'You are about to delete this admin.',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });

    if (confirmed.isConfirmed) {
      const response = await axios.delete(`${baseURL}/auth/admin/delete/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      fetchAdmins();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
    }
  } catch (error) {
    console.error('Error deleting admin:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to delete admin. Please try again.',
    });
  }
};



const fetchAdmins = async () => {
  try {
    const response = await axios.get(`${baseURL}/auth/admin/getadmin`, {
      params: {
        page: currentPage.value,
        pageSize: pageSize,
      },
      headers: {
        Authorization: `${token}`,
      },
    });
    totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
    totalRecords.value = response.data.totalRecords || [];
    Admins.value = response.data.adminUsers || [];
    ////console.log(response.data.adminUsers)
  } catch (error) {
    console.error('Error fetching admins:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to fetch admins data. Please try again.',
    });
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    fetchAdmins();
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
    fetchAdmins();
  }
};
</script>
<style scoped>
.bordered-input {
    border: 1px solid #a1a1a1;
  }
</style>
