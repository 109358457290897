import Vue from "vue";
import Notifications from 'vue-notification';
import VueRouter from 'vue-router';
import routes from './routes';
import axios from 'axios';


Vue.use(VueRouter);
const router = new VueRouter({
  routes,
  linkActiveClass: "active",
});


router.beforeEach(async (to, from, next) => {
  // console.log("BEFORE EACH", to);
  const token = localStorage.getItem('token');

  try {
    // Perform token verification logic here
    await verifyToken(token);

    // Check if user is an admin with role 1
    const isAdmin = await checkAdminRole(token);
    // console.log(isAdmin)
    if (isAdmin == 2) {
      // If user is admin with role 1, block access to specific routes
      const restrictedRoutes = ['/welcometo', '/Conference', '/ournetwork', '/Membership', '/trackrecord', '/testimonials', '/Partners', '/Links' , '/conference', '/membership', '/partners', '/links'];
      if (restrictedRoutes.includes(to.path)) {
        alert('You are not authorized to access this page.');
        next({ name: 'dashboard' }); // Block navigation
      } else {
        next(); // Continue navigation if token is valid and user is authorized
      }
    }  if (isAdmin == 1) {
      // If user is admin with role 1, block access to specific routes
      const restrictedRoutes = ['/admin', '/Admin','/invoice','/Invoice','/invoiceChart','/InvoiceChart ', '/withdraw', '/Withdraw'];
      if (restrictedRoutes.includes(to.path)) {
        alert('You are not authorized to access this page.');
        next({ name: 'dashboard' }); // Block navigation
      } else {
        next(); // Continue navigation if token is valid and user is authorized
      }
    }if (isAdmin == 3) {
      // If user is admin with role 1, block access to specific routes
      const restrictedRoutes = ['/invoice','/Invoice','/InvoiceChart','/invoiceChart'];
      if (restrictedRoutes.includes(to.path)) {
        alert('You are not authorized to access this page.');
        next({ name: 'dashboard' }); // Block navigation
      } else {
        next(); // Continue navigation if token is valid and user is authorized
      }
    }
    else {
      next(); // Continue navigation if token is valid and user is not admin with role 1
    }
  } catch (error) {
    console.error('Token verification failed:', error.message);

    if (to.name !== 'Login') {
      // Redirect to login page if not already on the login page
      next({ name: 'Login' });
      alert('Invalid token. Please log in again.');
    } else {
      next(); // Continue navigation if already on the login page
    }
  }
});

async function checkAdminRole(token) {
  try {
    const response = await axios.get('https://api2.logizall.com/auth/admin/verify-token', {
      headers: {
        Authorization: token,
      },
    });

    // Check if user is admin with role 1
    
    // console.log("res",response.data.role_admin)
    
    return response.data.role_admin ;
  } catch (error) {
    console.error('Failed to check admin role:', error.message);
    return false; // Default to false if role check fails
  }
}


async function verifyToken(token) {
  // console.log("TOKENNNN", token);

  try {
    // Send a request to verify the token
    //https://api2.logizall.com
    const response = await axios.get('https://api2.logizall.com/auth/admin/verify-token', {
      headers: {
        Authorization: token,
      },
    });

    // console.log(response);

    // If the token is invalid, throw an error
    if (response.data.status !== 200) {
      throw new Error('Invalid token');
    }
  } catch (error) {
    console.error('Token verification failed:', error.message);

    // Handle the error, for example, log the user out or show an error message
    // You can customize this part based on your application's requirements
    // For now, rethrow the error to propagate it to the calling code
    throw error;
  }
}

export default router;
