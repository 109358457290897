<!-- Partners.vue -->
<template>
  <div class="col-md-12">
    <Card>
      <div class="d-flex justify-content-end mb-3">
        <button @click="openAddPartnerModal" class="btn btn-outline-primary">
          <i class="ti-plus"></i> Add Partner
        </button>
      </div>

      <div slot="raw-content" class="table-responsive">
        <table class="table table-bordered">
          <thead class="bg-light bold-text">
            <tr>
              <th style="font-weight: bold;">#</th>
              <th style="font-weight: bold; width: 50%;">Image</th>
              <th style="font-weight: bold; width: 20%;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(partner, index) in partners" :key="partner.id">
              <td class="py-2 pl-6 md-2">{{ index + 1 }}</td>
              <td style="text-align: center;">
                <img :src="getImageUrl(partner.partners_image)" @click="openFileModal(getImageUrl(partner.partners_image))" alt="Partner Image" style="max-width: 150px; max-height: 150px;" />
              </td>
              <td style="text-align: center;">
                <button @click="openEditPartnerModal(index)" class="btn btn-outline-primary mr-2">
                  <i class="ti-pencil"></i>
                  Edit
                </button>
                <button @click="deletePartner(index)" class="btn btn-outline-danger mr-2">
                  <i class="ti-trash"></i>
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="main-container">
          <div class="pagination-container">
            <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
              Prev
            </div>
            <div v-else class="btn btn-secondary" disabled>
              Prev
            </div>
            <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
            <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
              Next
            </div>
            <div v-else @click="nextPage()" class="btn btn-outline-danger">
              Next
            </div>
          </div>
        </div>
      </div>
    </Card>

    <!-- Add Partner Modal -->
    <div v-if="isAddPartnerModalOpen" class="modal-overlay" @click="closeAddPartnerModal"></div>
    <Modal v-if="isAddPartnerModalOpen" @close="closeAddPartnerModal">
      <h3>Add New Partner</h3>
      <form @submit.prevent="submitAddPartnerForm">

        <div class="form-group">
          <label for="addPartnerImage">Image (Image 1126 x 1126 px):</label>
          <input type="file" @change="handleAddPartnerImageUpload" class="form-control" />
          <div v-if="addPartnerImagePreview" class="image-preview">
            <img :src="addPartnerImagePreview" alt="Partner Image" style="max-width: 256px; max-height: 256px;" />
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Add Partner</button>
      </form>
    </Modal>

    <!-- Edit Partner Modal -->
    <div v-if="isEditPartnerModalOpen" class="modal-overlay" @click="closeEditPartnerModal"></div>
    <Modal v-if="isEditPartnerModalOpen" @close="closeEditPartnerModal">
      <h3>Edit Partner</h3>
      <form @submit.prevent="submitEditPartnerForm">

        <div class="form-group">
          <label for="editPartnerImage">Image (Image 1126 x 1126 px):</label>
          <input type="file" @change="handleEditPartnerImageUpload" class="form-control" />
          <div v-if="editPartnerImagePreview || existingPartnerImage" class="image-preview">
            <img :src="editPartnerImagePreview || existingPartnerImage" alt="Partner Image" style="max-width: 256px; max-height: 256px;" />
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Save Changes</button>
      </form>
    </Modal>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { Card, PaperTable } from "@/components";
import Modal from '@/components/Modal/MembershipsIcon.vue'; // แก้ไขตามโค้ดจริง
import axios from 'axios';
import Swal from 'sweetalert2';

const partners = ref([]);
const isAddPartnerModalOpen = ref(false);
const isEditPartnerModalOpen = ref(false);
const token = localStorage.getItem('token');
const addPartnerFormData = reactive({
  addImageFile: null,
});

const editPartnerFormData = reactive({
  editImageFile: null,
});

const existingPartnerImage = ref('');
const addPartnerImagePreview = ref('');
const editPartnerImagePreview = ref('');
const currentPage = ref(1);
const pageSize = 10; // Set your desired page size
const totalPages = ref(1);
const totalRecords = ref(0);

const baseURL = 'https://api2.logizall.com';

const fetchPartners = async () => {
  try {
    const response = await axios.get(`${baseURL}/landingpage/partners/getpartnersdatapage`, {
      params: {
        page: currentPage.value,
        pageSize: pageSize,
      },
    });
    ////console.log("response", response)
    totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
    totalRecords.value = response.data.totalRecords || [];
    partners.value = response.data.data || [];
  } catch (error) {
    console.error('Error fetching partners data:', error);
  }
};

onMounted(() => {
  fetchPartners();
});

const openAddPartnerModal = () => {
  isAddPartnerModalOpen.value = true;
};

const closeAddPartnerModal = () => {
  addPartnerFormData.addImageFile = null;
  isAddPartnerModalOpen.value = false;
};

const openEditPartnerModal = (index) => {
  const partner = partners.value[index];
  editPartnerFormData.value = {
    id:partner.id,
  };
  editPartnerImagePreview.value = '';
  existingPartnerImage.value = getImageUrl(partner.partners_image);
  isEditPartnerModalOpen.value = true;
};

const closeEditPartnerModal = () => {
  editPartnerFormData.editImageFile = null;
  existingPartnerImage.value = '';
  editPartnerImagePreview.value = '';
  isEditPartnerModalOpen.value = false;
};

const handleAddPartnerImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    addPartnerFormData.addImageFile = file;
    addPartnerImagePreview.value = URL.createObjectURL(file);
  }
};

const handleEditPartnerImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    editPartnerFormData.editImageFile = file;
    editPartnerImagePreview.value = URL.createObjectURL(file);
  }
};

const submitAddPartnerForm = async () => {
  try {
    const formData = new FormData();
    formData.append('partners_image', addPartnerFormData.addImageFile);

    const response =  await axios.post(`${baseURL}/landingpage/partners/addpartnersdata`, formData,{
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    fetchPartners();
    closeAddPartnerModal();
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: response.data.message,
    });
  } catch (error) {
    console.error('Error adding partner:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to add partner. Please try again.',
    });
  }
};

const submitEditPartnerForm = async () => {
  try {
    const formData = new FormData();
    formData.append('partners_image', editPartnerFormData.editImageFile);

    // You'll need to implement the update endpoint on the server
    const response = await axios.put(`${baseURL}/landingpage/partners/updatepartnersData/${editPartnerFormData.value.id}`, formData,{
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    fetchPartners();
    closeEditPartnerModal();
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: response.data.message,
    });
  } catch (error) {
    console.error('Error updating partner:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to add partner. Please try again.',
    });
  }
};

const deletePartner = async (index) => {
  try {
    const partner = partners.value[index];
    ////console.log("partner.id",partner.id)
    const response = await axios.delete(`${baseURL}/landingpage/partners/remove/${partner.id}`,{
      headers: {
        Authorization: `${token}`,
      },
    });

    
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: response.data.message,
    });
    fetchPartners();
  } catch (error) {
    console.error('Error deleting partner:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to add partner. Please try again.',
    });
  }
};

const openFileModal = (fileUrl) => {
  ////console.log(fileUrl);
  if (fileUrl) {
    // Check if the file is an image (JPEG, PNG, GIF)
    const isImage = /\.(jpe?g|png|gif)$/i.test(fileUrl);

    if (isImage) {
      // Display the image in a smaller SweetAlert modal
      Swal.fire({
        imageUrl: fileUrl,
        imageAlt: '',
        showCloseButton: true,
        customClass: {
          popup: 'smaller-modal', // Add a custom CSS class for the modal
        },
      });
    } else {
      // Open the PDF in a new window or tab
      window.open(fileUrl, '_blank');
    }
  } else {
    // Handle the case where there is no file
    Swal.fire({
      icon: 'info',
      title: 'No File',
      text: 'There is no file available for this transaction.',
    });
  }
};


const getImageUrl = (filename) => {
  ////console.log("landingpage/partners/images/upload/", filename)
  // Replace 'localhost:4000' with your actual server address
  return `${baseURL}/landingpage/partners/images/upload/${filename}`;
};

const prevPage = () => {
  prevOrNextPage('prev');
};

const nextPage = () => {
  prevOrNextPage('next');
};

const prevOrNextPage = (action) => {
  if (action == 'prev') {
    if (currentPage.value > 1) {
      currentPage.value--;
      fetchPartners();
    }
  } else if (action == 'next') {

    ////console.log("pageSize", pageSize)
    //console.log("totalRecords", totalRecords.value)
    if (currentPage.value * pageSize <= totalRecords.value) {
      currentPage.value++;
    }
    //console.log("currentPage", currentPage.value)
    fetchPartners();
  }
};
</script>
