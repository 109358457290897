<!-- Modal.vue -->
<template>
    <div v-if="isEditModalOpen" class="update-status-modal">
      <!-- <div class="modal-overlay" @click="close"> -->
        <div class="modal-content">
        <span class="close-btn" @click="close">&times;</span>
       <slot></slot>
    </div>
      </div>
    <!-- </div> -->
  </template>
  
  <script setup>
    import { ref, onMounted, defineProps, defineEmits } from 'vue';
    import axios from 'axios';
    import Swal from 'sweetalert2';

    const baseURL = "https://api2.logizall.com";
    const token = localStorage.getItem('token');
    const emits = defineEmits(['close']);
    const isEditModalOpen = ref(true);

    const close = () => {
  emits('close');
};
  </script>
  
  <style scoped>
  .update-status-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001; /* Ensure modal is on top of the overlay */
  width: 400px; /* Adjust the width as needed */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  z-index: 1000; /* Set a lower z-index to be below the modal */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1002; /* Ensure modal content is on top of the overlay */
}
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
  }
  </style>
  