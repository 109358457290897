<template>
  <div>
    <!--Stats cards-->
    <div class="row">
      <div class="col-md-6 col-xl-4" v-for="stats in statsCards" :key="stats.title">
        <stats-card>
          <div class="icon-big text-center" :class="`icon-${stats.type}`" slot="header">
            <i :class="stats.icon"></i>
          </div>
          <div class="numbers" slot="content">
            <p>{{ stats.title }}</p>
            <!-- Ensure stats.value is not null or undefined before displaying -->
            <template v-if="stats.value !== null && stats.value !== undefined">
              {{ stats.value }}
            </template>
          </div>
          <div class="stats" slot="footer">
            <!-- <i :class="stats.footerIcon"></i> {{ stats.footerText }} -->
          </div>
        </stats-card>
      </div>
    </div>


    <!--Charts-->
    <div class="row">
      <!-- <div class="col-12">
        <chart-card
          title="Users behavior"
          sub-title="24 Hours performance"
          :chart-data="usersChart.data"
          :chart-options="usersChart.options"
        >
          <span slot="footer">
            <i class="ti-reload"></i> Updated 3 minutes ago
          </span>
          <div slot="legend">
            <i class="fa fa-circle text-info"></i> Open
            <i class="fa fa-circle text-danger"></i> Click
            <i class="fa fa-circle text-warning"></i> Click Second Time
          </div>
        </chart-card>
      </div> -->
      <div v-if="getdashboardinvoicea == true" class="col-md-6 col-12">
        <chart-card :title="'Transaction ' + currentYear" sub-title="Refill & Invoice" :chart-data="activityChart.data"
          :chart-options="activityChart.options" >

          <div slot="legend">
            <i class="fa fa-circle text-success"></i> Refill
            <i class="fa fa-circle text-warning"></i> Invoice
            <i class="fa fa-circle text-danger"></i> Withdraw
            <i class="fa fa-circle text-info"></i> Tranfer
            <i class="fa fa-circle " style="color: #7A9E9F;" ></i> Refund
          </div>
        </chart-card>
      </div>
      <div class="col-md-6 col-12 ">
        <chart-card title="Overall Chart" sub-title="Overall Chart" :chart-data="preferencesChart.data"
          chart-type="Pie">
          <!-- <span slot="footer">
            <i class="ti-timer"></i> Campaign set 2 days ago
          </span> -->
          <div slot="legend">
            <div>
              <i class="fa fa-circle text-info"></i> Wallet Remain
            </div>
            <div>
              <i class="fa fa-circle text-danger"></i> Wallet Out
            </div>
            <div>
              <i class="fa fa-circle text-warning"></i> Service charge
            </div>
            <div>
              <i class="fa fa-circle text-success"></i> Wallet In
            </div>

          </div>
        </chart-card>

      </div>




    </div>
    
    <div class="col-md-12">
      <Card :title="table1.title" :subTitle="table1.subTitle">
        <div slot="raw-content" class="table-responsive">
         
          <div class="mb-3 d-flex justify-content-start ">
           
                
              </div>
          <table class="table table-bordered">
            <thead class="bg-light  bold-text" >
              <tr>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">#</th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold;">Company</th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold;">REF</th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold;">Date</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Type</th>
                <th class="py-2 pl-6 " style="width: 20%; font-weight: bold;">Details</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Currency</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Balance</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Amount</th>
              </tr>
            </thead>
            <tbody>
          <tr v-for="(transaction, index) in accountingTransactionList" :key="transaction.id">
            <td>{{ index + 1 }}</td>
            <td class="text-left">{{ transaction.company_name }}</td>
            <td class="text-left">{{ transaction.referent_number }}</td>
            <td class="text-left">{{ formatDate(transaction.dateCreate) }}</td>
            <td :style="{ color: transaction.account_type === 'IN' ? 'green' : 'red' }">{{ transaction.account_type }}</td>
            <td class="text-left">{{ transaction.detail }}</td>
            <td class="text-center">{{ transaction.currency }}</td>
            <td class="text-right" :style="{ color: transaction.account_type === 'IN' ? 'green' : 'red' }">{{ parseFloat(transaction.debit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) >= parseFloat(transaction.credit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) ? ("+" +parseFloat(transaction.debit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})) : "-" + parseFloat(transaction.credit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})  }}</td>
            <td class="text-right">{{ parseFloat(transaction.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>

          </tr>
        </tbody>
          </table>

     
        </div>
      </Card>
      
        </div>
    
  </div>

  
</template>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { StatsCard, ChartCard } from "@/components/index";
import Chartist from "chartist";
import { watch } from 'vue';
import { Card, PaperTable } from "@/components";

// ใช้ watch เพื่อตรวจสอบค่าของ balance, totalcredit, และ totaldebit และเรียกใช้งาน getDashboard() เมื่อมีการเปลี่ยนแปลง

const baseURL = 'https://api2.logizall.com';
const token = localStorage.getItem('token');


const balance = ref(null);
const totalcredit = ref(null);
const totaldebit = ref(null);
const totalSvc = ref(null);
const totalRefund = ref(null);
const balancepercent = ref(null);
const totalcreditpercent = ref(null);
const totaldebitpercent = ref(null);
const Svcpercent = ref(null);
const Refundpercent = ref(null);
const getdashboardinvoicea = ref(null);
const currentYear = new Date().getFullYear();
const getDashboard = async () => {
  try {
    const response = await axios.get(`${baseURL}/admin/getdashboard`, {
      headers: {
        Authorization: token,
      },
    });
    // console.log(response.data.accountingData[0])



    const SVCValue = response.data.accountingDataSVC[0] && response.data.accountingDataSVC[0].totalcredit ? parseFloat(response.data.accountingDataSVC[0].totalcredit) : 0;
    const balanceValue = response.data.accountingData[0] && response.data.accountingData[0].balance ? parseFloat(response.data.accountingData[0].balance) : 0;
    const totalCreditValue = response.data.accountingData[0] && response.data.accountingData[0].totalcredit ? parseFloat(response.data.accountingData[0].totalcredit) : 0;
    const totalDebitValue = response.data.accountingData[0] && response.data.accountingData[0].totaldebit ? parseFloat(response.data.accountingData[0].totaldebit) : 0;
    const totalValue = parseFloat(balanceValue) + parseFloat(totalCreditValue) + parseFloat(totalDebitValue) + parseFloat(SVCValue);


    const totalpercent = 100;
    const SVCpsercentValue = (parseFloat((SVCValue / totalValue)) * 100).toFixed(2);
    const totalDebitPercentValue = (parseFloat((totalDebitValue / totalValue)) * 100).toFixed(2);
    const totalCreditPercentValue = (parseFloat((totalCreditValue / totalValue)) * 100).toFixed(2);
    const balancePercentValue = (parseFloat((balanceValue / totalValue)) * 100).toFixed(2);

    
    balance.value = balanceValue;
    totalcredit.value = totalCreditValue;
    totaldebit.value = totalDebitValue;
    totalSvc.value = SVCValue;

    // console.log(" totalSvc.value ", totalSvc.value)

    // console.log(totalDebitPercentValue, totalCreditPercentValue, balancePercentValue, SVCpsercentValue)

    balancepercent.value = balancePercentValue;
    totalcreditpercent.value = totalCreditPercentValue;
    totaldebitpercent.value = totalDebitPercentValue;
    Svcpercent.value = SVCpsercentValue


    // Update the series data of the preferencesChart


    // Update the value of the first card in statsCards with the fetched balance value
    statsCards.value[2].value = parseFloat(balance.value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    statsCards.value[0].value = parseFloat(totaldebit.value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    statsCards.value[1].value = parseFloat(totalcredit.value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    // statsCards.value[3].value = parseFloat(Svcpercent.value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 });


    preferencesChart.value.data.series = [
      parseFloat(balancePercentValue), // blue
      parseFloat(SVCpsercentValue),//yellow
      parseFloat(totalCreditPercentValue), //red
      parseFloat(totalDebitPercentValue)//green

    ];

    preferencesChart.value.data.labels = [
      `${balancePercentValue}%`,
      `${SVCpsercentValue}%`,
      `${totalCreditPercentValue}%`,
      `${totalDebitPercentValue}%`,

    ];

    new Chartist.Pie('.ct-chart', preferencesChart.value.data);


  } catch (error) {
    console.error("Failed to fetch dashboard data:", error.message);
  }
};


const getDashboard2 = async () => {
  try {
    const response2 = await axios.get(`${baseURL}/admin/getdashboardinvoice`, {
      headers: {
        Authorization: token,
      },
    });
    // console.log(response2.data)
    const accountingDataRefill = response2.data.accountingDataRefill;
    const accountingDataInvoice = response2.data.accountingDataInvoice;
    const accountingDataRefund = response2.data.accountingDataRefund
    const accountingDataWithdraw = response2.data.accountingDataWithdraw;
    const accountingDataTransaction = response2.data.accountingDataTransaction

    // Assuming you have access to the activityChart ref
    activityChart.value.data.series = [
      accountingDataTransaction.map(item => item.totalcredit),
      accountingDataInvoice.map(item => item.balance),
      accountingDataWithdraw.map(item => item.totalcredit),
      accountingDataRefill.map(item => item.balance),
      accountingDataRefund.map(item => item.totalcredit) 



    ];
    getdashboardinvoicea.value = true
    // console.log(activityChart.value.data.series[0]);

  } catch (error) {
    console.error("Failed to fetch dashboard data:", error.message);
  }
};

const currentPage = ref(1);
const pageSize = 20; // Set your desired page size
const totalPages = ref(1);
const totalRecords = ref(0);
const accountingTransactionList = ref([]);
const table1 = ref({
title: "Transaction latest",
subTitle: "Update 20 record",

});
const getTransaction = async () => {
try {

  const response = await axios.get(`${baseURL}/admin/getaccounting`, {
    params: {
      page: currentPage.value,
      pageSize: pageSize,
    },
    headers: {
      Authorization: token,
    },
  });
  //console.log("response",response)
  accountingTransactionList.value = response.data.data || [];
  totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
  totalRecords.value = response.data.totalRecords || [];
} catch (error) {
  console.error('Error fetching My Wallet data:', error);
  // Handle the error appropriately, e.g., show an error message to the user.
}
};

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Asia/Bangkok',
  };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};


onMounted(async () => {

  setTimeout(async () => {

    await getDashboard();
    await getDashboard2();
    await getTransaction();

  }, 100);
});


const activityChart = ref({
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    series: [],
  },
  options: {
    seriesBarDistance: 10,
    axisX: {
      showGrid: false,
    },
    height: "245px",
  },
});

const preferencesChart = ref({ data: { labels: [], series: [] }, options: {} });


const statsCards = ref([
  {
    type: "success",
    icon: "ti-money",
    title: "Wallet In",
    value: balance.value  // Ensure balance is populated before accessing its properties
  },
  {
    type: "danger",
    icon: "ti-pulse",
    title: "Wallet Out",
    value: totalcredit.value

  },
  {
    type: "success",
    icon: "ti-wallet",
    title: "Wallet Remain",
    value: totaldebit.value
  },

]);





</script>

<style></style>
