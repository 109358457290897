<template>
  <div v-if="isUpdateStatusModalOpen" class="update-status-modal">
    <div class="modal-overlay" @click="closeModal"></div>
    <div class="modal-content">
      <!-- Your modal content goes here -->
      <h4>Refund Management</h4>

      <!-- Add label and select dropdown for Customers Accounts -->
      <div class="form-group">
        <label for="customerAccount">Refund From Customers Accounts:</label>
        <v-select v-model="selectedOption1" :options="filteredOptions" label="company_name_en" placeholder="Select account"
          @input="handleChange" style="width: 100%;"></v-select>
      </div>
      <div class="form-group">
      <label for="customerAccount">Refund To Customers Accounts:</label>
        <v-select v-model="selectedOption2" :options="filteredOptions" label="company_name_en" placeholder="Select account"
           style="width: 100%;"></v-select>
        </div>
        <div class="form-group" v-if="selectedOption1 !== null && selectedOption2 !== null">
          <label for="RefundAmount">Refund Amount (USD) :</label>
          <!-- Add your multiselect component or input here -->
          <!-- Example using v-select for multiselect -->
          <input type="text" v-model="modalData.refundamount" @input="handleChangerefundamount" class="form-control bordered-input p-2" placeholder="Enter Refund Amount"  />
        </div>
        <div class="form-group" v-if="selectedOption1 !== null && selectedOption2 !== null">
          <label for="Note">Note:</label>
          <!-- Add your multiselect component or input here -->
          <!-- Example using v-select for multiselect -->
          <input type="text" v-model="modalData.note" class="form-control bordered-input p-2" placeholder="Enter Note.."  />
        </div>
        <div class="form-group">
          <label for="addImage">Ref Image:</label>
          <input type="file" @change="handleAddFileUpload" class="form-control" />
          <!-- Image preview -->
          <div v-if="addPreviewImage" class="image-preview">
            <img :src="addPreviewImage" alt="Preview Image" style="max-width: 150px; max-height: 250px;" />
          </div>
        </div>

      <!-- Display the customer's balance -->
      <div v-if="customerBalance !== 0">
        <p>Customer Balance: {{ customerBalance }}</p>
      </div>
      <div v-if="customerBalance !== 0 && modalData.refundamount !== null">
        <p>
          Calculation: {{ customerBalance }} <span :style="{ color: 'red' }">- {{ modalData.refundamount }}</span> = {{
            customerBalance - modalData.refundamount}}
        </p>
      </div>
      <!-- Display Total Amount Due -->
      <!-- <div v-if="totalAmountDue !== 0">
        <p>Total Amount Due: <span :style="{ color: 'red' }">{{ totalAmountDue }}</span></p>
      </div> -->

      <!-- Display Remaining Balance -->
      <!-- <div v-if="remainBalance !== null">
        <p>Remaining Balance: {{ remainBalance }}</p>
      </div> -->

      <!-- Display calculation -->
      <div v-if="customerBalance !== null && totalAmountDue !== 0">
        <p>
          Calculation: {{ customerBalance }} <span :style="{ color: 'red' }">- {{ totalAmountDue }}</span> = {{
            remainBalance }}
        </p>
      </div>

      <!-- Add your form or content for updating status -->
      <button @click="submitAccountingData" class="btn btn-outline-success mr-2 mb-3">Submit</button>

      <button @click="closeModal" class="btn btn-outline-danger mr-2 ">Close</button>
    </div>
  </div>
</template>



<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
import { watch } from 'vue';
const baseURL = "https://api2.logizall.com";
const token = localStorage.getItem('token');
const emits = defineEmits(['closeModal']);
const isUpdateStatusModalOpen = ref(true);

const modalData = ref({
  refundamount: '',
  note:'',

});
const filteredOptions = ref([]);
const customerBalance = ref(0);
const selectedOption1 = ref(null);
const selectedOption2 = ref(null);
const selectedOrderType = ref('');
const selectedFPSOptions = ref([]);
const fpsOptions = ref([]);
const selectedFPSAmounts = ref([]); // New array to store amounts for each selected option
const totalAmountDue = ref(0); // Total amountDue sum
const remainBalance = ref(0); // Remaining balance
const options = ref([]);
const addFormData = ref({
  image: '',
});
const addPreviewImage = ref(null);

watch(selectedOption1, (newValue, oldValue) => {
  if (newValue !== null) {
    // Filter options based on selectedOption1
    filteredOptions.value = filteredOptions.value.filter(option => option.id !== newValue.id);
  } else{
    getCustomer()
  }
});



const resetSelections = () => {
  selectedOption1.value = null;
  selectedOption2.value = null;
  selectedOrderType.value = '';
  selectedFPSOptions.value = [];
  customerBalance.value = 0;
  fpsOptions.value = [];
  modalData.value.refundamount = null;
};

const resetSelections2 = () => {

  selectedOrderType.value = '';
  selectedFPSOptions.value = [];
  fpsOptions.value = [];
  modalData.value.refundamount = null;
};

const handleChangerefundamount = async (value) => {
console.log(value)
    return value;
  }
  
const handleChange = async (value) => {
  if (value === null) {
    resetSelections();
    return;
  }

  try {
    const response = await axios.get(`${baseURL}/admin/getcustomerbalance/${value.id}`, {
      headers: {
        'Authorization': token,
      },
    });

    if (response.data && response.data.length > 0) {
      customerBalance.value = response.data[0].amount;
      resetSelections2();

      // Remove selected option from the second dropdown

    } else {
      customerBalance.value = null;
      console.error('Error: Empty or null response from the server');
      resetSelections2();
    }
  } catch (error) {
    console.error('Error fetching customers:', error);
  }
};


const getCustomer = async () => {
  try {
    let currentPage = 1;
    let totalPages = 1;
    let allOptions = []; // เพิ่มตัวแปรเก็บข้อมูลทั้งหมด

    do {
      const response = await axios.get(`https://api.logizall.com/api/customers?pagination%5Bpage%5D=${currentPage}&pagination%5BpageSize%5D=100`);
      //console.log("response", response.data.meta.pagination.pageCount);

      totalPages = response.data.meta.pagination.pageCount;

      const options = response.data.data
          .filter(option => option.attributes.status === "bronze") // Filter out only active data
          .map(option => ({
            id: option.id,
            company_name_en: option.attributes.companyNameEn
          }));

      allOptions = allOptions.concat(options); // เพิ่ม options เข้าไปใน allOptions 

      currentPage++;

    } while (currentPage <= totalPages);

    filteredOptions.value = allOptions; // ใส่ข้อมูลทั้งหมดลงใน filteredOptions.value
    //console.log(filteredOptions.value)
  } catch (error) {
    console.error('Error fetching balance types:', error);
  }
};

const handleAddFileUpload = (event) => {
  // Logic to handle file upload and preview for adding new data
  const file = event.target.files[0];
  addFormData.addImageFile = event.target.files[0];
  if (file) {
    addPreviewImage.value = URL.createObjectURL(file);
  }
};
const submitAccountingData = async () => {
  try {
    if (customerBalance.value < totalAmountDue.value) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to submit balance is insufficient. Please try again.',
      });
    } else {
      if (!selectedOption1.value || !selectedOption2.value) {
        console.error('Incomplete data. Cannot submit to the API.');
        return;
      }

      const accountingData = new FormData();
      accountingData.append('sid', selectedOption1.value.id);
      accountingData.append('rid', selectedOption2.value.id);
      accountingData.append('s_company_name', selectedOption1.value.company_name_en);
      accountingData.append('r_company_name', selectedOption2.value.company_name_en);
      accountingData.append('amount', modalData.value.refundamount);
      accountingData.append('note', modalData.value.note);
      accountingData.append('image', addFormData.addImageFile);

      // Make an API call to submit data
      const response = await axios.post(`${baseURL}/admin/addrefund`, accountingData, {
        headers: {
          'Authorization': token,
          'Content-Type': 'multipart/form-data',
        },
      });

      // Display SweetAlert notification on success
      await Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Accounting data submitted successfully!',
      });

      // Close the modal after successful submission
      closeModal(); // Assuming you have a function named closeModal to close the modal

      // Reload the window after closing the modal
      window.location.reload(true);

      // Reset the form or perform any other actions after successful submission
      resetSelections();
    }
  } catch (error) {
    console.error('Error submitting accounting data:', error);
    // Handle errors as needed
    // Display SweetAlert error notification if desired
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to submit accounting data. Please try again.',
    });
  }
};




onMounted(() => {
  getCustomer();
});

const closeModal = () => {
  emits('closeModal');
};
</script>





<style scoped>
.update-status-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  /* Ensure modal is on top of the overlay */
  width: 400px;
  /* Adjust the width as needed */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the transparency as needed */
  z-index: 1000;
  /* Set a lower z-index to be below the modal */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1002;
  /* Ensure modal content is on top of the overlay */
}
</style>
