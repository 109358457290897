<script setup >
import { ref, onMounted, defineEmits, watchEffect,defineProps  } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';

const props = defineProps({
    fileToDisplay: Object
});

const emits = defineEmits(['closeModal', 'submitForm']);
const showModal = ref(true);


// const baseURL = "https://api2.logizall.com";
const baseURL = "https://api2.logizall.com";


const closeModal = () => {
    emits('closeModal');
};


const getFileNames = () => {
  if (props.fileToDisplay && props.fileToDisplay.fileName) {
    return props.fileToDisplay.fileName.split(',').map(filename => filename.trim());
  } else {
    return [];
  }
};


onMounted(() => {
    getFileNames();
});

const getImageUrl = (filename) => {
  // Replace 'localhost:4000' with your actual server address
  return `${baseURL}/fps/images/FPS/${filename}`;
};
const openFileModal = (fileUrl) => {
  if (fileUrl) {
    // Check if the file is an image (JPEG, PNG, GIF)
    const isImage = /\.(jpe?g|png|gif)$/i.test(fileUrl);

    if (isImage) {
      // Display the image in a SweetAlert modal
      Swal.fire({
        imageUrl: fileUrl,
        imageAlt: 'FPS',
        showCloseButton: true,
      });
    } else {
      // Open the PDF in a new window or tab
      window.open(fileUrl, '_blank');
    }
  } else {
    // Handle the case where there is no file
    Swal.fire({
      icon: 'info',
      title: 'No File',
      text: 'There is no file available for this transaction.',
    });
  }
};

</script>
<template>
    <div v-if="showModal" class="modal">
        <div class="modal-header">
            <h2 class="text-lg font-semibold text-red-600">
                View file
            </h2>
            <button @click="closeModal" class="modal-close-button">×</button>
        </div>
        <!-- Iterate over each filename in the array -->
        <div v-for="(fileName, index) in getFileNames" :key="index">
            <span @click="openFileModal(getImageUrl(fileName))" class="file-name" style="cursor: pointer;">{{ fileName }} </span>
        </div>
    </div>
</template> 






<style scoped>
/* Modal styles */

.file-name:hover {
    color: red; /* เปลี่ยนสีของชื่อไฟล์เมื่อโฮเวอร์อยู่ที่นั้น */
}

.dropdown-options {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 200px;
    /* Set a maximum height for the dropdown */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.dropdown-options li {
    padding: 8px;
    cursor: pointer;
}

.selected-option {
    margin-top: 10px;
    color: #3490dc;
    font-weight: bold;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    /* Ensure modal is on top of the overlay */
    width: 400px;
    /* Adjust the width as needed */
}


.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.modal-header h2 {
    margin: 0;
}

.modal-close-button {
    cursor: pointer;
    border: none;
    background: none;
    font-size: 30px;
    color: #aaa;
}

.modal-body {
    margin-top: 10px;
}

.form-group {
    margin-bottom: 5px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input,
select,
textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
}

.submit-container {
    text-align: right;
}

.submit-button {
    background-color: #CF1808;
    color: white;
    cursor: pointer;
}

.file-label {
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: #3490dc;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}

.file-input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
}

/* Additional styling for SweetAlert */
.swal2-popup {
    font-size: 16px;
}

.image-preview {
    text-align: center;
    max-width: 128;
    max-height: 128px;
    overflow: hidden;
}

.image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensure the image covers the container while maintaining aspect ratio */
}

.image-preview-placeholder {
    padding: 10px;
    background-color: #f9f9f9;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 4px;
}
</style>