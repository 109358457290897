import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import Notifications from "@/pages/Notifications.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Typography from "@/pages/Typography.vue";
import TableList from "@/pages/TableList.vue";
import Accounting from "@/pages/Admin/Accounting.vue";
import Fps from "@/pages/Admin/Fps.vue";
import FpsChart from "@/pages/Admin/Fps_chart.vue";
import FpsApprove from "@/pages/Admin/Fps_approve.vue";
import Refill from "@/pages/Admin/Refill.vue";
import Refund from "@/pages/Admin/Refund.vue";
import Transaction from  "@/pages/Admin/Transaction.vue";
import Manual from  "@/pages/Admin/Manual.vue";

import AdminSetting from "@/pages/Admin/Admin_setting.vue";
import Welcometo from "@/pages/Landingpage/Welcometo.vue";
import OurNetwork from "@/pages/Landingpage/OurNetwork.vue";
import Trackrecord from "@/pages/Landingpage/Trackrecord.vue";
import Testimonials from "@/pages/Landingpage/Testimonials.vue";
import Membership from "@/pages/Landingpage/Membership.vue";
import Partners from "@/pages/Landingpage/Partners.vue";
import Conference from "@/pages/Landingpage/Conference.vue";
import Links from "@/pages/Landingpage/Links.vue";
// import Currency from "@/pages/Admin/Currency.vue"
import Withdraw from "@/pages/Admin/Withdraw.vue";
import ServiceCharge from "@/pages/Admin/Service_charge.vue";
import Login from "../components/Login.vue";
import Freeze from "@/pages/Admin/Freeze.vue";



const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import("@/pages/Dashboard.vue") // โหลดคอมโพเนนต์ Dashboard.vue เมื่อมีการเรียกใช้เส้นทางนี้
      },
      {
        path: "/stats",
        name: "stats",
        component: UserProfile,
      },
      {
        path: "/notifications",
        name: "notifications",
        component: Notifications,
      },
      {
        path: "/icons",
        name: "icons",
        component: Icons,
      },
      {
        path: "/maps",
        name: "maps",
        component: Maps,
      },
      {
        path: "/typography",
        name: "typography",
        component: Typography,
      },
      {
        path: "/table-list",
        name: "table-list",
        component: TableList,
      },
      {
        path: "/Transaction",
        name: "Transaction",
        component: Accounting,
      },
      // {
      //   path: "/Transaction",
      //   name: "Transaction",
      //   component: Transaction,
      // },
      
      {
        path: "/invoice",
        name: "Member Invoice", 
        component: Fps,
      },
      {
        path: "/invoiceChart",
        name: "Member Invoice Chart", 
        component: FpsChart,
      },
      {
        path: "/refund",
        name: "Refund System", 
        component: Refund,
      },
      {
        path: "/Withdraw",
        name: "Withdraw",
        component: Withdraw,
      },

      {
        path: "/invoiceapprove",
        name: "Member Invoice approve", 
        component: FpsApprove,
      },
      {
        path: "/ServiceCharge",
        name: "Service Charge", 
        component: ServiceCharge,
      },
      {
        path: "/refill",
        name: "refill",
        component: Refill,
      },
      // {
      //   path: '/Currency',
      //   name: 'Currency',
      //   component: Currency,
      // },
      {
        path: '/Admin',
        name: 'Admin Setting',
        component: AdminSetting,
      },
      {
        path: '/welcometo',
        name: 'welcometo',
        component: Welcometo,
      },
      {
        path: '/Conference',
        name: 'Conference',
        component: Conference,
      },
      {
        path: '/ournetwork',
        name: 'ournetwork',
        component: OurNetwork,
      },
      {
        path: "/Membership",
        name: "Membership",
        component: Membership,
      },
      {
        path: "/trackrecord",
        name: "Track Record", 
        component: Trackrecord,
      },
      {
        path: '/testimonials',
        name: 'Testimonials',
        component: Testimonials,
      },
      {
        path: '/Partners',
        name: 'Partners',
        component: Partners,
      },
      {
        path: '/Links',
        name: 'Links',
        component: Links,
      },
      {
        path: '/Manual',
        name: 'Manual',
        component: Manual,
      },
      {
        path: '/Freeze',
        name: 'Freeze',
        component: Freeze,
      },
      
    ],
  },
   { 
    path: "/login",  // Add the login route
    name: "Login",
    component: Login,
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/


export default routes;
