<template>
    <div class="row">
      <div class="col-md-12">
        <Card :title="table1.title" :subTitle="table1.subTitle">
          <div slot="raw-content" class="table-responsive">
            <div class="mb-3 d-flex justify-content-end">
              <form class="form-inline">
                <div class="form-group mr-3">
                  <v-select
                  v-model="selectedOption"
                  :options="filteredOptions"
                  label="company_name_en"
                  placeholder="Select account"
                  @input="handleChange"
                  style="width: 250px;"
                ></v-select>
                  </div>
  
                  <div class="form-group mr-3">
                <select v-model="selectedFilterType"
                  class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                  style="width: 100px; height: 38px;">
                  <option value="1">ALL</option>
                  <option value="Invoice">INVOICE</option>
                  <option value="FPS">FPS</option>
                  <!-- Add more options as needed -->
                </select>
              </div>
                  <div class="form-group mr-3">
                    <select
                      v-model="selectedFilter"
                      class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                      style="width: 200px; height: 38px;"
                    >
                      <option value="selected_month">Selected Month</option>
                      <option value="selected_year">Selected Year</option>
                      <option value="selected_day">Selected Day</option>
                     
                    </select>
                  </div>
  
                    <div class="form-group mr-3">
                      <div v-if="selectedFilter === 'selected_day'">
                        <input
                          type="date"
                          v-model="searchQuery"
                          placeholder="Select Date..."
                          class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                          style="width: 200px; height: 38px;" 
                        />
                      </div>
  
                  <div v-else-if="selectedFilter === 'selected_month'">
                    <input
                      type="month"
                      v-model="searchQuery"
                      placeholder="Select Month..."
                      class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                      style="width: 200px; height: 38px;" 
                    />
                  </div>
                  <div v-else-if="selectedFilter === 'selected_year'">
                    <input
                      type="number"
                      v-model="searchQuery"
                      placeholder="Select year..."
                      class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                      style="width: 200px; height: 38px;" 
                    />
                  </div>
                </div>
  
                <button
                  @click="search(selectedFilter, searchQuery , selectedFilterType)"
                  class="btn btn-outline-danger mr-2 "
                >
                  <i class="ti-search"></i> Search
                </button>
                <button @click="reload()" class="btn btn-outline-info mr-2 ">
                  <i class="ti-reload"></i> 
                </button>
  
                <!-- <button
                  @click="getDataAndGenerateFpsPDF"
                  class="btn btn-outline-default mr-2 "
                >
                  <i class="ti-printer"></i> Print Report
                </button> -->
              </form>
            </div>
            <table class="table table-bordered">
              <thead class="bg-light  bold-text" >
                <tr>
                  <th style="font-weight: bold; font-size: medium;">#</th>
                  <th style="font-weight: bold;font-size: medium">Type</th>
                  <th style="font-weight: bold;font-size: medium">Invoice Number</th>
                  <th style="font-weight: bold;font-size: medium">Company</th>
                  <th style="font-weight: bold;font-size: medium">Issued To</th>
                  
                  <th style="font-weight: bold;font-size: medium">Issue Date</th>
                  <th style="font-weight: bold;font-size: medium">Due Date</th>
                  <th style="font-weight: bold;font-size: medium"> Currency</th>
                  <!-- <th style="font-weight: bold;">Currency Rate</th> -->
                  <th style="font-weight: bold;font-size: medium">Amount Due</th>
                  <!-- <th style="font-weight: bold;">Updated Currency</th> -->
                  <th style="font-weight: bold;font-size: medium">Charge (%)</th>
                  <!-- <th style="font-weight: bold;font-size: medium">Total Charge</th> -->
                  <th style="font-weight: bold;font-size: medium">Total Amount</th>
                  <th style="font-weight: bold;font-size: medium">Note</th>
                  <th style="font-weight: bold;font-size: medium">Remark</th>
                  <th style="font-weight: bold;font-size: medium">SVC File</th>
                  <th style="font-weight: bold;font-size: medium">Invoice File</th>
                  <th style="font-weight: bold;font-size: medium">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(transaction, index) in Myinvoice" :key="transaction.id">
                  <td class="py-2 pl-6 md-2 " style="width: 3%; ">{{ index + 1 }}</td>
                  <td class="py-2 pl-6 md-2 text-left" style="width: 8%;">{{ transaction.Invoice_type }}</td>
                  <td class="py-2 pl-6 md-2 text-left" style="width: 8%;">{{ transaction.invoiceNo }}</td>
                  <td class="py-2 pl-6 md-2 text-left" style="width: 15%; ">{{ transaction.company_name }}</td>
                  <td class="py-2 pl-6 md-2 text-left" style="width: 15%;">{{ transaction.issuedTo }}</td>
                  
                  
                  <td class="py-2 pl-6 md-2" style="width: 8%;">{{ formatDate(transaction.issueDate) }}</td>
                <td class="py-2 pl-6 md-2 " style="width: 8%;">{{ formatDate(transaction.dueDate)}}</td>
                  <td class="py-2 pl-6 md-2 " style="width: 3%;">{{ transaction.currency }}</td>
                  <!-- <td class="py-2 pl-6 md-2 text-right" style="width: 3%;">{{ transaction.currency_rate }}</td> -->
                  <td class="py-2 pl-6 md-2 text-right" style="width: 3%;">{{ parseFloat(transaction.amountDue).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  <!-- <td class="py-2 pl-6 md-2 text-right" style="width: 3%;">{{ parseFloat(transaction.currency_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>-->
                  <td v-if="transaction.service_charge" class="py-2 pl-6 md-2 text-right" style="width: 8%;">{{ parseFloat(transaction.service_charge_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} {{"(" + transaction.service_charge + "%)" }}</td>
                  <td v-else class="py-2 pl-6 md-2 text-right" style="width: 3%;">-</td>
                  <td v-if="transaction.service_charge_amount"class="py-2 pl-6 md-2 text-right" style="width: 5%;">{{ parseFloat(transaction.totalamount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  <td v-else class="py-2 pl-6 md-2 text-right" style="width: 5%;">-</td>
                  <!-- <td v-if="transaction.totalamount"class="py-2 pl-6 md-2 text-right" style="width: 3%;">{{ parseFloat(transaction.totalamount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  <td v-else class="py-2 pl-6 md-2 text-right" style="width: 3%;">-</td> -->
                  <td class="py-2 pl-6 md-2 text-left" style="width: 15%;">{{ transaction.note }}</td>
                  <td v-if="(transaction.approve_status == 2 ) && transaction.submitBy !== null" class="py-2 pl-6 md-2 text-left" style="width: 15%;">
                    - Approve by {{ transaction.approveBy }}<br>
                    - Submit by {{ transaction.submitBy }}
                  </td>
               
                <td v-else-if="( transaction.approve_status == 1) " class="py-2 pl-6 md-2 text-left" style="width: 15%;">- Submit by {{ transaction.submitBy }}</td>
                <td v-else class="py-2 pl-6 md-2 text-left" style="width: 15%;">- Cancel by {{ transaction.approveBy }}<br> - Submit by {{ transaction.submitBy }}</td>
                <td style="text-align: center; width: 5%;">
                    <button v-if="transaction.invoiceFile" @click="openFileModal(getImageUrlFPS(transaction.attached_file))" class="btn btn-outline-default">
                      <i class="ti-eye"></i> View File
                    </button>
                    <span v-else class="text-muted">No File</span>
                  </td>
                  <td style="text-align: center; width: 5%;">
                  <button v-if="transaction.invoiceFile" @click="showWithDrawModal(transaction.invoiceFile)"
                    class="btn btn-outline-info">
                    <i class="ti-eye"></i> View File
                  </button>
                  <span v-else class="text-muted">No File</span>
                </td>

                  <td style="text-align: center; width: 5%;">
                    <button 
                      :style="{
                        backgroundColor: getStatusColor(transaction.approve_status),
                        color: 'white',
                        padding: '0.5rem',
                        borderRadius: '5px',
                        border: '1px solid ' + getStatusColor(transaction.approve_status),
                        cursor: 'pointer'
                      }" 
                      class="btn btn-outline"
                      @click="changeStatus(transaction)"
                      :disabled="transaction.approve_status === '2' || transaction.approve_status === '3'">
                      
                      {{ transaction.approve_status == 2 ? 'success' : transaction.approve_status == 1 ? 'pending' : transaction.approve_status == 3 ? 'cancel' : 'cancel'}}
                    </button>
                  </td>
                  
                </tr>
              </tbody>
            </table>
            <div class="main-container">
              <!-- ... (your existing template code) ... -->
              <div class="pagination-container">
                <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
                  Prev
                </div>
                <div v-else class="btn btn-secondary" disabled>
                  Prev
                </div>
                <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
                <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
                  Next
                </div>
                <div v-else @click="nextPage()" class="btn btn-outline-danger">
                  Next
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div v-if="showTranfer" class="modal-overlay" @click="closeWithDrawModal"></div>
      <Modal v-if="showTranfer" @close="closeWithDrawModal">
        <h5>View File</h5>


        <div class="form-group">
          <div v-for="(fileName, index) in fileToDisplay" :key="index">
            <span @click="openFileModal(getImageUrl(fileName))" class="file-name" style="cursor: pointer;">{{ fileName
              }} </span>
          </div>
        </div>



      </Modal>
      </div>
    </div>
  </template>
  <script setup>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import { Card, PaperTable } from "@/components";
  import Swal from 'sweetalert2';
  import 'vue-select/dist/vue-select.css';
  import vSelect from 'vue-select';
  import Modal from '@/components/Modal/MembershipsIcon.vue';
  
  const selectedFilter = ref('selected_month');
  const baseURL = "https://api2.logizall.com";
  const Myinvoice = ref([]);
  const Quertsearch = ref()
  const token = localStorage.getItem('token');
  const table1 = ref({
  title: "Member Invoice",
  subTitle: "Member Invoice",
  
  });
  const selectedFilterType = ref('1');
  
  const fileToDisplay = ref({});

const showTranfer = ref(false);
const showWithDrawModal = (file) => {
  const fileNames = file.split(',').map(name => name.trim());
  fileToDisplay.value = fileNames;
  console.log(fileToDisplay.value);
  showTranfer.value = true;
};

const closeWithDrawModal = () => {
  showTranfer.value = false;
};

  
  
  const currentPage = ref(1);
  const pageSize = 10; // Set your desired page size
  const totalPages = ref(1);
  const totalRecords = ref(0);
  const isSearch = ref(false);
  const isAdmin = ref(null);
  const changeStatus = async (transaction) => {
  if (isAdmin.value == 3) {
  const result = await Swal.fire({
    title: 'Change Status',
    text: 'Select the new status:',
    icon: 'warning',
    showCancelButton: true,
    showDenyButton: true,
    showConfirmButton: true,
    confirmButtonText: 'Approve',
    denyButtonText: 'Denied',
    cancelButtonText: 'Cancel',
  });
  
  if (result.isConfirmed) {
    updateStatus(transaction, '2');
  } else if (result.isDenied) {
    updateStatus(transaction, '3');
  } else if (result.isDismissed && result.dismiss == "cancel") { 
  }else{
  }
}else{
  window.location.reload();
  }
  };
  
  const updateStatus = async (transaction, newStatus) => {
  try {
    //console.log(token);
    //console.log("log", transaction.id, newStatus);
  
    const response = await axios.put(
      `${baseURL}/admin/changeStatus/${transaction.id}`,
      {
        newStatus: newStatus,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
  
    let message = '';
    let type = '';

    if (newStatus == 2) {
    message = 'The status has been changed to success successfully.';
    type = 'success';
    } else if (newStatus == 3) {
    message = 'The status has been changed to cancel.';
    type = 'success'; // Using 'error' type for the "Cancel" message
    }

    Swal.fire('Success', message, type).then(() => {
    // Reload the page after the user clicks "OK" on the success message
    window.location.reload();
    });

  } catch (error) {
    console.error('Error updating status:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while updating the status.',
    });
  }
  };
  
  
  const getMyinvoice = async () => {
  try {
  
    const response = await axios.get(`${baseURL}/admin/getfpsapprove`, {
      params: {
        page: currentPage.value,
        pageSize: pageSize,
      },
      headers: {
        Authorization: token,
      },
    });
    console.log("response",response)
    Myinvoice.value = response.data.data || [];
    totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
    totalRecords.value = response.data.totalRecords || [];
  } catch (error) {
    console.error('Error fetching My Wallet data:', error);
    // Handle the error appropriately, e.g., show an error message to the user.
  }
  };
  const prevPage = () => {
  prevOrNextPage('prev');
  };
  
  const nextPage = () => {
  prevOrNextPage('next');
  };
  
  const prevOrNextPage = (action) => {
  if (action == 'prev') {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
  } else if (action == 'next') {
    
    //console.log("pageSize",pageSize)
    //console.log("totalRecords",totalRecords.value)
    if (currentPage.value * pageSize <= totalRecords.value) {
      currentPage.value++;
    }
    //console.log("currentPage",currentPage.value)
  }
  
  
  // Check if it's a search or regular page navigation
  if (isSearch.value) {
    //console.log("isSearch",isSearch.value)
    search(selectedFilter.value, Quertsearch.value , selectedFilterType.value);
  } else {
    getMyinvoice();
  }
  };
  
  // const search = async (dateFilter, searchQuery , selectedtype) => {
  // //console.log("search");
  // //console.log("dateFilter", dateFilter);
  // //console.log("selectedDate", searchQuery);
  // Quertsearch.value = searchQuery;
  // //console.log("Quertsearch", Quertsearch.value);
  // //console.log("selectedOption", selectedOption.value);
  // try {
  //   // Check if selectedFilter is null
  //   if (dateFilter == "selected_month" && searchQuery == null && selectedtype == 1 && Quertsearch.value == null && selectedOption.value == null) {
  //     getMyinvoice();
  //     // Perform the search using the original API
  //     const response = await axios.get(`${baseURL}/admin/getfpsapprovebysearch`, {
  //       params: { dateFilter, selectedDate: searchQuery, selectedFilterType: selectedtype, page: currentPage.value, pageSize: pageSize },
  //       headers: {
  //         Authorization: token,
  //       },
  //     });
  
  //     // Destructure the response data
  //     const { data, total } = response.data;

      
  //     // Update values
  //     Myinvoice.value = data || [];
  //     totalRecords.value = total || [];
  
  //     // Set the search flag to true
  //     isSearch.value = true;
  //     totalPages.value = Math.ceil(response.data.total / pageSize);
  //     // Update the current page to 1, as the search results start from the first page

  //   } else {
  //     // Perform the search using the new API
  //     //console.log("selectedOption.value",selectedOption.value.id)
  //     const response = await axios.get(`${baseURL}/admin/getfpsapprovebysearchmid/${selectedOption.value.id}`, {
  //       params: { dateFilter, selectedDate: searchQuery, selectedFilterType: selectedtype, page: currentPage.value, pageSize: pageSize },
  //       headers: {
  //         Authorization: token,
  //       },
  //     });
  
  //     // Destructure the response data
  //     const { data, total } = response.data;
    
  //     // Update values
  //     Myinvoice.value = data || [];
  //     totalRecords.value = total || [];
  
  //     // Set the search flag to true
  //     isSearch.value = true;
  //     totalPages.value = Math.ceil(response.data.total / pageSize);
  
  //     // Update the current page to 1, as the search results start from the first page

  //   }
  // } catch (error) {
  //   console.error('Error fetching accounting data:', error);
  //   // Handle the error appropriately, e.g., show an error message to the user.
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'Error',
  //     text: 'An error occurred while fetching data.',
  //   });
  // }
  // };
let searchQuery
  const search = async (dateFilter, searchQuery, selectedtype) => {
  console.log("search")
  // console.log("search");
  // console.log("dateFilter", dateFilter);
  // console.log("selectedDate", searchQuery);
  Quertsearch.value = searchQuery;
  // console.log("Quertsearch", Quertsearch.value);
  // console.log("selectedOption", selectedOption.value);
  try {
    // Check if selectedFilter is null
    if (dateFilter == "selected_month" && searchQuery == null && selectedtype == 1 && Quertsearch.value == null && selectedOption.value == null) {
      getMyinvoice();
    }
    else {
      if (selectedOption.value === null) {
        // Perform the search using the original API
        const response = await axios.get(`${baseURL}/admin/getfpsapprovebysearch`, {
          params: { dateFilter, selectedDate: searchQuery, selectedFilterType: selectedtype, page: currentPage.value, pageSize: pageSize },
          headers: {
            Authorization: token,
          },
        });

        // Destructure the response data
        const { data, total } = response.data;

        // Update values
        Myinvoice.value = data || [];
        totalRecords.value = total || [];

        // Set the search flag to true
        isSearch.value = true;
        totalPages.value = Math.ceil(response.data.total / pageSize);
        // Update the current page to 1, as the search results start from the first page
      } else {
        // Perform the search using the new API
        console.log("selectedOption.value", selectedOption.value.id)
        const response = await axios.get(`${baseURL}/admin/getfpsapprovebysearchmid/${selectedOption.value.id}`, {
          params: { dateFilter, selectedDate: searchQuery, selectedFilterType: selectedtype, page: currentPage.value, pageSize: pageSize },
          headers: {
            Authorization: token,
          },
        });

        // Destructure the response data
        const { data, total } = response.data;

        // Update values
        Myinvoice.value = data || [];
        totalRecords.value = total || [];

        // Set the search flag to true
        isSearch.value = true;
        totalPages.value = Math.ceil(response.data.total / pageSize);

       
      }
    }
  } catch (error) {
    console.error('Error fetching accounting data:', error);
    // Handle the error appropriately, e.g., show an error message to the user.
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while fetching data.',
    });
  }
};
  
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};
  const openFileModal = (fileUrl) => {
  console.log(fileUrl);
  if (fileUrl) {
    // Check if the file is an image (JPEG, PNG, GIF)
    const isImage = /\.(jpe?g|png|gif)$/i.test(fileUrl);
  
    if (isImage) {
      // Display the image in a smaller SweetAlert modal
      Swal.fire({
        imageUrl: fileUrl,
        imageAlt: 'FPS',
        showCloseButton: true,
        customClass: {
          popup: 'smaller-modal', // Add a custom CSS class for the modal
        },
      });
    } else {
      // Open the PDF in a new window or tab
      window.open(fileUrl, '_blank');
    }
  } else {
    // Handle the case where there is no file
    Swal.fire({
      icon: 'info',
      title: 'No File',
      text: 'There is no file available for this transaction.',
    });
  }
  };
  const getImageUrlFPS = (filename) => {
  console.log("/admin/images/FPS/Servicecharge/", filename)
  // Replace 'localhost:4000' with your actual server address
  return `${baseURL}/admin/images/FPS/Servicecharge/${filename}`;
};
  
  const getImageUrl = (filename) => {
    console.log("/fps/images/FPS/",filename)
    // Replace 'localhost:4000' with your actual server address
    return `${baseURL}/fps/images/FPS/${filename}`;
  };
  const getStatusColor = (status) => {
  switch (status) {
    case '0':
      return 'orange';
    case '1':
      return 'orange';
      case '2':
      return 'green';
      case '3':
      return 'red';
    default:
      return 'red';
  }
  };
  
  const selectedOption = ref(null);
  const filteredOptions = ref([]);
  
  const handleChange = (value) => {
  // Handle the selected value here
  console.log('Selected Option:', value);
  // You can perform additional actions or update other variables as needed
  };
  const fetchTransfer = async () => {
    try {
      let currentPage = 1;
      let totalPages = 1;
      let allOptions = []; // เพิ่มตัวแปรเก็บข้อมูลทั้งหมด
  
      do {
        const response = await axios.get(`https://api.logizall.com/api/customers?pagination%5Bpage%5D=${currentPage}&pagination%5BpageSize%5D=100`);
        //console.log("response", response.data.meta.pagination.pageCount);
  
        totalPages = response.data.meta.pagination.pageCount;
  
        const options = response.data.data
          .filter(option => option.attributes.status === "bronze") // Filter out only active data
          .map(option => ({
            id: option.id,
            company_name_en: option.attributes.companyNameEn
          }));
  
        allOptions = allOptions.concat(options); // เพิ่ม options เข้าไปใน allOptions
  
        currentPage++;
  
      } while (currentPage <= totalPages);
  
      filteredOptions.value = allOptions; // ใส่ข้อมูลทั้งหมดลงใน filteredOptions.value
      //console.log(filteredOptions.value)
    } catch (error) {
      console.error('Error fetching balance types:', error);
    }
  };
  const reload = async () => {
    fetchTransfer();
    getMyinvoice();
    checkAdminRole();
};
const checkAdminRole = async () => {
  try {
    const response = await axios.get(
      "https://api2.logizall.com/auth/admin/verify-token",
      {
        headers: {
          Authorization: token,
        },
      },
    );

    // Check if user is admin with role 1

    // console.log("res", response.data.role_admin);
    isAdmin.value = response.data.role_admin;
    // return isAdmin ;
  } catch (error) {
    console.error("Failed to check admin role:", error.message);
    return false; // Default to false if role check fails
  }
};
  onMounted(() => {
    fetchTransfer();
    getMyinvoice();
    checkAdminRole()
  });

  
  
  </script>
  
  
  <style>
  .file-name:hover {
    color: red; /* เปลี่ยนสีของชื่อไฟล์เมื่อโฮเวอร์อยู่ที่นั้น */
}
    .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
    .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: center;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  .search-container {
    display: flex;
    /* align-items: right; */
    margin-left: auto;
  }
  
  
  .pagination-container {
  display: flex;
  justify-content: flex-end; /* Align items at the start of the container */
  align-items: stretch; /* Stretch items vertically */
  text-align: center; /* Center text within each item */
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  }
  
  .smaller-modal {
    max-width: 50%; /* Adjust the maximum width as needed */
  }
  
  .bea-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; /* Adjust the font size as needed */
  color: #333; /* Text color */
  
  width: 200px;
  }
  
  .bea-select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; /* Adjust the font size as needed */
  color: #333; /* Text color */
  width: 200px;
  }
  
  .bold-text {
  font-weight: bold;
  }
  
  </style>
  