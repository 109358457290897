<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" name="Dashboard" icon="ti-panel" />
        <!-- <sidebar-link to="/stats" name="User Profile" icon="ti-user" /> -->
        <!-- <sidebar-link
          to="/table-list"
          name="Table List"
          icon="ti-view-list-alt"
        />
        <sidebar-link to="/typography" name="Typography" icon="ti-text" /> -->
        <!-- <sidebar-link to="/icons" name="Icons" icon="ti-pencil-alt2" /> -->
        <!-- <sidebar-link to="/maps" name="Map" icon="ti-map" /> -->
        <sidebar-link v-if="isAdmin == 2" to="/invoice" name="Member Invoice" icon="ti-shield" />
        
        <sidebar-link v-if="isAdmin == 2" to="/invoiceChart" name="Member Invoice Log" icon="ti-stats-up" />
        <sidebar-link v-if="isAdmin == 3" to="/invoiceapprove" name="Member Invoice" icon="ti-shield" />
        <sidebar-link v-if="isAdmin != 1" to="/refund" name="Refund " icon="ti-money" />
        <sidebar-link v-if="isAdmin != 1" to="/Withdraw" name=" Withdraw" icon="ti-export" />
        <sidebar-link  to="/Transaction" name="Transaction" icon="ti-clipboard" />
        <!-- <sidebar-link to="/Transaction" name="Transaction" icon="ti-exchange-vertical" /> -->
        <sidebar-link to="/refill" name="Refill" icon="ti-wallet" />
        <sidebar-link v-if="isAdmin == 2" to="/ServiceCharge" name="Service Charge" icon="ti-support" />
        <!-- <sidebar-link to="/currency" name="Currency Rate" icon="ti-stats-up" /> -->
        <sidebar-link v-if="isAdmin == 3" to="/admin" name="Admin" icon="ti-user" />
        <sidebar-link v-if="isAdmin == 1" to="/Freeze" name="Freeze" icon="ti-timer" />
        
        <!-- <sidebar-link to="/notifications" name="Notifications" icon="ti-bell" /> -->
        <div class="nav-item" v-if="isAdmin != 2">
        <a class="nav-link" @click="toggleDropdown" >  
          
          <p> <i class="ti-settings"></i>Landing Page <i class="ti-angle-down small-icon"></i></p>
          
        </a>
        <ul v-if="showDropdown" class="nav">
          <li class="nav-item">
            <sidebar-select to="/welcometo" name="Welcome To" icon="ti-layout"/>
          </li>
          <li class="nav-item">
            <sidebar-select to="/conference" name="Conference" icon="ti-face-smile"/>
          </li>
          <li class="nav-item">
            <sidebar-select to="/ournetwork" name="Our network" icon="ti-bar-chart-alt"/>
          </li>

          <li class="nav-item">
            <sidebar-select to="/Membership" name="Membership" icon="ti-user"/>
          </li>
          <li class="nav-item">
            <sidebar-select to="/trackrecord" name="Track Record" icon="ti-link"/>
          </li>
          <li class="nav-item">
            <sidebar-select to="/testimonials" name="Testimonials" icon="ti-id-badge"/>
          </li>
          <li class="nav-item">
            <sidebar-select to="/partners" name="Partners" icon="ti-user"/>
          </li>
          <li class="nav-item">
            <sidebar-select to="/Links" name="Links" icon="ti-link"/>
          </li>
   
        </ul>
      </div>
      <sidebar-link to="/Manual" name="Manual" icon="ti-book" />
      </template>
      <mobile-menu>
        <li class="nav-item">
          <!-- <a class="nav-link">
            <i class="ti-panel"></i>
            <p>Stats</p>
          </a> -->
        </li>
        <!-- <drop-down
          class="nav-item"
          title="5 Notifications"
          title-classes="nav-link"
          icon="ti-bell"
        >
          <a class="dropdown-item">Notification 1</a>
          <a class="dropdown-item">Notification 2</a>
          <a class="dropdown-item">Notification 3</a>
          <a class="dropdown-item">Notification 4</a>
          <a class="dropdown-item">Another notification</a>
        </drop-down> -->

        <!-- <li class="divider"></li> -->
        <li class="nav-item">
            <a   class="nav-link pointer">
              <i class="ti-user"></i>
              <p>{{isAdmin == 1 ? 'admin': isAdmin == 2 ? 'accounting' : isAdmin == 3 ? 'manager' : 'admin' }}</p>
            </a>
          </li>
        <li class="nav-item">
          
            <a  @click="logout" class="nav-link pointer">
              <i class="ti-power-off"></i>
              <p>Logout</p>
            </a>
          </li>
        <li class="divider"></li>
      </mobile-menu>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import MovingArrow from "@/components/SidebarPlugin/MovingArrow.vue";
import axios from 'axios';
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    MovingArrow, // Register MovingArrow component
    
  },
  data() {
    return {
      showDropdown: false,
      arrowMoveY: 0, // Initialize arrowMoveY
      isAdmin: null, // กำหนดให้ isAdmin เป็น null ในตอนเริ่มต้น
      
    };
  },
  async created() {
    // Perform token verification and get user role
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get('https://api2.logizall.com/auth/admin/verify-token', {
          headers: {
            Authorization: token,
          },
        });

        // สมมติว่าข้อมูลผู้ใช้มีการส่งค่าบทบาทกลับมาแบบนี้ { role: 3 } เพื่อตรวจสอบบทบาทของผู้ใช้
        const role = response.data.role_admin;
        
        this.isAdmin = role
        console.log(this.isAdmin)
        // return role
      } catch (error) {
        console.error('Failed to verify token:', error.message);
      }
    }
  },
  
  methods: {

    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      // Adjust the arrowMoveY value based on your design
      this.arrowMoveY = this.showDropdown ? 20 : 0;
    },
    logout() {
      localStorage.removeItem('token');
      // console.log("logout", localStorage.getItem('token'));
      this.$notify({
        title: 'You have been Logout!!',
        text: 'You have been successfully logged out.',
        type: 'danger',
        duration: 3000,
      });
      this.$router.push('/login');
    },
    
  },
};
</script>
