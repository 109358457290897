<template>
    <div>
      <div class="container d-flex align-items-center justify-content-center vh-100">
        <div class="login-container shadow p-4 bg-white">
          <h2 class="mb-4">Login</h2>
          <form @submit.prevent="login">
            <div class="form-group mb-4">
              <label for="username" class="form-label custom-label">Username:</label>
              <input type="text" v-model="username" class="form-control custom-input" placeholder="username" required />
            </div>
            <div class="form-group mb-4">
              <label for="password" class="form-label custom-label">Password:</label>
              <input type="password" v-model="password" class="form-control custom-input"  placeholder="password" required />
            </div>
            <button type="submit" class="btn btn-success btn-block">Login</button>
          </form>
        </div>
      </div>
    </div>
  </template>
  

  
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        username: '',
        password: '',
      };
    },
    methods: {
        async login() {
  try {
    const response = await axios.post('https://api2.logizall.com/auth/admin/login',
    {
      username: this.username,
      password: this.password,
    });

    // console.log(response.data);
    const token = response.data.token;

    // Store the token in localStorage
    localStorage.setItem('token', token);

    // Check token validity before notifying success and navigating to the dashboard
    await this.verifyToken(token);

    // Notify about the successful login using vue-notification
    this.$notify({
      title: 'Login successful!', 
      text: 'Login successful!',
      type: 'success',
      duration: 3000, // Adjust the duration as needed
    });

    // Redirect to dashboard
    this.$router.push('/dashboard');
  } catch (error) {
    // Handle login error
    console.error('Login failed:', error);

    if (error.response && error.response.status === 401) {
      // Incorrect username or password
      this.$notify({
        title: 'Login failed Incorrect username or password. Please try again.' ,
        text: 'Incorrect username or password. Please try again.',
        type: 'danger',
        duration: 3000, // Adjust the duration as needed
      });
    } else {
      // Other errors
      this.$notify({
        title: 'Error Login failed. Please try again.',
        type: 'danger',
        duration: 3000, // Adjust the duration as needed
      });
    }
  }
},

  
      async verifyToken(token) {
        // console.log("TOKENNNN", token);
       
        // https://api2.logizall.com
        // Send a request to verify the token
        const response = await axios.get('https://api2.logizall.com/auth/admin/verify-token', {
          headers: {
            Authorization: token,
          },
        });
  
        // console.log(response);
  
        // If the token is invalid, throw an error
        if (!response.data.status == 200) {
          throw new Error('Invalid token');
        }
      },
    },
  };
  </script>
  
  <style scoped>

  
  .login-container {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  /* Customize the label styles for better visibility */
  .custom-label {
    display: block;
    font-weight: bold; /* Make the label bold */
    color: #333; /* Set a darker color for better contrast */
    margin-bottom: 8px; /* Add some bottom margin for separation */
  }
  
  /* Customize the input styles for better visibility during typing */
  .custom-input {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s;
  }
  
  .custom-input:focus {
    outline: none;
    border-color: #66afe9; /* Highlight color on focus, adjust as needed */
  }
  
  /* Optional: Customize the button color */
  .btn-success {
    background-color: #28a745; /* Green color for success button, adjust as needed */
    color: #fff; /* White text for better contrast */
    flex: auto;
  }
  
  .btn-success:hover {
    background-color: #218838; /* Darker green on hover, adjust as needed */
  }
  </style>
  
  
  