<template>
  <div class="row">
    <div class="col-md-12">
      <Card :title="table1.title" :subTitle="table1.subTitle">
        <div slot="raw-content" class="table-responsive">
          <div class="mb-3 d-flex justify-content-end">
            <form class="form-inline">
              <div class="form-group mr-3">
                <v-select
                v-model="selectedOption"
                :options="filteredOptions"
                label="company_name_en"
                placeholder="Select account"
                @input="handleChange"
                style="width: 250px;"
              ></v-select>
                </div>

                <div class="form-group mr-3">
                  <select
                    v-model="selectedFilter"
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;"
                  >
                    <option value="selected_month">Selected Month</option>
                    <option value="selected_year">Selected Year</option>
                    <option value="selected_day">Selected Day</option>
                   
                  </select>
                </div>

                  <div class="form-group mr-3">
                    <div v-if="selectedFilter === 'selected_day'">
                      <input
                        type="date"
                        v-model="searchQuery"
                        placeholder="Select Date..."
                        class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                        style="width: 200px; height: 38px;" 
                      />
                    </div>

                <div v-else-if="selectedFilter === 'selected_month'">
                  <input
                    type="month"
                    v-model="searchQuery"
                    placeholder="Select Month..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" 
                  />
                </div>
                <div v-else-if="selectedFilter === 'selected_year'">
                  <input
                    type="number"
                    v-model="searchQuery"
                    placeholder="Select year..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" 
                  />
                </div>
              </div>

              <button
                @click="search(selectedFilter, searchQuery)"
                class="btn btn-outline-danger mr-2 "
              >
                <i class="ti-search"></i> Search
              </button>
              <button @click="reload()" class="btn btn-outline-info mr-2 ">
                  <i class="ti-reload"></i> 
                </button>

              <!-- <button
                @click="getDataAndGenerateFpsPDF"
                class="btn btn-outline-default mr-2 "
              >
                <i class="ti-printer"></i> Print Report
              </button> -->
            </form>
          </div>
          <table class="table table-bordered">
            <thead class="bg-light  bold-text" >
              <tr>
                <th style="font-weight: bold;">#</th>
                <th style="font-weight: bold;">Company</th>
                <th style="font-weight: bold;">Invoice Number</th>
                <!-- <th style="font-weight: bold;">Issued To</th> -->
                <th style="font-weight: bold;">Issue Date</th>
                <th style="font-weight: bold;">Due Date</th>
                <th style="font-weight: bold;"> Currency</th>
                <th style="font-weight: bold;font-size: medium;">Currency Rate</th>
                <th style="font-weight: bold;font-size: medium;">Amount Due</th>
                <th style="font-weight: bold; font-size: medium;">Updated Currency</th>
                <!-- <th style="font-weight: bold;">Note</th> -->
                <!-- <th style="font-weight: bold;">Invoice File</th> -->
                <!-- <th style="font-weight: bold;">Status</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction, index) in Myinvoice" :key="transaction.id">
                <td class="py-2 pl-6 md-2 " style="width: 3%; ">{{ index + 1 }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width: 12%; ">{{ transaction.company_name }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width: 10%;">{{ transaction.invoiceNo }}</td>
                <!-- <td class="py-2 pl-6 md-2 text-left" style="width: 15%;">{{ transaction.issuedTo }}</td> -->
                <td class="py-2 pl-6 md-2" style="width: 8%;">{{ formatDate(transaction.issueDate) }}</td>
                <td class="py-2 pl-6 md-2 " style="width: 8%;">{{ formatDate(transaction.dueDate) }}</td>
                <td class="py-2 pl-6 md-2 " style="width: 5%;">{{ transaction.currency }}</td>
                <td class="py-2 pl-6 md-2 text-right" style="width: 5%;">{{ transaction.currency_rate }}</td>
                <td class="py-2 pl-6 md-2 text-right" style="width: 5%;">{{ parseFloat(transaction.amountDue).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})  }}</td>
                <td class="py-2 pl-6 md-2 text-right" style="width: 5%;">{{ parseFloat(transaction.currency_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                <!-- <td class="py-2 pl-6 md-2 text-left" style="width: 15%;">{{ transaction.note }}</td>
                <td style="text-align: center; width: 15%;">
                  <button v-if="transaction.invoiceFile" @click="openFileModal(getImageUrl(transaction.invoiceFile))" class="btn btn-outline-info">
                    <i class="ti-eye"></i> View File
                  </button>
                  <span v-else class="text-muted">No File</span>
                </td>
                <td style="text-align: center; width: 15%;">
                  <button 
                    :style="{
                      backgroundColor: getStatusColor(transaction.fps_status),
                      color: 'white',
                      padding: '0.5rem',
                      borderRadius: '5px',
                      border: '1px solid ' + getStatusColor(transaction.fps_status),
                      cursor: 'pointer'
                    }" 
                    class="btn btn-outline"
                    @click="changeStatus(transaction)"
                    :disabled="transaction.fps_status === 'Active' || transaction.fps_status === 'Inactive'">
                    
                    {{ transaction.fps_status }}
                  </button>
                </td> -->
                
              </tr>
            </tbody>
          </table>
          <div class="main-container">
            <!-- ... (your existing template code) ... -->
            <div class="pagination-container">
              <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
                Prev
              </div>
              <div v-else class="btn btn-secondary" disabled>
                Prev
              </div>
              <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
              <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
                Next
              </div>
              <div v-else @click="nextPage()" class="btn btn-outline-danger">
                Next
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { Card, PaperTable } from "@/components";
import Swal from 'sweetalert2';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';


const selectedFilter = ref('selected_month');
const baseURL = "https://api2.logizall.com";
const Myinvoice = ref([]);
const Quertsearch = ref()
const token = localStorage.getItem('token');
const table1 = ref({
title: "Member Invoice",
subTitle: "Member Invoice",

});




const currentPage = ref(1);
const pageSize = 10; // Set your desired page size
const totalPages = ref(1);
const totalRecords = ref(0);
const isSearch = ref(false);

const changeStatus = async (transaction) => {
const result = await Swal.fire({
  title: 'Change Status',
  text: 'Select the new status:',
  icon: 'warning',
  showCancelButton: true,
  showDenyButton: true,
  showConfirmButton: true,
  confirmButtonText: 'Active',
  denyButtonText: 'Inactive',
  cancelButtonText: 'Cancel',
});

if (result.isConfirmed) {
  updateStatus(transaction, 'Active');
} else if (result.isDenied) {
  updateStatus(transaction, 'Inactive');
} else if (result.isDismissed && result.dismiss == "cancel") { 
}else{
}
};

const updateStatus = async (transaction, newStatus) => {
try {
  //console.log(token);
  //console.log("log", transaction.id, newStatus);

  const response = await axios.put(
    `${baseURL}/admin/updateStatus/${transaction.id}`,
    {
      newStatus: newStatus,
    },
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );

  //console.log(response);

  // Handle the response accordingly
  // You may want to refresh the data or update the status directly in the component
  // For example: transaction.fps_status = response.data.newStatus;
  Swal.fire('Success', `The status has been changed to ${newStatus} successfully.`, 'success')
  .then(() => {
      // Reload the page after the user clicks "OK" on the success message
      window.location.reload();
    });
} catch (error) {
  console.error('Error updating status:', error);
  Swal.fire({
    icon: 'error',
    title: 'Error',
    text: 'An error occurred while updating the status.',
  });
}
};


const getMyinvoice = async () => {
try {

  const response = await axios.get(`${baseURL}/admin/getfps`, {
    params: {
      page: currentPage.value,
      pageSize: pageSize,
    },
    headers: {
      Authorization: token,
    },
  });
  //console.log("response",response)
  Myinvoice.value = response.data.data || [];
  totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
  totalRecords.value = response.data.totalRecords || [];
} catch (error) {
  console.error('Error fetching My Wallet data:', error);
  // Handle the error appropriately, e.g., show an error message to the user.
}
};
const prevPage = () => {
prevOrNextPage('prev');
};

const nextPage = () => {
prevOrNextPage('next');
};

const prevOrNextPage = (action) => {
if (action == 'prev') {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
} else if (action == 'next') {
  
  //console.log("pageSize",pageSize)
  //console.log("totalRecords",totalRecords.value)
  if (currentPage.value * pageSize <= totalRecords.value) {
    currentPage.value++;
  }
  //console.log("currentPage",currentPage.value)
}


// Check if it's a search or regular page navigation
if (isSearch.value) {
  //console.log("isSearch",isSearch.value)
  search(selectedFilter.value, Quertsearch.value);
} else {
  getMyinvoice();
}
};

const search = async (dateFilter, searchQuery) => {
//console.log("search");
//console.log("dateFilter", dateFilter);
//console.log("selectedDate", searchQuery);
Quertsearch.value = searchQuery;
//console.log("Quertsearch", Quertsearch.value);
//console.log("selectedOption", selectedOption.value);
try {
  // Check if selectedFilter is null
  if (selectedOption.value === null) {
    // Perform the search using the original API
    const response = await axios.get(`${baseURL}/admin/getfpsbysearch`, {
      params: { dateFilter, selectedDate: searchQuery, page: currentPage.value, pageSize: pageSize },
      headers: {
        Authorization: token,
      },
    });

    // Destructure the response data
    const { data, total } = response.data;
    


    
    // Update values
    Myinvoice.value = data || [];
    totalRecords.value = total || [];

    // Set the search flag to true
    isSearch.value = true;
    totalPages.value = Math.ceil(response.data.total / pageSize);
    // Update the current page to 1, as the search results start from the first page


    try {
      //console.log("try pdf")
      const responsepdf = await axios.get(`${baseURL}/admin/getfpsbysearchpdf`, {
        params: { dateFilter, selectedDate: searchQuery},
        headers: {
          Authorization: token,
        },
      });
      const datapdf = responsepdf.data.data;
      const totalAmount = responsepdf.data.totalAmountDue || 0;
      // เพิ่มลำดับในข้อมูล
      const dataWithIndex = datapdf.map((record, index) => {
        return { 
            ลำดับ: index + 1,
          ...record,
      
        };
      });
      summaryData.value = totalAmount || 0;
      pdfContent.value = generatePDFContent(dataWithIndex);
      fetchedData.value = dataWithIndex;
      // printReport()

    } catch (error) {
      console.error('Error retrieving data or generating PDF', error);
    }
  } else {
    // Perform the search using the new API
    //console.log("selectedOption.value",selectedOption.value.id)
    const response = await axios.get(`${baseURL}/admin/getfpsbysearchmid/${selectedOption.value.id}`, {
      params: { dateFilter, selectedDate: searchQuery, page: currentPage.value, pageSize: pageSize },
      headers: {
        Authorization: token,
      },
    });

    // Destructure the response data
    const { data, total } = response.data;
  
    // Update values
    Myinvoice.value = data || [];
    totalRecords.value = total || [];

    // Set the search flag to true
    isSearch.value = true;
    totalPages.value = Math.ceil(response.data.total / pageSize);

    // Update the current page to 1, as the search results start from the first page
    try {
      //console.log("try pdf")
      const responsepdf = await axios.get(`${baseURL}/admin/getfpsbysearchmidpdf/${selectedOption.value.id}`, {
        params: { dateFilter, selectedDate: searchQuery},
        headers: {
          Authorization: token,
        },
      });
      const datapdf = responsepdf.data.data;
      const totalAmount = responsepdf.data.totalAmountDue || 0;
      // เพิ่มลำดับในข้อมูล
      const dataWithIndex = datapdf.map((record, index) => {
        return { 
            ลำดับ: index + 1,
          ...record,
      
        };
      });
      summaryData.value = totalAmount || 0;
      pdfContent.value = generatePDFContent(dataWithIndex);
      fetchedData.value = dataWithIndex;
      // printReport()

    } catch (error) {
      console.error('Error retrieving data or generating PDF', error);
    }
  }
} catch (error) {
  console.error('Error fetching accounting data:', error);
  // Handle the error appropriately, e.g., show an error message to the user.
  Swal.fire({
    icon: 'error',
    title: 'Error',
    text: 'An error occurred while fetching data.',
  });
}
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};
const openFileModal = (fileUrl) => {
console.log(fileUrl);
if (fileUrl) {
  // Check if the file is an image (JPEG, PNG, GIF)
  const isImage = /\.(jpe?g|png|gif)$/i.test(fileUrl);

  if (isImage) {
    // Display the image in a smaller SweetAlert modal
    Swal.fire({
      imageUrl: fileUrl,
      imageAlt: 'FPS',
      showCloseButton: true,
      customClass: {
        popup: 'smaller-modal', // Add a custom CSS class for the modal
      },
    });
  } else {
    // Open the PDF in a new window or tab
    window.open(fileUrl, '_blank');
  }
} else {
  // Handle the case where there is no file
  Swal.fire({
    icon: 'info',
    title: 'No File',
    text: 'There is no file available for this transaction.',
  });
}
};


const getImageUrl = (filename) => {
  console.log("/fps/images/FPS/",filename)
  // Replace 'localhost:4000' with your actual server address
  return `${baseURL}/fps/images/FPS/${filename}`;
};
const getStatusColor = (status) => {
switch (status) {
  case 'Pending':
    return 'orange';
  case 'Active':
    return 'green';
  default:
    return 'red';
}
};

const selectedOption = ref(null);
const filteredOptions = ref([]);

const handleChange = (value) => {
// Handle the selected value here
console.log('Selected Option:', value);
// You can perform additional actions or update other variables as needed
};
const fetchTransfer = async () => {
  try {
    let currentPage = 1;
    let totalPages = 1;
    let allOptions = []; // เพิ่มตัวแปรเก็บข้อมูลทั้งหมด

    do {
      const response = await axios.get(`https://api.logizall.com/api/customers?pagination%5Bpage%5D=${currentPage}&pagination%5BpageSize%5D=100`);
      //console.log("response", response.data.meta.pagination.pageCount);

      totalPages = response.data.meta.pagination.pageCount;

      const options = response.data.data
        .filter(option => option.attributes.status === "bronze") // Filter out only active data
        .map(option => ({
          id: option.id,
          company_name_en: option.attributes.companyNameEn
        }));

      allOptions = allOptions.concat(options); // เพิ่ม options เข้าไปใน allOptions

      currentPage++;

    } while (currentPage <= totalPages);

    filteredOptions.value = allOptions; // ใส่ข้อมูลทั้งหมดลงใน filteredOptions.value
    //console.log(filteredOptions.value)
  } catch (error) {
    console.error('Error fetching balance types:', error);
  }
};

const reload = async () => {
  fetchTransfer();
  getMyinvoice();
};
onMounted(() => {
  fetchTransfer();
  getMyinvoice();
});

const pdfContent = ref('');
const summaryData = ref('');
  const fetchedData = ref([]);
  const tableHeaders = ref([ 'No', 'Company Name', 'InvoiceNo', 'IssuedTo', 'IssueDate', 'DueDate', 'Currency', 'Amount Due' , 'Note' , 'Status']);

  const getDataAndGenerateFpsPDF = async () => {
  try {
    if (isSearch.value == false) {
      //console.log("isSearch",isSearch.value)
      const response = await axios.get(`${baseURL}/admin/getfpspdf`, {
        headers: {
          Authorization: token,
        },
      });

      const data = response.data.data;
      const totalAmountDue = response.data.summary.totalAmountDue || 0;

      // เพิ่มลำดับในข้อมูล
      const dataWithIndex = data.map((record, index) => {
        return {
          ลำดับ: index + 1,
          ...record,
        };
      });

      // เพิ่ม totalAmountDue ใน summary
      const summary = {
        totalAmountDue: totalAmountDue,
        // สามารถเพิ่ม properties อื่น ๆ ของ summary ตามที่ต้องการ
      };
      //console.log("sum",summary.totalAmountDue[0].totalAmountDue)
      summaryData.value = summary.totalAmountDue[0].totalAmountDue
      pdfContent.value = generatePDFContent(dataWithIndex, summary);
      fetchedData.value = dataWithIndex;
      printReport();
    } else {
      printReport();
    }
  } catch (error) {
    console.error('Error retrieving data or generating PDF', error);
  }
};


  
  const generatePDFContent = (data) => {
  let content = 'ข้อมูลจาก API:\n\n';
  data.forEach((record, index) => {
    // console.log(formatDate(record.dateCreate))
    // Format ข้อมูลวันที่ด้วย formatDate function
    const formattedDateCreate = formatDate(record.dateCreate);

    content += `Record ${index + 1}:\n`;
    for (const [key, value] of Object.entries(record)) {
      // เช็คว่า key เป็น 'dateCreate' หรือไม่ ถ้าใช่ให้ใช้ formattedDateCreate
      const displayValue = key == 'dateCreate' ? formattedDateCreate : value;
      // console.log("displayValue",displayValue)
      content += `${key}: ${displayValue}\n`;
    }
    content += '\n';
  });
  content += 'Summary:\n';
  for (const [key, value] of Object.entries(summaryData)) {
    content += `${key}: ${value}\n`;
  }
  return content;
};


  
  
const printReport = () => {
  if (pdfContent.value && fetchedData.value && summaryData) {
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Print Report</title>');
    // Add custom styles for the table
    printWindow.document.write(`
      <style>
        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 40px;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
          font-size: 12px; 
        }
        th {
          background-color: #f2f2f2;
        }
        /* Add a specific style for the summary row */
        .summary-row {
          font-weight: bold;
          background-color: #e6e6e6; /* Light gray background color */
        }
      </style>
    `);
    printWindow.document.write('</head><body>');

    // Create the table
    printWindow.document.write('<table>');
    printWindow.document.write('<thead><tr>');
    // Use tableHeaders instead of fetching from the first record
    tableHeaders.value.forEach(header => {
      printWindow.document.write(`<th>${header}</th>`);
    });
    printWindow.document.write('</tr></thead><tbody>');

    // Populate the table with data
    fetchedData.value.forEach((record, index) => {
      printWindow.document.write('<tr>');
      for (const [key, value] of Object.entries(record)) {
        // Check if the key is 'dateTransfer', 'balance_type', 'datePublic', or other specific keys
        const displayValue = key === 'dateTransfer' ? formatDate(value) : 
                            (key === 'balance_type' ? (value === 1 ? 'Deposit' : value) :
                            (key === 'datePublic' ? formatDate(value) : value));
        printWindow.document.write(`<td>${displayValue}</td>`);
      }
      printWindow.document.write('</tr>');
    });

    // Add the summary row with enhanced styling
    printWindow.document.write('<tr class="summary-row">');
    for (const [key, value] of Object.entries(summaryData)) {
      // Handle display logic for summary keys if needed
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td>Summary</td>`);
      printWindow.document.write(`<td>${value}</td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
    }
    printWindow.document.write('</tr>');

    printWindow.document.write('</tbody></table>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  } else {
    console.warn('No content to print. Generate a report first.');
  }
};



</script>


<style>
  .form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

  .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.search-container {
  display: flex;
  /* align-items: right; */
  margin-left: auto;
}


.pagination-container {
display: flex;
justify-content: flex-end; /* Align items at the start of the container */
align-items: stretch; /* Stretch items vertically */
text-align: center; /* Center text within each item */
margin-top: 10px;
margin-bottom: 10px;
margin-right: 10px;
}

.smaller-modal {
  max-width: 50%; /* Adjust the maximum width as needed */
}

.bea-input {
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 14px; /* Adjust the font size as needed */
color: #333; /* Text color */

width: 200px;
}

.bea-select {
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 14px; /* Adjust the font size as needed */
color: #333; /* Text color */
width: 200px;
}

.bold-text {
font-weight: bold;
}

</style>
