<template>
  <div class="row">
    <div class="col-md-12">
      <Card :title="table1.title" :subTitle="table1.subTitle">
        <div slot="raw-content" class="table-responsive">
         
          <!-- <div class="mb-3 d-flex justify-content-start ">
            <button class="btn btn-outline-default mr-3 ml-3 mt-2" @click="openUpdateStatusModal">
              <i class="ti-settings mr-2"></i> Accounting Management 
            </button>
                
              </div> -->
          <div class="mb-3 d-flex justify-content-end">
            
            <form class="form-inline">
             
              <div class="form-group mr-3">
                <v-select
                v-model="selectedOption"
                :options="filteredOptions"
                label="company_name_en"
                placeholder="Select account"
                @input="handleChange"
                style="width: 300px; "
              ></v-select>
                </div>
                <div class="form-group mr-3">
                  <select
                    v-model="selectedFilterType"
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 100px; height: 38px;"
                  >
                    <option value="1">IN/OUT</option>
                    <option value="IN">IN</option>
                    <option value="OUT">OUT</option>
                    <!-- Add more options as needed -->
                  </select>
                </div>

                <div class="form-group mr-3">
                  <select
                    v-model="selectedFilter"
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;"
                  >
                    <option value="selected_month">Selected Month</option>
                    <option value="selected_year">Selected Year</option>
                    <option value="selected_day">Selected Day</option>
                    <!-- Add more options as needed -->
                  </select>
                </div>

                  <div class="form-group mr-3">
                    <div v-if="selectedFilter === 'selected_day'">
                      <input
                        type="date"
                        v-model="searchQuery"
                        placeholder="Select Date..."
                        class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                        style="width: 200px; height: 38px;" 
                      />
                    </div>

                <div v-else-if="selectedFilter === 'selected_month'">
                  <input
                    type="month"
                    v-model="searchQuery"
                    placeholder="Select Month..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" 
                  />
                </div>
                <div v-else-if="selectedFilter === 'selected_year'">
                  <input
                    type="number"
                    v-model="searchQuery"
                    placeholder="Select year..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" 
                  />
                </div>
              </div>

              <button
                @click="search(selectedFilter, searchQuery,selectedFilterType)"
                class="btn btn-outline-danger mr-2 "
              >
                <i class="ti-search"></i> Search
              </button>
              <button
                @click="getDataAndGenerateAccountingPDF"
                class="btn btn-outline-default mr-2 "
              >
                <i class="ti-printer"></i> Print Report
              </button>
              <button @click="reload()" class="btn btn-outline-info mr-2 ">
                  <i class="ti-reload"></i> 
                </button>
            </form>
          </div>
          <table class="table table-bordered">
            <thead class="bg-light  bold-text" >
              <tr>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">#</th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold;">Company</th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold;">REF</th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold;">Date</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Type</th>
                <th class="py-2 pl-6 " style="width: 20%; font-weight: bold;">Details</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Currency</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Balance</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Amount</th>
              </tr>
            </thead>
            <tbody>
          <tr v-for="(transaction, index) in accountingTransactionList" :key="transaction.id">
            <td>{{ index + 1 }}</td>
            <td class="text-left">{{ transaction.company_name }}</td>
            <td class="text-left">{{ transaction.referent_number }}</td>
            <td class="text-left">{{ formatDate(transaction.dateCreate) }}</td>
            <td :style="{ color: transaction.account_type === 'IN' ? 'green' : 'red' }">{{ transaction.account_type }}</td>
            <td class="text-left">{{ transaction.detail }}</td>
            <td class="text-center">{{ transaction.currency }}</td>
            <td class="text-right" :style="{ color: transaction.account_type === 'IN' ? 'green' : 'red' }">{{ parseFloat(transaction.debit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) >= parseFloat(transaction.credit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) ? ("+" +parseFloat(transaction.debit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})) : "-" + parseFloat(transaction.credit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})  }}</td>
            <td class="text-right">{{ parseFloat(transaction.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>

          </tr>
        </tbody>
          </table>
          <div class="main-container"> 
            <!-- ... (your existing template code) ... -->
            <div class="pagination-container">
              <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
                Prev
              </div>
              <div v-else class="btn btn-secondary" disabled>
                Prev
              </div>
              <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
              <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
                Next
              </div>
              <div v-else @click="nextPage()" class="btn btn-outline-danger">
                Next
              </div>
            </div>
          </div>
          <!-- <div v-if="isUpdateStatusModalOpen" class="modal-overlay" @click="closeUpdateStatusModal"></div>
          <AccountingModal v-if="isUpdateStatusModalOpen" @closeModal="closeUpdateStatusModal" /> -->
        </div>
      </Card>
      
        </div>
      
  </div>

</template>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { Card, PaperTable } from "@/components";
import Swal from 'sweetalert2';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
import AccountingModal from '@/components/Modal/AccountingModal.vue';

const pdfContent = ref('');
const summaryDebit = ref('');
const summaryCredit = ref('');
const summaryAmount= ref('');
  const fetchedData = ref([]);
  const tableHeaders = ref([ 'No', 'Company Name', 'REF', 'Detail', 'Debit', 'Credit', 'Account type', 'Amount', 'DateCreate']);
const selectedFilter = ref('selected_month');
const selectedFilterType = ref('1');
const baseURL = "https://api2.logizall.com";
const accountingTransactionList = ref([]);
const Quertsearch = ref()
const token = localStorage.getItem('token');
const table1 = ref({
title: "Transaction",
subTitle: "Transaction",

});
let searchQuery
const currentPage = ref(1);
const pageSize = 10; // Set your desired page size
const totalPages = ref(1);
const totalRecords = ref(0);
const isSearch = ref(false);

const isUpdateStatusModalOpen = ref(false);

// Method to open the update status modal
const openUpdateStatusModal = () => {
  isUpdateStatusModalOpen.value = true;
};

// Method to close the update status modal
const closeUpdateStatusModal = () => {
  isUpdateStatusModalOpen.value = false;
};

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Asia/Bangkok',
  };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

const getAccounting = async () => {
try {

  const response = await axios.get(`${baseURL}/admin/getaccounting`, {
    params: {
      page: currentPage.value,
      pageSize: pageSize,
    },
    headers: {
      Authorization: token,
    },
  });
  //console.log("response",response)
  accountingTransactionList.value = response.data.data || [];
  totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
  totalRecords.value = response.data.totalRecords || [];
} catch (error) {
  console.error('Error fetching My Wallet data:', error);
  // Handle the error appropriately, e.g., show an error message to the user.
}
};
const prevPage = () => {
prevOrNextPage('prev');
};

const nextPage = () => {
prevOrNextPage('next');
};

const prevOrNextPage = (action) => {
if (action == 'prev') {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
} else if (action == 'next') {
  
  //console.log("pageSize",pageSize)
  //console.log("totalRecords",totalRecords.value)
  if (currentPage.value * pageSize <= totalRecords.value) {
    currentPage.value++;
  }
  //console.log("currentPage",currentPage.value)
}


// Check if it's a search or regular page navigation
if (isSearch.value) {
  //console.log("isSearch",isSearch.value)
  search(selectedFilter.value, Quertsearch.value,selectedFilterType.value);
} else {
  getAccounting();
}
};

const search = async (dateFilter, searchQuery ,selectedtype) => {
//console.log("search");
//console.log("dateFilter", dateFilter);
//console.log("selectedDate", searchQuery);
Quertsearch.value = searchQuery;
//console.log("selectedtype", selectedtype);
//console.log("Quertsearch", Quertsearch.value);
//console.log("selectedOption", selectedOption.value);
try {
  // Check if selectedFilter is null
  //console.log("if")
  if(dateFilter == "selected_month" && searchQuery == null && selectedtype == 1 && Quertsearch.value == null && selectedOption.value == null  ){
    getAccounting();
    try {
      //console.log("try pdf")
      const responsepdf = await axios.get(`${baseURL}/admin/getaccountingbysearchpdf`, {
        params: { dateFilter, selectedDate: searchQuery ,selectedFilterType:selectedtype},
        headers: {
          Authorization: token,
        },
      });
      const datapdf = responsepdf.data.data;
      const totalDebit = responsepdf.data.totalDebit || 0;
      const totalCredit = responsepdf.data.totalCredit || 0;
      const totalAmount = responsepdf.data.totalAmount || 0;
      summaryDebit.value = totalDebit;
      summaryCredit.value = totalCredit;
      summaryAmount.value = totalAmount ;
  
      // เพิ่มลำดับในข้อมูล
      const dataWithIndex = datapdf.map((record, index) => {
        return { 
          ลำดับ: index + 1,
            company_name: record.company_name,
            referent_number:record.referent_number,
            detail: record.detail,
            debit: parseFloat(record.debit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            credit: parseFloat(record.credit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            account_type: record.account_type,
            amount:parseFloat(record.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            // remark: record.remark,
            dateCreate:record.dateCreate,
            // ...record
      
        };
      });
  
      pdfContent.value = generatePDFContent(dataWithIndex);
      fetchedData.value = dataWithIndex;
      // printReport()

    } catch (error) {
      console.error('Error retrieving data or generating PDF', error);
    }
  }
  else{

  
  if (selectedOption.value === null) {
    // Perform the search using the original API
    const response = await axios.get(`${baseURL}/admin/getaccountingbysearch`, {
      params: { dateFilter, selectedDate: searchQuery,selectedFilterType:selectedtype, page: currentPage.value, pageSize: pageSize },
      headers: {
        Authorization: token,
      },
    });

    // Destructure the response data
    const { data, total } = response.data;
    // console.log(response.data)
    // Update values
    accountingTransactionList.value = data || [];
    totalRecords.value = total || [];

    // Set the search flag to true
    isSearch.value = true;
    totalPages.value = Math.ceil(response.data.total / pageSize);
    // Update the current page to 1, as the search results start from the first page


    //pdf

    try {
      //console.log("try pdf")
      const responsepdf = await axios.get(`${baseURL}/admin/getaccountingbysearchpdf`, {
        params: { dateFilter, selectedDate: searchQuery ,selectedFilterType:selectedtype},
        headers: {
          Authorization: token,
        },
      });
      const datapdf = responsepdf.data.data;
      const totalDebit = responsepdf.data.totalDebit || 0;
      const totalCredit = responsepdf.data.totalCredit || 0;
      const totalAmount = responsepdf.data.totalAmount || 0;
      summaryDebit.value = totalDebit;
      summaryCredit.value = totalCredit;
      summaryAmount.value = totalAmount ;
  
      // เพิ่มลำดับในข้อมูล
      const dataWithIndex = datapdf.map((record, index) => {
        return { 
          ลำดับ: index + 1,
            company_name: record.company_name,
            referent_number:record.referent_number,
            detail: record.detail,
            debit: parseFloat(record.debit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            credit: parseFloat(record.credit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            account_type: record.account_type,
            amount:parseFloat(record.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            // remark: record.remark,
            dateCreate:record.dateCreate,
            // ...record
      
        };
      });
  
      pdfContent.value = generatePDFContent(dataWithIndex);
      fetchedData.value = dataWithIndex;
      // printReport()

    } catch (error) {
      console.error('Error retrieving data or generating PDF', error);
    }


  } else {
    // Perform the search using the new API
    //console.log("selectedOption.value",selectedOption.value.id)
    const response = await axios.get(`${baseURL}/admin/getaccountingbysearchmid/${selectedOption.value.id}`, {
      params: { dateFilter, selectedDate: searchQuery,selectedFilterType:selectedtype, page: currentPage.value, pageSize: pageSize },
      headers: {
        Authorization: token,
      },
    });

    // Destructure the response data
    const { data, total } = response.data;

    // Update values
    accountingTransactionList.value = data || [];
    totalRecords.value = total || [];

    // Set the search flag to true
    isSearch.value = true;
    totalPages.value = Math.ceil(response.data.total / pageSize);

    try {
      //console.log("try pdf")
      const responsepdf = await axios.get(`${baseURL}/admin/getaccountingbysearchmidpdf/${selectedOption.value.id}`, {
        params: { dateFilter, selectedDate: searchQuery ,selectedFilterType:selectedtype},
        headers: {
          Authorization: token,
        },
      });
      const datapdf = responsepdf.data.data;
      const totalDebit = responsepdf.data.totalDebit || 0;
      const totalCredit = responsepdf.data.totalCredit || 0;
      const totalAmount = responsepdf.data.totalAmount || 0;
      summaryDebit.value = totalDebit;
      summaryCredit.value = totalCredit;
      summaryAmount.value = totalAmount ;
  
      // เพิ่มลำดับในข้อมูล
      const dataWithIndex = datapdf.map((record, index) => {
        return { 
          ลำดับ: index + 1,
            company_name: record.company_name,
            referent_number:record.referent_number,
            detail: record.detail,
            debit: parseFloat(record.debit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            credit: parseFloat(record.credit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            account_type: record.account_type,
            amount:parseFloat(record.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            // remark: record.remark,
            dateCreate:record.dateCreate,
            // ...record
      
        };
      });
  
      pdfContent.value = generatePDFContent(dataWithIndex);
      fetchedData.value = dataWithIndex;
      // printReport()

    } catch (error) {
      console.error('Error retrieving data or generating PDF', error);
    }
    // Update the current page to 1, as the search results start from the first page
  }
}
} catch (error) {
  console.error('Error fetching accounting data:', error);
  // Handle the error appropriately, e.g., show an error message to the user.
  Swal.fire({
    icon: 'error',
    title: 'Error',
    text: 'An error occurred while fetching data.',
  });
}
};


const selectedOption = ref(null);
const filteredOptions = ref([]);

const handleChange = (value) => {
// Handle the selected value here
//console.log('Selected Option:', value);
// You can perform additional actions or update other variables as needed
};
const fetchTransfer = async () => {
  try {
    let currentPage = 1;
    let totalPages = 1;
    let allOptions = []; // เพิ่มตัวแปรเก็บข้อมูลทั้งหมด

    do {
      const response = await axios.get(`https://api.logizall.com/api/customers?pagination%5Bpage%5D=${currentPage}&pagination%5BpageSize%5D=100`);
      //console.log("response", response.data.meta.pagination.pageCount);

      totalPages = response.data.meta.pagination.pageCount;

      const options = response.data.data
        .filter(option => option.attributes.status === "bronze") // Filter out only active data
        .map(option => ({
          id: option.id,
          company_name_en: option.attributes.companyNameEn
        }));

      allOptions = allOptions.concat(options); // เพิ่ม options เข้าไปใน allOptions

      currentPage++;

    } while (currentPage <= totalPages);

    filteredOptions.value = allOptions; // ใส่ข้อมูลทั้งหมดลงใน filteredOptions.value
    //console.log(filteredOptions.value)
  } catch (error) {
    console.error('Error fetching balance types:', error);
  }
};
const reload = async () => {
  fetchTransfer();
  getAccounting();
};

onMounted(() => {
  fetchTransfer();
  getAccounting();
});

const getDataAndGenerateAccountingPDF = async () => {
    try {

      if (isSearch.value == false) {
        //console.log("isSearch",isSearch.value)
        const response = await axios.get(`${baseURL}/admin/getaccountingpdf`, {
        headers: {
          Authorization: token,
        },
      });
      const data = response.data.data;
     console.log("response.data.summary.totalCredit",response.data.data)
      const totalDebit = response.data.summary.totalDebit || 0;
      const totalCredit = response.data.summary.totalCredit || 0;
      //console.log("response",totalCredit,totalDebit)
      // เพิ่มลำดับในข้อมูล
      const dataWithIndex = data.map((record, index) => {
        return {
            ลำดับ: index + 1,
            company_name: record.company_name,
            referent_number:record.referent_number,
            detail: record.detail,
            debit: parseFloat(record.debit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            credit: parseFloat(record.credit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            account_type: record.account_type,
            amount:parseFloat(record.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            // remark: record.remark,
            dateCreate:record.dateCreate,
            // ...record
      
        };
      });
      const summary = {
        totalDebit: totalDebit,
        totalCredit:totalCredit
        // สามารถเพิ่ม properties อื่น ๆ ของ summary ตามที่ต้องการ
      };
      //console.log("sum",summary)
      summaryDebit.value = summary.totalDebit.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      summaryCredit.value = summary.totalCredit.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      //console.log("response",summaryDebit.value,summaryCredit.value)
      pdfContent.value = generatePDFContent(dataWithIndex);
      fetchedData.value = dataWithIndex;
      printReport()



      } else {
  
      printReport()
}
      
    } catch (error) {
      console.error('Error retrieving data or generating PDF', error);
    }
  };
  
  const generatePDFContent = (data) => {
  let content = 'ข้อมูลจาก API:\n\n';
  data.forEach((record, index) => {
    // console.log(formatDate(record.dateCreate))
    // Format ข้อมูลวันที่ด้วย formatDate function
    const formattedDateCreate = formatDate(record.dateCreate);

    content += `Record ${index + 1}:\n`;
    for (const [key, value] of Object.entries(record)) {
      // เช็คว่า key เป็น 'dateCreate' หรือไม่ ถ้าใช่ให้ใช้ formattedDateCreate
      const displayValue = key == 'dateCreate' ? formattedDateCreate : value;
      // console.log("displayValue",displayValue)
      content += `${key}: ${displayValue}\n`;
    }
    content += '\n';
  });
  return content;
};


  
  
const printReport = () => {
  if (pdfContent.value && fetchedData.value) {
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Print Report</title>');
    // Add custom styles for the table
    printWindow.document.write(`
      <style>
        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 40px;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
          font-size: 11px; 
        }
        th {
          background-color: #f2f2f2;
        }
        .summary-row {
          font-weight: bold;
          background-color: #e6e6e6; /* Light gray background color */
        }

      </style>
    `);
    printWindow.document.write('</head><body>');

    // Create the table
    printWindow.document.write('<table>');
    printWindow.document.write('<thead><tr>');
    // Use tableHeaders instead of fetching from the first record
    tableHeaders.value.forEach(header => {
      printWindow.document.write(`<th>${header}</th>`);
    });
    printWindow.document.write('</tr></thead><tbody>');

    // Populate the table with data
    fetchedData.value.forEach((record, index) => {
      printWindow.document.write('<tr>');
      for (const [key, value] of Object.entries(record)) {
        // Check if the key is 'dateCreate', use formatDate
        const displayValue = key === 'dateCreate' ? formatDate(value) : value;
        
        printWindow.document.write(`<td>${displayValue}</td>`);
      }
      
      printWindow.document.write('</tr>');
      
      // Add page break after every 8 records

    });
    tableHeaders.value.forEach(header => {
      printWindow.document.write(`<th>${header}</th>`);
    });

    printWindow.document.write('<tr class="summary-row">');
    
   
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);

      printWindow.document.write(`<td>Summary</td>`);
      printWindow.document.write(`<td>${summaryDebit.value}</td>`);
      printWindow.document.write(`<td>${summaryCredit.value}</td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
    printWindow.document.write('</tr>');

    printWindow.document.write('</tbody></table>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  } else {
    console.warn('No content to print. Generate a report first.');
  }
};


</script>


<style>



.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

  .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.search-container {
  display: flex;
  /* align-items: right; */
  margin-left: auto;
}


.pagination-container {
display: flex;
justify-content: flex-end; /* Align items at the start of the container */
align-items: stretch; /* Stretch items vertically */
text-align: center; /* Center text within each item */
margin-top: 10px;
margin-bottom: 10px;
margin-right: 10px;
}

.smaller-modal {
  max-width: 50%; /* Adjust the maximum width as needed */
}

.bea-input {
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 14px; /* Adjust the font size as needed */
color: #333; /* Text color */

width: 200px;
}

.bea-select {
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 14px; /* Adjust the font size as needed */
color: #333; /* Text color */
width: 200px;
}

.bold-text {
font-weight: bold;
}

</style>

