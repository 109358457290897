<template>
  <div v-if="isUpdateStatusModalOpen" class="update-status-modal">
    <div class="modal-overlay" @click="closeModal"></div>
    <div class="modal-content">
      <!-- Your modal content goes here -->
      <h4>Member Invoice Management</h4>

      <!-- Add label and select dropdown for Customers Accounts -->
      <div class="form-group">
        <label for="customerAccount">Customers Accounts:</label>
        <v-select v-model="selectedOption" :options="filteredOptions" label="company_name_en" placeholder="Select account"
          @input="handleChange" style="width: 100%;"></v-select>
      </div>

      <!-- Add label and select dropdown for Order Type -->
      <div class="form-group">
        <label for="orderType">Order Type:</label>
        <select v-model="selectedOrderType" id="orderType" class="form-control" @change="handleOrderTypeChange">
          <!-- <option value="">please select..</option> -->
          <option value="2">Invoice</option>
          <option value="3">FPS</option>
          <!-- <option value="0">Refund</option> -->
        </select>
      </div>

      <!-- Conditionally show multiselection for FPS -->
      <div v-if="selectedOrderType === '2' || selectedOrderType === '3'">
        <div class="form-group">
          <label for="multiselectFPS">Select Invoice Options:</label>
          <!-- Add your multiselect component or input here -->
          <!-- Example using v-select for multiselect -->
          <v-select v-model="selectedFPSOptions" :options="fpsOptions" multiple label="invoiceNo"
            placeholder="Select options" style="width: 100%;" @input="handleMultiselectChange"></v-select>
        </div>
      </div>
      <div v-if="selectedOrderType === '0'">
        <div class="form-group">
          <label for="RefundAmount">Refund Amount (USD) :</label>
          <!-- Add your multiselect component or input here -->
          <!-- Example using v-select for multiselect -->
          <input type="text" v-model="modalData.refundamount" @input="handleChangerefundamount" class="form-control bordered-input p-2" placeholder="Enter Refund Amount"  />
        </div>
      </div>

      <!-- Display the customer's balance -->
      <div v-if="customerBalance !== 0">
        <p>Customer Balance: {{ parseFloat(customerBalance).toLocaleString('en-US', {style: 'currency', currency: 'USD',minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
      </div>
      <div v-if="customerBalance !== 0 && modalData.refundamount !== null">
        <p>
          Calculation: {{ parseFloat(customerBalance).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} <span :style="{ color: 'red' }">- {{ modalData.refundamount }}</span> = {{
            customerBalance - (parseFloat(modalData.refundamount) + parseFloat(modalData.refundamount) )}}
        </p>
      </div>
      <!-- Display Total Amount Due -->
      <!-- <div v-if="totalAmountDue !== 0">
        <p>Total Amount Due: <span :style="{ color: 'red' }">{{ totalAmountDue }}</span></p>
      </div> -->

      <!-- Display Remaining Balance -->
      <!-- <div v-if="remainBalance !== null">
        <p>Remaining Balance: {{ remainBalance }}</p>
      </div> -->

      <!-- Display calculation -->
      <div v-if="customerBalance != null && totalAmountDue != 0">
        <p>
          Calculation: {{ parseFloat(customerBalance).toLocaleString('en-US', {style: 'currency', currency: 'USD',minimumFractionDigits: 2, maximumFractionDigits: 2}) }} <span :style="{ color: 'red' }">- {{ parseFloat(totalAmountDue).toLocaleString('en-US', {style: 'currency', currency: 'USD',minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span> = {{
            parseFloat(remainBalance).toLocaleString('en-US', {style: 'currency', currency: 'USD',minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
        </p>
      </div>

      <!-- Add your form or content for updating status -->
      <button @click="submitAccountingData" class="btn btn-outline-success mr-2 mb-3">Submit</button>

      <button @click="closeModal" class="btn btn-outline-danger mr-2 ">Close</button>
    </div>
  </div>
</template>



<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';

const baseURL = "https://api2.logizall.com";
const token = localStorage.getItem('token');
const emits = defineEmits(['closeModal']);
const isUpdateStatusModalOpen = ref(true);

const modalData = ref({
  refundamount: '',

});
const filteredOptions = ref([]);
const customerBalance = ref(0);
const service_charge = ref(0);
const selectedOption = ref(null);
const selectedOrderType = ref('2');
const selectedFPSOptions = ref([]);
const fpsOptions = ref([]);
const selectedFPSAmounts = ref([]); // New array to store amounts for each selected option
const totalAmountDue = ref(0); // Total amountDue sum
const remainBalance = ref(0); // Remaining balance
const options = ref([]);
const resetSelections = () => {
  selectedOption.value = null;
  selectedOrderType.value = '2';
  selectedFPSOptions.value = [];
  customerBalance.value = 0;
  service_charge.value = 0;
  fpsOptions.value = [];
  totalAmountDue.value = 0;
  remainBalance.value = 0;
  modalData.value.refundamount = null;
};

const resetSelections2 = () => {

  selectedOrderType.value = '2';
  selectedFPSOptions.value = [];
  fpsOptions.value = [];
  totalAmountDue.value = 0;
  remainBalance.value = 0;
  modalData.value.refundamount = null;
};
const handleChangerefundamount = async (value) => {
console.log(value)
    return value;
  }

const handleChange = async (value) => {
  // Check if the value is null before proceeding
  if (value === null) {
    resetSelections();
    // console.log('Selected Option is null. Skipping function.');
    return;
  }



  // console.log('Selected Option:', value);

  try {
    const response = await axios.get(`${baseURL}/admin/getcustomerbalance/${value.id}`, {
      headers: {
        'Authorization': token,
      },
    });

    // Check if response.data is not null before updating customerBalance
    if (response.data && response.data.length > 0) {
      customerBalance.value = response.data[0].amount;
      // console.log("response amount", response.data[0].amount);
      resetSelections2();
      handleOrderTypeChange();
    } else {
      customerBalance.value = null;
      console.error('Error: Empty or null response from the server');
      resetSelections2();
      handleOrderTypeChange();
    }
  } catch (error) {
    console.error('Error fetching customers:', error);
  }

  // You can perform additional actions or update other variables as needed
};

const handleOrderTypeChange = async (value) => {

  if (selectedOrderType.value == '3'){
    try {
      console.log("FPS")
      // console.log("selectedOption.value",selectedOption.value.id)
      const response = await axios.get(`${baseURL}/admin/getallfpssid/${selectedOption.value.id}`, {
        headers: {
          'Authorization': token,
        },
      });
      // console.log("response getallfps", response.data);
      fpsOptions.value = response.data;

      // console.log("fpsOptions", fpsOptions.value);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  }
  else if (selectedOrderType.value == '2'){
    try {
      console.log("Invoice")
      // console.log("selectedOption.value",selectedOption.value.id)
      const response = await axios.get(`${baseURL}/admin/getallInvoicesid/${selectedOption.value.id}`, {
        headers: {
          'Authorization': token,
        },
      });
      // console.log("response getallfps", response.data);
      fpsOptions.value = response.data;

      // console.log("fpsOptions", fpsOptions.value);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  // You can perform additional actions or update other variables as needed
};
  // You can perform additional actions or update other variables as needed
};



const handleMultiselectChange = (value) => {
  // Update selectedFPSAmounts with the amounts and invoiceNos for the selected options
  selectedFPSAmounts.value = value.map(option => ({
    id: option.id,
    invoiceNo: option.invoiceNo,
    amountDue: option.currency_amount,
    service_charge_amount : option.service_charge_amount
  }));

  // Convert the amountDue values to numbers before calculating the sum
  const numericAmounts = selectedFPSAmounts.value.map(entry => parseFloat(entry.amountDue));
  const idselect = selectedFPSAmounts.value.map(entry => entry.id);
  const servicecharge = selectedFPSAmounts.value.map(entry => parseFloat(entry.service_charge_amount));
  console.log(servicecharge)
  console.log(numericAmounts)
  console.log(idselect)
  // Calculate the sum of numeric amountDue values
  let totalAmountDueValue = 0;
  for (const amount of numericAmounts) {
    totalAmountDueValue += amount;
  }
  for (const amounts of servicecharge) {
    totalAmountDueValue += amounts;
    console.log(servicecharge)
  }
  
  totalAmountDue.value = totalAmountDueValue;

  // Calculate remaining balance
  // remainBalance.value = customerBalance.value - totalAmountDue.value;
  remainBalance.value = (customerBalance.value - totalAmountDue.value);
  //console.log(remainBalance.value)
  // console.log('Selected Multiselect Data:', selectedFPSAmounts.value);
  // console.log('Total Amount Due:', totalAmountDue.value);
  // console.log('Remaining Balance:', remainBalance.value);

  // Now you can use selectedFPSAmounts.value in your API calls
};

const getCustomer = async () => {
  try {
    let currentPage = 1;
    let totalPages = 1;
    let allOptions = []; // เพิ่มตัวแปรเก็บข้อมูลทั้งหมด

    do {
      const response = await axios.get(`https://api.logizall.com/api/customers?pagination%5Bpage%5D=${currentPage}&pagination%5BpageSize%5D=100`);
      //console.log("response", response.data.meta.pagination.pageCount);

      totalPages = response.data.meta.pagination.pageCount;

      const options = response.data.data
          .filter(option => option.attributes.status === "bronze") // Filter out only active data
          .map(option => ({
            id: option.id,
            company_name_en: option.attributes.companyNameEn
          }));

      allOptions = allOptions.concat(options); // เพิ่ม options เข้าไปใน allOptions 

      currentPage++;

    } while (currentPage <= totalPages);

    filteredOptions.value = allOptions; // ใส่ข้อมูลทั้งหมดลงใน filteredOptions.value
    //console.log(filteredOptions.value)
  } catch (error) {
    console.error('Error fetching balance types:', error);
  }
};

const submitAccountingData = async () => {
  try {
    if(selectedOrderType.value == '2' || selectedOrderType.value == '3'){
      if (customerBalance.value < totalAmountDue.value) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to submit balance is insufficient. Please try again.',
      });
    } else {

      if (!selectedOption.value || !selectedOrderType.value || selectedFPSAmounts.value.length === 0) {
        console.error('Incomplete data. Cannot submit to the API.');
        return;
      }

      const accountingData = {
        mid: selectedOption.value.id,
        company_name: selectedOption.value.company_name_en,
        orderType: selectedOrderType.value,
        fpsData: selectedFPSAmounts.value,
        totalAmountDue: totalAmountDue.value,
        amount: customerBalance.value,
        // Add other fields as needed
      };

      // Make an API call to submit data
      const response = await axios.post(`${baseURL}/admin/submitaccounting`, accountingData, {
        headers: {
          'Authorization': token,
        },
      });

      // Display SweetAlert notification on success
      await Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Accounting data submitted successfully!',
      });

      // Close the modal after successful submission
      closeModal(); // Assuming you have a function named closeModal to close the modal

      // Reload the window after closing the modal
      window.location.reload(true);

      // Reset the form or perform any other actions after successful submission
      resetSelections();
    }
    }
    else if (selectedOrderType.value == '0' ){
      if((customerBalance.value - modalData.value.refundamount) > 0){
        const accountingData = {
        mid: selectedOption.value.id,
        company_name: selectedOption.value.company_name_en,
        orderType: selectedOrderType.value,
        refundamount: modalData.value.refundamount

        // Add other fields as needed
      };
      try {
    const response = await axios.post(`${baseURL}/admin/addrefund`, accountingData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    if(response.status == 201){
      Swal.fire({
      icon: 'success',
      title: 'Success',
      text: response.data.message,
    });
    
    closeModal(); // Assuming you have a function named closeModal to close the modal
    window.location.href = '?#/refund';
      resetSelections();
    }
   
  } catch (error) {
    console.error('Error adding :', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to add . Please try again.',
    });
  }
      console.log(accountingData)
      } else {
        Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to submit accounting data. Please try again.',
    });
      }
      
    }
    // Validate that the required data is present
    // console.log(customerBalance.value , totalAmountDue.value)

  } catch (error) {
    console.error('Error submitting accounting data:', error);
    // Handle errors as needed
    // Display SweetAlert error notification if desired
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to submit accounting data. Please try again.',
    });
  }
};



onMounted(() => {
  getCustomer();
});

const closeModal = () => {
  emits('closeModal');
};
</script>





<style scoped>
.update-status-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  /* Ensure modal is on top of the overlay */
  width: 400px;
  /* Adjust the width as needed */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the transparency as needed */
  z-index: 1000;
  /* Set a lower z-index to be below the modal */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1002;
  /* Ensure modal content is on top of the overlay */
}
</style>
