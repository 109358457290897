<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Welcome Section</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="updateWelcometo">
                <div class="form-group">
                  <label for="welcomeTitle">Welcome Title:</label>
                  <input v-model="updatedData.welcome_title" id="welcomeTitle" type="text" class="form-control bordered-input" />
                </div>

                <div class="form-group">
                  <label for="mainTitle">Main Title:</label>
                  <input v-model="updatedData.main_title" id="mainTitle" type="text" class="form-control bordered-input" />
                </div>
                <div class="form-group">
                  <label for="mainTitle">Main content:</label>
                  <input v-model="updatedData.main_content" id="mainTitle" type="text" class="form-control bordered-input" />
                </div>
                <div class="form-group">
                  <label for="mainTitle">Main Sub Title:</label>
                  <input v-model="updatedData.main_sub_title" id="mainTitle" type="text" class="form-control bordered-input" />
                </div>
                <div class="form-group">
                  <label for="mainTitle">Sub Title:</label>
                  <textarea v-model="updatedData.sub_title" id="mainTitle" class="form-control bordered-input"></textarea>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="mainTitle">Phone Number:</label>
                      <input v-model="updatedData.welcometo_phone" id="mainTitle" type="text"
                        class="form-control bordered-input" />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="mainTitle">Email:</label>
                      <input v-model="updatedData.welcometo_email" id="mainTitle" type="text"
                        class="form-control bordered-input" />
                    </div>
                  </div>


                <!-- Add other form fields as needed -->
                <div class="form-group ">
                  <label for="ImageFile">Image File (Image 1280 x 720 px):</label>
                  <input type="file" @change="handleImageUpload" class="form-control bordered-input " />
                  <img v-if="coverSource" :src="coverSource" class="mt-2" style="max-width: 100%; max-height: 150px;" />
                </div>

                <div class="form-group">
                  <label for="videoFile">Video File:</label>
                  <input type="file" @change="handleFileUpload" class="form-control bordered-input" />
                </div>
                <div class="form-group">

                  <video autoplay loop muted class="max-w max-h">
                    <source v-if="videoSource" :src="videoSource" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>

                <button type="submit" class="btn btn-outline-danger">Update Data</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';

// const baseURL = "https://api2.logizall.com";
const baseURL = "https://api2.logizall.com"
const token = localStorage.getItem('token');
const welcometoData = ref({});
const videoSource = ref('')
const coverSource = ref('')
const updatedData = reactive({
  welcome_title: '',
  main_title: '',
  main_content: '',
  main_sub_title: '',
  sub_title: '',
  videoFile: null,
  imageFile: null,
  welcometo_phone: '',
  welcometo_email: '',
  // Add other form fields as needed
});

onMounted(() => {
  getWelcometoData();
});

const getWelcometoData = async () => {
  try {
    const response = await axios.get(`${baseURL}/landingpage/welcome/getwelcometo`);
    welcometoData.value = response.data[0];
    updatedData.welcome_title = welcometoData.value.welcome_title;
    updatedData.main_title = welcometoData.value.main_title;
    updatedData.main_content = welcometoData.value.main_content;
    updatedData.main_sub_title = welcometoData.value.main_sub_title;
    updatedData.sub_title = welcometoData.value.sub_title;
    updatedData.welcometo_phone = welcometoData.value.welcometo_phone;
    updatedData.welcometo_email = welcometoData.value.welcometo_email;
    // //console.log(welcometoData.value);
    videoSource.value = `${baseURL}/landingpage/welcome/video/${welcometoData.value.bg_video_url}`;
    coverSource.value  = `${baseURL}/landingpage/welcome/cover/${welcometoData.value.bg_cover}`;
    //console.log("videoSource", videoSource);
  } catch (error) {
    console.error('Error fetching Welcometo data:', error);
  }
};

const handleFileUpload = (event) => {
  // console.log(event.target.files[0]);
  updatedData.videoFile = event.target.files[0];
  // console.log(updatedData.videoFile);
};

const handleImageUpload = (event) => {
  // console.log(event.target.files[0]);
  updatedData.imageFile = event.target.files[0];
  // console.log(updatedData.imageFile);
};

const updateWelcometo = async () => {
  try {
    const formData = new FormData();
    formData.append('welcome_title', updatedData.welcome_title);
    formData.append('main_title', updatedData.main_title);
    formData.append('main_content', updatedData.main_content);
    formData.append('main_sub_title', updatedData.main_sub_title);
    formData.append('sub_title', updatedData.sub_title);
    formData.append('bg_video_url', updatedData.videoFile);
    formData.append('bg_cover', updatedData.imageFile);
    formData.append('welcometo_phone', updatedData.welcometo_phone);
    formData.append('welcometo_email', updatedData.welcometo_email);
    // Add other form fields as needed
    //console.log("formData", formData);
    const response = await axios.put(`${baseURL}/landingpage/welcome/updateWelcometo`, formData, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    //console.log("res", response);
    // Check if the update was successful
    if (response.data.message == "Data updated successfully") {
      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Update Successful!',
        text: response.data.message,
      });

      // Refresh data after successful update
      window.location.reload()
    } else {
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: response.data.message,
      });
    }
  } catch (error) {
    console.error('Error updating Welcometo data:', error);

    // Show error alert
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while updating Welcometo data.',
    });
  }
};

</script>


<style scoped>
/* Add additional styles as needed */

.bordered-input {
    border: 1px solid #b6b6b6;
  }
.card {
  margin-top: 20px;
}

.max-w {
  max-width: 450px;
}

.max-h {
  max-height: 450px;
}
</style>
