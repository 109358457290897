<template>
  <div class="row">
    <div class="col-md-12">
      <Card :title="table1.title" :subTitle="table1.subTitle">
        <div slot="raw-content" class="table-responsive">
         
          <div class="mb-3 d-flex justify-content-start ">
           
                
              </div>
          <div class="mb-3 d-flex justify-content-end">
            
            <form class="form-inline">
             
              <div class="form-group mr-3">
                <v-select
                v-model="selectedOption"
                :options="filteredOptions"
                label="company_name_en"
                placeholder="Select account"
                @input="handleChange"
                style="width: 300px; "
              ></v-select>
                </div>
                <div class="form-group mr-3">
                  <select
                    v-model="selectedFilterType"
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 100px; height: 38px;"
                  >
                    <option value="1">IN/OUT</option>
                    <option value="IN">IN</option>
                    <option value="OUT">OUT</option>
                    <!-- Add more options as needed -->
                  </select>
                </div>

                <div class="form-group mr-3">
                  <select
                    v-model="selectedFilter"
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;"
                  >
                    <option value="selected_month">Selected Month</option>
                    <option value="selected_year">Selected Year</option>
                    <option value="selected_day">Selected Day</option>
                    <!-- Add more options as needed -->
                  </select>
                </div>

                  <div class="form-group mr-3">
                    <div v-if="selectedFilter === 'selected_day'">
                      <input
                        type="date"
                        v-model="searchQuery"
                        placeholder="Select Date..."
                        class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                        style="width: 200px; height: 38px;" 
                      />
                    </div>

                <div v-else-if="selectedFilter === 'selected_month'">
                  <input
                    type="month"
                    v-model="searchQuery"
                    placeholder="Select Month..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" 
                  />
                </div>
                <div v-else-if="selectedFilter === 'selected_year'">
                  <input
                    type="number"
                    v-model="searchQuery"
                    placeholder="Select year..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" 
                  />
                </div>
              </div>

              <button
                @click="search(selectedFilter, searchQuery,selectedFilterType)"
                class="btn btn-outline-danger mr-2 "
              >
                <i class="ti-search"></i> Search
              </button>
              <!-- <button
                @click="getDataAndGenerateAccountingPDF"
                class="btn btn-outline-default mr-2 "
              >
                <i class="ti-printer"></i> Print Report
              </button> -->
              <button @click="reload()" class="btn btn-outline-info mr-2 ">
                  <i class="ti-reload"></i> 
                </button>
            </form>
          </div>
          <table class="table table-bordered">
            <thead class="bg-light  bold-text" >
              <tr>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">#</th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold;">Company</th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold;">REF</th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold;">Date</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Type</th>
                <th class="py-2 pl-6 " style="width: 20%; font-weight: bold;">Details</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Currency</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Balance</th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold;">Amount</th>
              </tr>
            </thead>
            <tbody>
          <tr v-for="(transaction, index) in accountingTransactionList" :key="transaction.id">
            <td>{{ index + 1 }}</td>
            <td class="text-left">{{ transaction.company_name }}</td>
            <td class="text-left">{{ transaction.referent_number }}</td>
            <td class="text-left">{{ formatDate(transaction.dateCreate) }}</td>
            <td :style="{ color: transaction.account_type === 'IN' ? 'green' : 'red' }">{{ transaction.account_type }}</td>
            <td class="text-left">{{ transaction.detail }}</td>
            <td class="text-center">{{ transaction.currency }}</td>
            <td class="text-right" :style="{ color: transaction.account_type === 'IN' ? 'green' : 'red' }">{{ parseFloat(transaction.debit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) >= parseFloat(transaction.credit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) ? ("+" +parseFloat(transaction.debit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})) : "-" + parseFloat(transaction.credit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})  }}</td>
            <td class="text-right">{{ parseFloat(transaction.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>

          </tr>
        </tbody>
          </table>
          <div class="main-container"> 
            <!-- ... (your existing template code) ... -->
            <div class="pagination-container">
              <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
                Prev
              </div>
              <div v-else class="btn btn-secondary" disabled>
                Prev
              </div>
              <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
              <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
                Next
              </div>
              <div v-else @click="nextPage()" class="btn btn-outline-danger">
                Next
              </div>
            </div>
          </div>
     
        </div>
      </Card>
      
        </div>
      
  </div>

</template>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { Card, PaperTable } from "@/components";
import Swal from 'sweetalert2';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
// import AccountingModal from '@/components/Modal/AccountingModal.vue';

const pdfContent = ref('');
const summaryDebit = ref('');
const summaryCredit = ref('');
const summaryAmount= ref('');
  const fetchedData = ref([]);
  const tableHeaders = ref([ 'No', 'Company Name', 'REF', 'Detail', 'Debit', 'Credit', 'Account type', 'Amount', 'DateCreate']);
const selectedFilter = ref('selected_month');
const selectedFilterType = ref('1');
const baseURL = "https://api2.logizall.com";
const accountingTransactionList = ref([]);
const Quertsearch = ref()
let searchQuery
const token = localStorage.getItem('token');
const table1 = ref({
title: "Transaction",
subTitle: "Transaction",

});

const currentPage = ref(1);
const pageSize = 10; // Set your desired page size
const totalPages = ref(1);
const totalRecords = ref(0);
const isSearch = ref(false);

const isUpdateStatusModalOpen = ref(false);

// Method to open the update status modal
const openUpdateStatusModal = () => {
  isUpdateStatusModalOpen.value = true;
};

// Method to close the update status modal
const closeUpdateStatusModal = () => {
  isUpdateStatusModalOpen.value = false;
};

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Asia/Bangkok',
  };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

const getTransaction = async () => {
try {

  const response = await axios.get(`${baseURL}/admin/getTransaction`, {
    params: {
      page: currentPage.value,
      pageSize: pageSize,
    },
    headers: {
      Authorization: token,
    },
  });
  //console.log("response",response)
  accountingTransactionList.value = response.data.data || [];
  totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
  totalRecords.value = response.data.totalRecords || [];
} catch (error) {
  console.error('Error fetching My Wallet data:', error);
  // Handle the error appropriately, e.g., show an error message to the user.
}
};
const prevPage = () => {
prevOrNextPage('prev');
};

const nextPage = () => {
prevOrNextPage('next');
};

const prevOrNextPage = (action) => {
if (action == 'prev') {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
} else if (action == 'next') {
  
  //console.log("pageSize",pageSize)
  //console.log("totalRecords",totalRecords.value)
  if (currentPage.value * pageSize <= totalRecords.value) {
    currentPage.value++;
  }
  //console.log("currentPage",currentPage.value)
}


// Check if it's a search or regular page navigation
if (isSearch.value) {
  //console.log("isSearch",isSearch.value)
  search(selectedFilter.value, Quertsearch.value,selectedFilterType.value);
} else {
  getTransaction();
}
};

const search = async (dateFilter, searchQuery ,selectedtype) => {
Quertsearch.value = searchQuery;
try {
  // Check if selectedFilter is null
  //console.log("if")
  if(dateFilter == "selected_month" && searchQuery == null && selectedtype == 1 && Quertsearch.value == null && selectedOption.value == null  ){
    getTransaction();
  }
  else{

  
  if (selectedOption.value === null) {
    // Perform the search using the original API
    const response = await axios.get(`${baseURL}/admin/getTransactionbysearch`, {
      params: { dateFilter, selectedDate: searchQuery,selectedFilterType:selectedtype, page: currentPage.value, pageSize: pageSize },
      headers: {
        Authorization: token,
      },
    });

    // Destructure the response data
    const { data, total } = response.data;
    // console.log(response.data)
    // Update values
    accountingTransactionList.value = data || [];
    totalRecords.value = total || [];

    // Set the search flag to true
    isSearch.value = true;
    totalPages.value = Math.ceil(response.data.total / pageSize);


  } else {
    // Perform the search using the new API
    //console.log("selectedOption.value",selectedOption.value.id)
    const response = await axios.get(`${baseURL}/admin/getTransactionbysearchmid/${selectedOption.value.id}`, {
      params: { dateFilter, selectedDate: searchQuery,selectedFilterType:selectedtype, page: currentPage.value, pageSize: pageSize },
      headers: {
        Authorization: token,
      },
    });

    // Destructure the response data
    const { data, total } = response.data;

    // Update values
    accountingTransactionList.value = data || [];
    totalRecords.value = total || [];

    // Set the search flag to true
    isSearch.value = true;
    totalPages.value = Math.ceil(response.data.total / pageSize);

    // Update the current page to 1, as the search results start from the first page
  }
}
} catch (error) {
  console.error('Error fetching accounting data:', error);
  // Handle the error appropriately, e.g., show an error message to the user.
  Swal.fire({
    icon: 'error',
    title: 'Error',
    text: 'An error occurred while fetching data.',
  });
}
};


const selectedOption = ref(null);
const filteredOptions = ref([]);

const handleChange = (value) => {
// Handle the selected value here
//console.log('Selected Option:', value);
// You can perform additional actions or update other variables as needed
};
const fetchTransfer = async () => {
  try {
    let currentPage = 1;
    let totalPages = 1;
    let allOptions = []; // เพิ่มตัวแปรเก็บข้อมูลทั้งหมด

    do {
      const response = await axios.get(`https://api.logizall.com/api/customers?pagination%5Bpage%5D=${currentPage}&pagination%5BpageSize%5D=100`);
      //console.log("response", response.data.meta.pagination.pageCount);

      totalPages = response.data.meta.pagination.pageCount;

      const options = response.data.data
        .filter(option => option.attributes.status === "bronze") // Filter out only active data
        .map(option => ({
          id: option.id,
          company_name_en: option.attributes.companyNameEn
        }));

      allOptions = allOptions.concat(options); // เพิ่ม options เข้าไปใน allOptions

      currentPage++;

    } while (currentPage <= totalPages);

    filteredOptions.value = allOptions; // ใส่ข้อมูลทั้งหมดลงใน filteredOptions.value
    //console.log(filteredOptions.value)
  } catch (error) {
    console.error('Error fetching balance types:', error);
  }
};
const reload = async () => {
  fetchTransfer();
  getTransaction();
};

onMounted(() => {
  fetchTransfer();
  getTransaction();
});

  


  
  



</script>


<style>



.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

  .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.search-container {
  display: flex;
  /* align-items: right; */
  margin-left: auto;
}


.pagination-container {
display: flex;
justify-content: flex-end; /* Align items at the start of the container */
align-items: stretch; /* Stretch items vertically */
text-align: center; /* Center text within each item */
margin-top: 10px;
margin-bottom: 10px;
margin-right: 10px;
}

.smaller-modal {
  max-width: 50%; /* Adjust the maximum width as needed */
}

.bea-input {
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 14px; /* Adjust the font size as needed */
color: #333; /* Text color */

width: 200px;
}

.bea-select {
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 14px; /* Adjust the font size as needed */
color: #333; /* Text color */
width: 200px;
}

.bold-text {
font-weight: bold;
}

</style>

