<template>
  <div class="col-md-12">
    <Card>
      <div class="d-flex justify-content-end mb-3">
        <button @click="openAddTestimonialModal" class="btn btn-outline-primary">
          <i class="ti-plus"></i> Add Testimonial
        </button>
      </div>

      <div slot="raw-content" class="table-responsive">
        <table class="table table-bordered">
          <thead class="bg-light  bold-text">
            <tr>
              <th style="font-weight: bold;">#</th>
              <th style="font-weight: bold;">Name</th>
              <th style="font-weight: bold;">Company Name</th>
              <th style="font-weight: bold;">Description</th>
              <th style="font-weight: bold;">Profile Image</th>
              <th style="font-weight: bold;">Company Image</th>
              <th style="font-weight: bold;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(testimonial, index) in testimonials" :key="testimonial.id">
              <td class="py-2 pl-6 md-2">{{ index + 1 }}</td>
              <td class="py-2 pl-6 md-2">{{ testimonial.members_name }}</td>
              <td class="py-2 pl-6 md-2">{{ testimonial.members_company_name }}</td>
              <td class="py-2 pl-6 md-2">{{ testimonial.members_description }}</td>
              <td style="text-align: center; width: 15%;">
                <button v-if="testimonial.members_profile_image"
                  @click="openFileModal(getImageUrl(testimonial.members_profile_image))" class="btn btn-outline-info">
                  <i class="ti-eye"></i> View File
                </button>
                <span v-else class="text-muted">No File</span>
              </td>
              <td style="text-align: center; width: 15%;">
                <button v-if="testimonial.members_company_image"
                  @click="openFileModal(getImageUrl(testimonial.members_company_image))" class="btn btn-outline-info">
                  <i class="ti-eye"></i> View File
                </button>
                <span v-else class="text-muted">No File</span>
              </td>
              <td style="text-align: center;">
                <button @click="openEditTestimonialModal(index)" class="btn btn-outline-primary mr-2">
                  <i class="ti-pencil"></i>
               
                </button>
                <button @click="deleteItem(index)" class="btn btn-outline-danger mr-2">
                  <i class="ti-trash"></i>
                
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="main-container">
          <div class="pagination-container">
            <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
              Prev
            </div>
            <div v-else class="btn btn-secondary" disabled>
              Prev
            </div>
            <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
            <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
              Next
            </div>
            <div v-else @click="nextPage()" class="btn btn-outline-danger">
              Next
            </div>
          </div>
        </div>
      </div>
    </Card>

    <!-- Add Testimonial Modal -->
    <div v-if="isAddTestimonialModalOpen" class="modal-overlay" @click="closeAddTestimonialModal"></div>
    <Modal v-if="isAddTestimonialModalOpen" @close="closeAddTestimonialModal">
      <h3>Add New Testimonial</h3>
      <form @submit.prevent="submitAddTestimonialForm">
        <div class="form-group ">
          <label for="addTestimonialName">Name:</label>
          <input v-model="addTestimonialFormData.members_name" id="addTestimonialName" type="text" class="form-control bordered-input p-2" />
        </div>
        <div class="form-group ">
          <label for="addCompanyName">Company Name:</label>
          <input v-model="addTestimonialFormData.members_company_name" id="addCompanyName" type="text"
            class="form-control bordered-input p-2" />
        </div>
        <div class="form-group ">
          <label for="addTestimonialDescription">Description:</label>
          <textarea v-model="addTestimonialFormData.members_description" id="addTestimonialDescription"
            class="form-control bordered-input p-2"></textarea>
        </div>
        <div class="form-group ">
          <label for="addProfileImage">Profile Image (Image 350 x 350 px):</label>
          <input type="file" @change="handleAddProfileImageUpload" class="form-control bordered-input p-2" />
          <div v-if="addProfileImagePreview" class="image-preview pt-2">
            <img :src="addProfileImagePreview" alt="Profile Image" style="max-width: 256px; max-height: 256px;" />
          </div>
        </div>
        <div class="form-group ">
          <label for="addCompanyImage">Company Image (Image 128 x 128 px):</label>
          <input type="file" @change="handleAddCompanyImageUpload" class="form-control bordered-input p-2" />
          <div v-if="addCompanyImagePreview" class="image-preview pt-2">
            <img :src="addCompanyImagePreview" alt="Company Image" style="max-width: 256px; max-height: 256px;" />
          </div>
        </div>
        <button type="submit" class="btn btn-outline-info">Add Testimonial</button>
      </form>
    </Modal>

    <!-- Edit Testimonial Modal -->
    <div v-if="isEditTestimonialModalOpen" class="modal-overlay" @click="closeEditTestimonialModal"></div>
    <Modal v-if="isEditTestimonialModalOpen" @close="closeEditTestimonialModal">
      <h3>Edit Testimonial</h3>
      <form @submit.prevent="submitEditTestimonialForm">
        <div class="form-group ">
          <label for="editTestimonialName ">Name:</label>
          <input v-model="editTestimonialFormData.members_name" id="editTestimonialName" type="text"
            class="form-control bordered-input p-2" />
        </div>
        <div class="form-group ">
          <label for="editCompanyName">Company Name:</label>
          <input v-model="editTestimonialFormData.members_company_name" id="editCompanyName" type="text"
            class="form-control bordered-input p-2" />
        </div>
        <div class="form-group ">
          <label for="editTestimonialDescription">Description:</label>
          <textarea v-model="editTestimonialFormData.members_description" id="editTestimonialDescription"
            class="form-control bordered-input p-2"></textarea>
        </div>
        <div class="form-group ">
          <label for="editProfileImage">Profile Image:</label>
          <input type="file" @change="handleEditProfileImageUpload" class="form-control bordered-input p-2" />
          <div v-if="editProfileImagePreview || existingProfileImage" class="image-preview pt-3">
            <img :src="editProfileImagePreview || existingProfileImage" alt="Profile Image"
              style="max-width: 128px; max-height: 128px;" />
          </div>
        </div>
        <div class="form-group ">
          <label for="editCompanyImage">Company Image:</label>
          <input type="file" @change="handleEditCompanyImageUpload" class="form-control bordered-input p-2 " />
          <div v-if="editCompanyImagePreview || existingCompanyImage" class="image-preview pt-3">
            <img :src="editCompanyImagePreview || existingCompanyImage" alt="Company Image"
              style="max-width: 128px; max-height: 128px;" />
          </div>
        </div>
        <button type="submit" class="btn btn-outline-danger">Save Changes</button>
      </form>
    </Modal>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Card from "@/components/Cards/Card";
import Modal from '@/components/Modal/MembershipsIcon.vue'; // Import your Modal component
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert library

// ref variables for data and modal state
const testimonials = ref([]); // Make sure to fetch this data from your API
const isAddTestimonialModalOpen = ref(false);
const isEditTestimonialModalOpen = ref(false);
const currentPage = ref(1);
const pageSize = 10; // Set your desired page size
const totalPages = ref(1);
const totalRecords = ref(0);
const token = localStorage.getItem('token');


// Form data for adding new testimonial
const addTestimonialFormData = ref({
  members_name: '',
  members_company_name: '',
  members_description: '',
  addProfileImageFile: null,
  addCompanyImageFile: null,
});

// Form data for editing testimonial
const editTestimonialFormData = ref({
  members_name: '',
  members_company_name: '',
  members_description: '',
  editProfileImageFile: null,
  editCompanyImageFile: null,
});

// Existing image previews for editing
const existingProfileImage = ref('');
const existingCompanyImage = ref('');

// Profile image previews for adding/editing
const addProfileImagePreview = ref('');
const editProfileImagePreview = ref('');
const addCompanyImagePreview = ref('');
const editCompanyImagePreview = ref('');

// Your base API URL
const baseURL = 'https://api2.logizall.com'; // Replace with your actual API URL

// Fetch testimonials data from the API (you need to implement this)
const fetchTestimonials = async () => {
  try {
    const response = await axios.get(`${baseURL}/landingpage/testimonials/gettestimonialspage`, {
      params: {
        page: currentPage.value,
        pageSize: pageSize,
      },
    });
    //console.log("response", response)
    totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
    totalRecords.value = response.data.totalRecords || [];
    testimonials.value = response.data.data || [];
  } catch (error) {
    console.error('Error fetching testimonials data:', error);
  }
};

// Fetch testimonials data on component mount
onMounted(() => {
  fetchTestimonials();
});

const openFileModal = (fileUrl) => {
  //console.log(fileUrl);
  if (fileUrl) {
    // Check if the file is an image (JPEG, PNG, GIF)
    const isImage = /\.(jpe?g|png|gif)$/i.test(fileUrl);

    if (isImage) {
      // Display the image in a smaller SweetAlert modal
      Swal.fire({
        imageUrl: fileUrl,
        imageAlt: '',
        showCloseButton: true,
        customClass: {
          popup: 'smaller-modal', // Add a custom CSS class for the modal
        },
      });
    } else {
      // Open the PDF in a new window or tab
      window.open(fileUrl, '_blank');
    }
  } else {
    // Handle the case where there is no file
    Swal.fire({
      icon: 'info',
      title: 'No File',
      text: 'There is no file available for this transaction.',
    });
  }
};


const getImageUrl = (filename) => {
  //console.log("landingpage/testimonials/images/upload/", filename)
  // Replace 'localhost:4000' with your actual server address
  return `${baseURL}/landingpage/testimonials/images/upload/${filename}`;
};

// Method to open the add testimonial modal
const openAddTestimonialModal = () => {
  isAddTestimonialModalOpen.value = true;
};

// Method to close the add testimonial modal
const closeAddTestimonialModal = () => {
  // Reset the form data
  addTestimonialFormData.members_name = '';
  addTestimonialFormData.members_company_name = '';
  addTestimonialFormData.members_description = '';
  addTestimonialFormData.addProfileImageFile = null;
  addTestimonialFormData.addCompanyImageFile = null;
  // Close the modal
  isAddTestimonialModalOpen.value = false;
};

// Method to open the edit testimonial modal
const openEditTestimonialModal = (index) => {
  // Populate the form data for editing
  const testimonial = testimonials.value[index];
  editTestimonialFormData.value = {
    id: testimonial.id,
    members_name: testimonial.members_name,
    members_company_name: testimonial.members_company_name,
    members_description: testimonial.members_description,
  };
  existingProfileImage.value = getImageUrl(testimonial.members_profile_image);
  existingCompanyImage.value = getImageUrl(testimonial.members_company_image);;
  // Open the modal
  isEditTestimonialModalOpen.value = true;
};

// Method to close the edit testimonial modal
const closeEditTestimonialModal = () => {
  // Reset the form data
  editTestimonialFormData.members_name = '';
  editTestimonialFormData.members_company_name = '';
  editTestimonialFormData.members_description = '';
  editTestimonialFormData.editProfileImageFile = null;
  editTestimonialFormData.editCompanyImageFile = null;
  // Reset existing image previews
  existingProfileImage.value = '';
  existingCompanyImage.value = '';
  // Close the modal
  isEditTestimonialModalOpen.value = false;
};

// Method to handle profile image upload for adding testimonial
const handleAddProfileImageUpload = (event) => {
  const file = event.target.files[0];
  addTestimonialFormData.addProfileImageFile = file;
  addProfileImagePreview.value = URL.createObjectURL(file);
};

// Method to handle company image upload for adding testimonial
const handleAddCompanyImageUpload = (event) => {
  const file = event.target.files[0];
  addTestimonialFormData.addCompanyImageFile = file;
  addCompanyImagePreview.value = URL.createObjectURL(file);
};

// Method to submit the form for adding a new testimonial
const submitAddTestimonialForm = async () => {
  try {
    // Create a FormData object
    const formData = new FormData();
    formData.append('members_name', addTestimonialFormData.value.members_name);
    formData.append('members_company_name', addTestimonialFormData.value.members_company_name);
    formData.append('members_description', addTestimonialFormData.value.members_description);
    formData.append('members_profile_image', addTestimonialFormData.addProfileImageFile);
    formData.append('members_company_image', addTestimonialFormData.addCompanyImageFile);

    // Send a POST request to your API to add the testimonial
    const response = await axios.post(`${baseURL}/landingpage/testimonials/addtestimonialsdata`, formData, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    // Display a success message
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: response.data.message,
    });

    // Fetch updated testimonials data
    fetchTestimonials();

    // Close the add testimonial modal
    closeAddTestimonialModal();
  } catch (error) {
    console.error('Error adding testimonial:', error);
    // Display an error message
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to add testimonial. Please try again.',
    });
  }
};

const handleEditProfileImageUpload = (event) => {
  const file = event.target.files[0];
  editTestimonialFormData.editProfileImageFile = file;
  editProfileImagePreview.value = URL.createObjectURL(file);
};

// Method to handle company image upload for editing testimonial
const handleEditCompanyImageUpload = (event) => {
  const file = event.target.files[0];
  editTestimonialFormData.editCompanyImageFile = file;
  editCompanyImagePreview.value = URL.createObjectURL(file);
};

// Method to submit the form for editing an existing testimonial
const submitEditTestimonialForm = async () => {
  try {
    // Create a FormData object
    const formData = new FormData();
    formData.append('members_name', editTestimonialFormData.value.members_name);
    formData.append('members_company_name', editTestimonialFormData.value.members_company_name);
    formData.append('members_description', editTestimonialFormData.value.members_description);
    formData.append('members_profile_image', editTestimonialFormData.editProfileImageFile);
    formData.append('members_company_image', editTestimonialFormData.editCompanyImageFile);

    // Send a POST request to your API to edit the testimonial
    const response = await axios.put(`${baseURL}/landingpage/testimonials/updatetestimonialsData/${editTestimonialFormData.value.id}`, formData, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    // Display a success message
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: response.data.message,
    });

    // Fetch updated testimonials data
    fetchTestimonials();

    // Close the edit testimonial modal
    closeEditTestimonialModal();
  } catch (error) {
    console.error('Error editing testimonial:', error);
    // Display an error message
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to edit testimonial. Please try again.',
    });
  }
};

const deleteItem = async (index) => {
  const itemId = testimonials.value[index].id;

  try {
    const response = await axios.delete(`${baseURL}/landingpage/testimonials/remove/${itemId}`,{
      headers: {
        Authorization: `${token}`,
      },
    });

    if (response.status === 200) {
      // Delete the item from the local array
      testimonials.value.splice(index, 1);
      //console.log('Item deleted successfully');
    } else {
      console.error('Error deleting item:', response.data.error);
    }
  } catch (error) {
    console.error('Error deleting item:', error);
  }
};

const prevPage = () => {
  prevOrNextPage('prev');
};

const nextPage = () => {
  prevOrNextPage('next');
};

const prevOrNextPage = (action) => {
  if (action == 'prev') {
    if (currentPage.value > 1) {
      currentPage.value--;
      fetchTestimonials();
    }
  } else if (action == 'next') {

    //console.log("pageSize", pageSize)
    //console.log("totalRecords", totalRecords.value)
    if (currentPage.value * pageSize <= totalRecords.value) {
      currentPage.value++;
    }
    //console.log("currentPage", currentPage.value)
    fetchTestimonials();
  }
};
// Your existing methods for editing testimonials and handling image uploads
// ...

</script>

<style scoped>
.bordered-input {
    border: 1px solid #a1a1a1;
  }
</style>