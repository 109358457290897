<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Our Network Section</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="updateWelcometo">
                <div class="form-group pl-2 pr-2">
                  <label for="welcomeTitle">Network Title:</label>
                  <input v-model="updatedData.network_title" id="welcomeTitle" type="text"
                    class="form-control bordered-input " />
                </div>

                <div class="form-group pl-2 pr-2">
                  <label for="mainTitle">Main Title:</label>
                  <input v-model="updatedData.main_title" id="mainTitle" type="text"
                    class="form-control bordered-input " />
                </div>
                <div class="form-group pl-2 pr-2">
                  <label for="mainTitle">Main Sub Title:</label>
                  <input v-model="updatedData.main_sub_title" id="mainTitle" type="text"
                    class="form-control bordered-input " />
                </div>

                <div class="form-group pl-2 pr-2">
                  <label for="mainTitle">Youtube Url:</label>
                  <input v-model="updatedData.youtube_url" id="mainTitle" type="text"
                    class="form-control bordered-input " />
                </div>
                <div class="form-group pl-2 pr-2">
                  <label for="ImageFile">Image File (Image 1440 x 775 px):</label>
                  <input type="file" @change="handleFileUpload" class="form-control" />
                  <img v-if="imageSource" :src="imageSource" class="mt-2" style="max-width: 100%; max-height: 150px;" />
                </div>


                
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle1">Value Bar Title 1:</label>
                      <input v-model="updatedData.value_bar_title_1" id="valueBarTitle1" type="text"
                        class="form-control bordered-input " />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar1">Value Bar 1:</label>
                      <input v-model="updatedData.value_bar_1" id="valueBar1" type="text"
                        class="form-control bordered-input bordered-input " />
                    </div>
                  </div>

                  <!-- Pair 2 -->
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle2">Value Bar Title 2:</label>
                      <input v-model="updatedData.value_bar_title_2" id="valueBarTitle2" type="text"
                        class="form-control bordered-input" />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar2">Value Bar 2:</label>
                      <input v-model="updatedData.value_bar_2" id="valueBar2" type="text"
                        class="form-control bordered-input" />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle3">Value Bar Title 3:</label>
                      <input v-model="updatedData.value_bar_title_3" id="valueBarTitle3" type="text"
                        class="form-control bordered-input" />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar3">Value Bar 3:</label>
                      <input v-model="updatedData.value_bar_3" id="valueBar3" type="text"
                        class="form-control bordered-input" />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle4">Value Bar Title 4:</label>
                      <input v-model="updatedData.value_bar_title_4" id="valueBarTitle4" type="text"
                        class="form-control bordered-input" />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar4">Value Bar 4:</label>
                      <input v-model="updatedData.value_bar_4" id="valueBar4" type="text"
                        class="form-control bordered-input" />
                    </div>
                  </div>

                  <!-- Pair 5 -->
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle5">Value Bar Title 5:</label>
                      <input v-model="updatedData.value_bar_title_5" id="valueBarTitle5" type="text"
                        class="form-control bordered-input" />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar5">Value Bar 5:</label>
                      <input v-model="updatedData.value_bar_5" id="valueBar5" type="text"
                        class="form-control bordered-input" />
                    </div>
                  </div>

                  <!-- Pair 6 -->
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle6">Value Bar Title 6:</label>
                      <input v-model="updatedData.value_bar_title_6" id="valueBarTitle6" type="text"
                        class="form-control bordered-input" />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar6">Value Bar 6:</label>
                      <input v-model="updatedData.value_bar_6" id="valueBar6" type="text"
                        class="form-control bordered-input" />
                    </div>
                  </div>

                  <!-- Pair 7 -->
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle7">Value Bar Title 7:</label>
                      <input v-model="updatedData.value_bar_title_7" id="valueBarTitle7" type="text"
                        class="form-control bordered-input" />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar7">Value Bar 7:</label>
                      <input v-model="updatedData.value_bar_7" id="valueBar7" type="text"
                        class="form-control bordered-input" />
                    </div>
                  </div>

                  <!-- Pair 8 -->
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle8">Value Bar Title 8:</label>
                      <input v-model="updatedData.value_bar_title_8" id="valueBarTitle8" type="text"
                        class="form-control bordered-input" />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar8">Value Bar 8:</label>
                      <input v-model="updatedData.value_bar_8" id="valueBar8" type="text"
                        class="form-control bordered-input" />
                    </div>
                  </div>



                  <button type="submit" class="btn btn-outline-danger">Update Data</button>
                  
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';

const baseURL = "https://api2.logizall.com";
const token = localStorage.getItem('token');
const conferenceData = ref({});
const imageSource = ref('')
const updatedData = ref({
  network_title: '',
  main_title: '',
  main_sub_title: '',
  youtube_url: '',
  imageFile: null,
  // Add other form fields as needed
  value_bar_title_1: '',
  value_bar_1: '',
  value_bar_title_2: '',
  value_bar_2: '',
  value_bar_title_3: '',
  value_bar_3: '',
  value_bar_title_4: '',
  value_bar_4: '',
  value_bar_title_5: '',
  value_bar_5: '',
  value_bar_title_6: '',
  value_bar_6: '',
  value_bar_title_7: '',
  value_bar_7: '',
  value_bar_title_8: '',
  value_bar_8: '',
});

const getWelcometoData = async () => {
  try {
    const response = await axios.get(`${baseURL}/landingpage/network/getnetwork`);
    conferenceData.value = response.data[0];
    updatedData.value = { ...conferenceData.value }; // Assign data to updatedData
    ////console.log(conferenceData.value);
    imageSource.value = `${baseURL}/landingpage/network/image/${conferenceData.value.bg_image_url}`
  } catch (error) {
    console.error('Error fetching Welcometo data:', error);
  }
};

const handleFileUpload = (event) => {
  ////console.log(event.target.files[0]);
  updatedData.value.imageFile = event.target.files[0];
  ////console.log(updatedData.value.imageFile);
};

const updateWelcometo = async () => {
  try {
    const formData = new FormData();
    formData.append('network_title', updatedData.value.network_title);
    formData.append('main_title', updatedData.value.main_title);
    formData.append('main_sub_title', updatedData.value.main_sub_title);
    formData.append('youtube_url', updatedData.value.youtube_url);
    formData.append('bg_image_url', updatedData.value.imageFile);
    formData.append('value_bar_title_1', updatedData.value.value_bar_title_1);
    formData.append('value_bar_title_2', updatedData.value.value_bar_title_2);
    formData.append('value_bar_title_3', updatedData.value.value_bar_title_3);
    formData.append('value_bar_title_4', updatedData.value.value_bar_title_4);
    formData.append('value_bar_title_5', updatedData.value.value_bar_title_5);
    formData.append('value_bar_title_6', updatedData.value.value_bar_title_6);
    formData.append('value_bar_title_7', updatedData.value.value_bar_title_7);
    formData.append('value_bar_title_8', updatedData.value.value_bar_title_8);

    formData.append('value_bar_1', updatedData.value.value_bar_1);
    formData.append('value_bar_2', updatedData.value.value_bar_2);
    formData.append('value_bar_3', updatedData.value.value_bar_3);
    formData.append('value_bar_4', updatedData.value.value_bar_4);
    formData.append('value_bar_5', updatedData.value.value_bar_5);
    formData.append('value_bar_6', updatedData.value.value_bar_6);
    formData.append('value_bar_7', updatedData.value.value_bar_7);
    formData.append('value_bar_8', updatedData.value.value_bar_8);

    // Add other form fields as needed
    ////console.log("formData", formData);
    const response = await axios.put(`${baseURL}/landingpage/network/updatenetwork`, formData, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    ////console.log(response.data.message);

    if (response.data.message === "Data updated successfully.") {
      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Update Successful!',
        text: response.data.message,
      });

      // Refresh data after successful update
      getWelcometoData();
    } else {
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: response.data.message,
      });
    }
  } catch (error) {
    console.error('Error updating Welcometo data:', error);

    // Show error alert
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while updating Welcometo data.',
    });
  }
};

// Fetch data on component mount
onMounted(() => {
  getWelcometoData();
});
</script>


<style scoped>
/* Add additional styles as needed */
.card {
  margin-top: 20px;
}

.bordered-input {
  border: 1px solid #b6b6b6;
}
</style>
