<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Conference Section</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="updateconference">
                <div class="form-group">
                  <label for="welcomeTitle">Conference Title:</label>
                  <input v-model="updatedData.conference_title" id="welcomeTitle" type="text" class="form-control bordered-input" />
                </div>
                <div class="form-group">
                  <label for="mainTitle">Main Title:</label>
                  <input v-model="updatedData.main_title" id="mainTitle" type="text" class="form-control bordered-input" />
                </div>
                <div class="form-group">
                  <label for="mainTitle">Main Sub Title:</label>
                  <input v-model="updatedData.main_sub_title" id="mainTitle" type="text" class="form-control bordered-input" />
                </div>


                <!-- Add other form fields as needed -->

                <div class="form-group">
                  <label for="imageFile">Image File ( Image size 1800 x 600 px ):</label>
                  <input type="file" @change="handleFileUpload" class="form-control bordered-input" />
                  <!-- Image preview -->
                  <img v-if="imageSource" :src="imageSource" class="mt-2" style="max-width: 100%; max-height: 150px;" />
                </div>

                <button type="submit" class="btn btn-outline-danger">Update Data</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';
const baseURL = "https://api2.logizall.com";
const token = localStorage.getItem('token');
const conferenceData = ref({});
const updatedData = ref({
  conference_title: '',
  main_title: '',
  main_sub_title: '',
  imageFile: null,
});
const imageSource = ref('')
const getconferenceData = async () => {
  try {
    const response = await axios.get(`${baseURL}/landingpage/conference/getconference`);
    conferenceData.value = response.data[0];
    updatedData.value = { ...conferenceData.value }; // Assign data to updatedData
    //console.log(conferenceData.value);
    imageSource.value = `${baseURL}/landingpage/conference/image/${conferenceData.value.bg_image_url}`
    //console.log(imageSource.value)
  } catch (error) {
    console.error('Error fetching conference data:', error);
  }
};

const handleFileUpload = (event) => {
  //console.log(event.target.files[0]);
  updatedData.value.imageFile = event.target.files[0];
  //console.log(updatedData.value.imageFile);
};

const updateconference = async () => {
  try {
    const formData = new FormData();
    formData.append('conference_title', updatedData.value.conference_title);
    formData.append('main_title', updatedData.value.main_title);
    formData.append('main_sub_title', updatedData.value.main_sub_title);
    formData.append('bg_image_url', updatedData.value.imageFile);

    const response = await axios.put(`${baseURL}/landingpage/conference/updateconference`, formData, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.message == "Data updated successfully.") {
      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Update Successful!',
        text: response.data.message,
      });

      // Refresh data after successful update
      getconferenceData();
    } else {
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: response.data.message,
      });
    }
  } catch (error) {
    console.error('Error updating conference data:', error);

    // Show error alert
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while updating conference data.',
    });
  }
};


onMounted(() => {
  getconferenceData();
});
</script>


<style scoped>
/* Add additional styles as needed */
.card {
  margin-top: 20px;
}

.bordered-input {
    border: 1px solid #b6b6b6;
  }
</style>
