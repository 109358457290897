<template>
    <div class="col-md-12">
        <Card>

            <div class="d-flex justify-content-end mb-3">
                <button @click="openEditFreezeDateModal" class="btn btn-outline-default mx-2">
                    <i class="ti-pencil"></i> {{ FreezeDate[0].freeze_date }} Day
                </button>
                <button @click="openAddFreezeModal" class="btn btn-outline-primary mx-2 ">
                    <i class="ti-plus"></i> Add Freeze
                </button>

                
            </div>

          



            <div slot="raw-content" class="table-responsive">
                <table class="table table-bordered">
                    <thead class="bg-light bold-text">
                        <tr>
                            <th style="font-weight: bold;">#</th>
                            <th style="font-weight: bold;">Company</th>
                            <th style="font-weight: bold;">Withdraw status</th>
                            <th style="font-weight: bold;">Tranfer status</th>
                            <th style="font-weight: bold;">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(Freeze, index) in Freeze" :key="Freeze.id">
                            <td class="py-2 pl-6 md-2" style="width: 8%;">{{ index + 1 }}</td>
                            <td class="py-2 pl-6 md-2" style="width: 20%;">{{ Freeze.company_name }}</td>
                            <td class="py-2 pl-6 md-2" style="width: 20%;">{{ Freeze.withdraw_status == '1' ? 'true' :
                    'false' }}</td>
                            <td class="py-2 pl-6 md-2" style="width: 20%;">{{ Freeze.transfer_status == '1' ? 'true' :
                    'false' }}</td>


                            <!-- <td>{{ formatDate(Freeze.created_at) }}</td>
                <td>{{ Freeze.created_by }}</td> -->
                            <td style="text-align: center; width: 20%;  ">
                                <button @click="openEditFreezeModal(index)" class="btn btn-outline-primary mr-2">
                                    <i class="ti-pencil"></i>
                                    Edit
                                </button>
                                <button @click="deleteFreeze(Freeze.id)" class="btn btn-outline-danger mr-2">
                                    <i class="ti-trash"></i>
                                    Delete
                                </button>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="main-container">
                    <div class="pagination-container">
                        <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
                            Prev
                        </div>
                        <div v-else class="btn btn-secondary" disabled>
                            Prev
                        </div>
                        <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
                        <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
                            Next
                        </div>
                        <div v-else @click="nextPage()" class="btn btn-outline-danger">
                            Next
                        </div>
                    </div>
                </div>
            </div>
        </Card>

        <!-- Add Freeze Modal -->
        <div v-if="isAddFreezeModalOpen" class="modal-overlay" @click="closeAddFreezeModal"></div>
        <Modal v-if="isAddFreezeModalOpen" @close="closeAddFreezeModal">
            <h3>Add New Freeze</h3>

            <div class="form-group">
                <label for="customerAccount">Customers Accounts:</label>
                <v-select v-model="selectedOption" :options="filteredOptions" label="company_name_en"
                    placeholder="Select account" @input="handleChange" style="width: 100%;"></v-select>
            </div>
            <div class="form-group">
                <label>Freeze Options:</label>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="freezeWithdraw"
                        v-model="addFreezeFormData.withdraw_status" true-value="1" false-value="0">
                    <label class="form-check-label" for="freezeWithdraw"> Freeze Withdraw</label>
                </div>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="freezeTransfer"
                        v-model="addFreezeFormData.transfer_status" true-value="1" false-value="0">
                    <label class="form-check-label" for="freezeTransfer"> Freeze Transfer</label>
                </div>
            </div>
            <div>
                <button @click="submitAddFreezeForm" class="btn btn-outline-info mr-2 mb-3">Add Freeze</button>

                <button @click="closeAddFreezeModal" class="btn btn-outline-danger  mr-2 mb-3 ">Close</button>
            </div>
        </Modal>

        <div v-if="isEditFreezeDateModalOpen" class="modal-overlay" @click="closeEditFreezedateModal"></div>
        <Modal v-if="isEditFreezeDateModalOpen" @close="closeEditFreezedateModal">
            <h3>Edit Freeze Date</h3>
            <form @submit.prevent="submitEditFreezedateForm">
                <!-- <div class="form-group">
      <label for="customerAccount">Customers Accounts:</label>
      <v-select v-model="selectedOption" :options="filteredOptions" label="company_name_en" placeholder="Select account" @input="handleChange" style="width: 100%;"></v-select>
    </div> -->
                <div class="form-group">
                    <label for="customerAccount">Date freeze:</label>
                    <input type="number" v-model="editFreezeDateFormData.Freeze" class="form-control bordered-input p-2"
                        placeholder="date freeze" min="0" max="60" />
                </div>


                <button type="submit" class="btn btn-outline-info mr-2 mb-3">Edit Freeze Date</button>
        <button type="button" @click="closeEditFreezedateModal" class="btn btn-outline-danger mr-2 mb-3">Close</button>
            </form>
        </Modal>

        <!-- Edit Freeze Modal -->
        <div v-if="isEditFreezeModalOpen" class="modal-overlay" @click="closeEditFreezeModal"></div>
        <Modal v-if="isEditFreezeModalOpen" @close="closeEditFreezeModal">
            <h3>Edit Freeze</h3>
            <form @submit.prevent="submitEditFreezeForm">
                <div class="form-group">
                    <label for="customerAccount">Customers Accounts:</label>
                    <input type="text" v-model="editFreezeFormData.company_name" class="form-control bordered-input p-2"
                        placeholder="Company" disabled />
                </div>
                <div class="form-group">
                    <label>Freeze Options:</label>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="freezeWithdraw"
                            v-model="editFreezeFormData.withdraw_status" true-value="1" false-value="0" />
                        <label for="freezeWithdraw"> Freeze Withdraw</label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="freezeWithdraw"
                            v-model="editFreezeFormData.transfer_status" true-value="1" false-value="0">
                        <label for="freezeTransfer"> Freeze Transfer</label>
                    </div>
                </div>
             
                <button type="submit" class="btn btn-outline-info mr-2 mb-3">Edit Freeze</button>
        <button type="button" @click="closeEditFreezeModal" class="btn btn-outline-danger mr-2 mb-3">Close</button>
            </form>
        </Modal>



    </div>



</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Card, PaperTable } from "@/components";
import Modal from '@/components/Modal/MembershipsIcon.vue';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';

const Freeze = ref([]);
const FreezeDate = ref([]);
const isAddFreezeModalOpen = ref(false);
const isEditFreezeModalOpen = ref(false);
const isEditFreezeDateModalOpen = ref(false);
const token = localStorage.getItem('token');
const selectedOption = ref(null);
const filteredOptions = ref([]);

const addFreezeFormData = {
    Freeze: '',
    company_id: '',
    company_name: '',
    transfer_status: '0',
    withdraw_status: '0'
};

const editFreezeFormData = {
    id: '',
    Freeze: '',
    company_id: '',
    company_name: '',
    transfer_status: '',
    withdraw_status: ''

};
const editFreezeDateFormData = {
    Freeze: '',
};

const handleChange = async (value) => {
    // Check if the value is null before proceeding
    if (value === null) {
        resetSelections();
        // console.log('Selected Option is null. Skipping function.');
        return;
    }
};

const resetSelections = () => {
    selectedOption.value = null;
};
const currentPage = ref(1);
const pageSize = 10;
const totalPages = ref(1);
const totalRecords = ref(0);

const baseURL = 'https://api2.logizall.com';

const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'Asia/Bangkok',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
};

onMounted(() => {
    fetchFreeze(); // Uncommented this line
    getCustomer()
    fetchFreezeDate()
});

const openAddFreezeModal = () => {
    isAddFreezeModalOpen.value = true;
};

const openEditFreezeDateModal = () => {
    const FreezeDates = FreezeDate.value[0];
    editFreezeDateFormData.Freeze = FreezeDates.freeze_date;
    isEditFreezeDateModalOpen.value = true;
};

const closeAddFreezeModal = () => {
    resetAddFreezeFormData();
    isAddFreezeModalOpen.value = false;
};

const resetAddFreezeFormData = () => {
    addFreezeFormData.Freeze = '';
};

const openEditFreezeModal = (index) => {
    const Freezes = Freeze.value[index];
    console.log(Freezes)
    editFreezeFormData.id = Freezes.id;
    editFreezeFormData.company_name = Freezes.company_name;
    editFreezeFormData.transfer_status = Freezes.transfer_status;
    editFreezeFormData.withdraw_status = Freezes.withdraw_status;
    isEditFreezeModalOpen.value = true;
};

const closeEditFreezeModal = () => {
    resetEditFreezeFormData();
    isEditFreezeModalOpen.value = false;
};

const closeEditFreezedateModal = () => {
    isEditFreezeDateModalOpen.value = false;
};

const resetEditFreezeFormData = () => {
    editFreezeFormData.id = '';
    editFreezeFormData.Freeze = '';
};

const submitAddFreezeForm = async () => {
    try {
        console.log("addFreezeFormData", addFreezeFormData)
        const createFreezeFormData = {
            company_id: selectedOption.value.id,
            company_name: selectedOption.value.company_name_en,
            transfer_status: addFreezeFormData.transfer_status,
            withdraw_status: addFreezeFormData.withdraw_status
            // Add other fields as needed
        };

        console.log("create", addFreezeFormData)
        const response = await axios.post(`${baseURL}/admin/createfreeze`, createFreezeFormData, {
            headers: {
                Authorization: `${token}`,
            },
        });
        fetchFreeze();
        closeAddFreezeModal();
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
        });
    } catch (error) {
        console.error('Error adding Freeze:', error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to add Freeze. Please try again.',
        });
    }
};

const submitEditFreezeForm = async () => {
    try {
        const confirmed = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to edit this Freeze.',
            showCancelButton: true,
            confirmButtonText: 'Yes, edit it!',
            cancelButtonText: 'No, cancel!',
        });

        if (confirmed.isConfirmed) {
            const response = await axios.put(`${baseURL}/admin/editFreeze/`, editFreezeFormData, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            fetchFreeze();
            closeEditFreezeModal();
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
            });
        }
    } catch (error) {
        console.error('Error updating Freeze:', error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to update Freeze. Please try again.',
        });
    }
};
const submitEditFreezedateForm = async () => {
    try {
        const confirmed = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to edit this Freeze.',
            showCancelButton: true,
            confirmButtonText: 'Yes, edit it!',
            cancelButtonText: 'No, cancel!',
        });

        if (confirmed.isConfirmed) {
            const editFreezeDate = {
                freeze_date: editFreezeDateFormData.Freeze,
                // Add other fields as needed
            };
            const response = await axios.put(`${baseURL}/admin/editfreezedate/`, editFreezeDate, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            fetchFreezeDate();
            closeEditFreezedateModal();
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
            });
        }
    } catch (error) {
        console.error('Error updating Freeze:', error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to update Freeze. Please try again.',
        });
    }
};

const deleteFreeze = async (id) => {
    try {
        console.log(id)

        const confirmed = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'You are about to delete this Freeze.',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (confirmed.isConfirmed) {
            const response = await axios.delete(`${baseURL}/admin/deletefreeze/${id}`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            fetchFreeze();
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
            });
        }
    } catch (error) {
        console.error('Error deleting Freeze:', error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete Freeze. Please try again.',
        });
    }
};



const fetchFreeze = async () => {
    try {
        const response = await axios.get(`${baseURL}/admin/getfreeze`, {
            params: {
                page: currentPage.value,
                pageSize: pageSize,
            },
            headers: {
                Authorization: `${token}`,
            },
        });
        console.log("res", response.data)
        totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
        totalRecords.value = response.data.totalRecords || [];
        Freeze.value = response.data.freezeUsers || [];
        ////console.log(response.data.FreezeUsers)
    } catch (error) {
        console.error('Error fetching admins:', error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch admins data. Please try again.',
        });
    }
};

const fetchFreezeDate = async () => {
    try {
        const response = await axios.get(`${baseURL}/admin/getfreezedate`, {

            headers: {
                Authorization: `${token}`,
            },
        });
        console.log("res", response.data)

        FreezeDate.value = response.data.data || [];
        ////console.log(response.data.FreezeUsers)
    } catch (error) {
        console.error('Error fetching admins:', error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch admins data. Please try again.',
        });
    }
};

const getCustomer = async () => {
    try {
        let currentPage = 1;
        let totalPages = 1;
        let allOptions = []; // เพิ่มตัวแปรเก็บข้อมูลทั้งหมด

        do {
            const response = await axios.get(`https://api.logizall.com/api/customers?pagination%5Bpage%5D=${currentPage}&pagination%5BpageSize%5D=100`);
            //console.log("response", response.data.meta.pagination.pageCount);

            totalPages = response.data.meta.pagination.pageCount;

            const options = response.data.data
                .filter(option => option.attributes.status === "bronze") // Filter out only active data
                .map(option => ({
                    id: option.id,
                    company_name_en: option.attributes.companyNameEn
                }));

            allOptions = allOptions.concat(options); // เพิ่ม options เข้าไปใน allOptions 

            currentPage++;

        } while (currentPage <= totalPages);

        filteredOptions.value = allOptions; // ใส่ข้อมูลทั้งหมดลงใน filteredOptions.value
        //console.log(filteredOptions.value)
    } catch (error) {
        console.error('Error fetching balance types:', error);
    }
};

const prevPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
        fetchFreeze();
    }
};

const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
        fetchFreeze();
    }
};
</script>
<style scoped>
.bordered-input {
    border: 1px solid #a1a1a1;
}
</style>