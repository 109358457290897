<template>
  <div class="row">
    <div class="col-md-12">
      <Card :title="table1.title" :subTitle="table1.subTitle">
        <div slot="raw-content" class="table-responsive">
          <div class="mb-3 d-flex justify-content-end">
            <form class="form-inline">
              <div class="form-group mr-3">
                <v-select v-model="selectedOption" :options="filteredOptions" label="company_name_en"
                  placeholder="Select account" @input="handleChange" style="width: 250px"></v-select>
              </div>

              <div class="form-group mr-3">
                <select v-model="selectedFilter"
                  class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                  style="width: 200px; height: 38px">
                  <option value="selected_month">Selected Month</option>
                  <option value="selected_year">Selected Year</option>
                  <option value="selected_day">Selected Day</option>
                  <!-- Add more options as needed -->
                </select>
              </div>

              <div class="form-group mr-3">
                <div v-if="selectedFilter === 'selected_day'">
                  <input type="date" v-model="searchQuery" placeholder="Select Date..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px" />
                </div>

                <div v-else-if="selectedFilter === 'selected_month'">
                  <input type="month" v-model="searchQuery" placeholder="Select Month..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px" />
                </div>
                <div v-else-if="selectedFilter === 'selected_year'">
                  <input type="number" v-model="searchQuery" placeholder="Select year..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px" />
                </div>
              </div>

              <button @click="search(selectedFilter, searchQuery)" class="btn btn-outline-danger mr-2">
                <i class="ti-search"></i> Search
              </button>
              <button @click="getDataAndGenerateRefillPDF" class="btn btn-outline-default mr-2">
                <i class="ti-printer"></i> Print Report
              </button>
              <button @click="reload()" class="btn btn-outline-info mr-2">
                <i class="ti-reload"></i>
              </button>
            </form>
          </div>
          <table class="table table-bordered">
            <thead class="bg-light bold-text">
              <tr>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold">
                  #
                </th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold">
                  Company
                </th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold">
                  Reference Number
                </th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold">
                  Currency
                </th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold">
                  Amount
                </th>
                
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold">
                  Note
                </th>
                <th class="py-2 pl-6" style="width: 15%; font-weight: bold">
                  Remark
                </th>
                <th class="py-2 pl-6" style="width: 10%; font-weight: bold">
                  Date Transfer
                </th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold">
                  Slip/File
                </th>
                <th class="py-2 pl-6" style="width: 5%; font-weight: bold">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction, index) in Refill" :key="transaction.id">
                <td class="py-2 pl-6 md-2">{{ index + 1 }}</td>
                <td class="py-2 pl-6 md-2 text-left">
                  {{ transaction.company_name }}
                </td>
                <td class="py-2 pl-6 md-2 text-left">
                  {{ transaction.referent_number }}
                </td>
                <td class="py-2 pl-6 md-2">{{ transaction.currency }}</td>
                <td class="py-2 pl-6 md-2 text-right">
                  {{ parseFloat(transaction.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </td>
                <td class="py-2 pl-6 md-2 text-left">{{ transaction.note }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="white-space: nowrap">
                  <span  v-for="line in transaction.remark.split('+')" :key="line">{{ line }} {{
        transaction.cancel_reason }}<br /></span>
                </td>
                <td class="py-2 pl-6 md-2">
                  {{ formatDate(transaction.dateCreate) }}
                </td>

                <td style="text-align: center; width: 5%">
                  <button v-if="transaction.image" @click="openFileModal(getImageUrl(transaction.image))"
                    class="btn btn-outline-info">
                    <i class="ti-eye"></i> View File
                  </button>
                  <span v-else class="text-muted">No File</span>
                </td>
                <td style="text-align: center; width: 5%">
                  <button :style="{
        backgroundColor: getStatusColor(transaction.status),
        color: 'white',
        padding: '0.5rem',
        borderRadius: '5px',
        border: '1px solid ' + getStatusColor(transaction.status),
        cursor: 'pointer',
      }" class="btn btn-outline" @click="changeStatus(transaction)" :disabled="(isAdmin == 1 && transaction.status != 0) ||
        (isAdmin == 2 && transaction.status != 1) ||
        (isAdmin == 3 && transaction.status != 2)
        ">
                    {{
        isAdmin == 1 &&
          transaction.status >= 1 &&
          transaction.status != 4 &&
          transaction.status != 3
          ? "In Progress"
          : isAdmin == 1 && transaction.status == 0
            ? "Pending"
            : isAdmin == 2 &&
              transaction.status >= 2 &&
              transaction.status != 4 &&
              transaction.status != 3
              ? "In Progress"
              : transaction.status == 3
                ? "Success"
                : transaction.status == 4
                  ? "Cancel"
                  : "Pending"
      }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="main-container">
            <!-- ... (your existing template code) ... -->
            <div class="pagination-container">
              <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
                Prev
              </div>
              <div v-else class="btn btn-secondary" disabled>Prev</div>
              <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
              <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
                Next
              </div>
              <div v-else @click="nextPage()" class="btn btn-outline-danger">
                Next
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { Card, PaperTable } from "@/components";
import Swal from "sweetalert2";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

const selectedFilter = ref("selected_month");
const baseURL = "https://api2.logizall.com";
const Refill = ref([]);
const Quertsearch = ref();
const token = localStorage.getItem("token");
const table1 = ref({
  title: "Refill",
  subTitle: "Refill",
});

let searchQuery;

const currentPage = ref(1);
const pageSize = 10; // Set your desired page size
const totalPages = ref(1);
const totalRecords = ref(0);
const isSearch = ref(false);

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Asia/Bangkok",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};
const changeStatus = async (transaction) => {
  let originalAmount;
  console.log(isAdmin.value)
  if (isAdmin.value == 1) {
    originalAmount =
      parseFloat(transaction.amount) / parseFloat(transaction.currency_rate);
  } else {
    originalAmount = transaction.amount;
  }
  console.log("transaction.amount", parseFloat(originalAmount))
  const result = await Swal.fire({
    title: "Change Status",
    icon: "warning",
    input: "text",
    inputValue: parseFloat(originalAmount).toFixed(2), // Set the default value
    inputLabel: "Enter an Amount",
    inputPlaceholder: "Enter the new amount...",
    showCancelButton: true,
    showDenyButton: true,
    showConfirmButton: true,
    confirmButtonText: "Confirm",
    denyButtonText: "Cancel",
    cancelButtonText: "Close",
    inputValidator: (value) => {
      return isNaN(value) ? "Please enter a valid number!" : null;
    },
  });

  if (result.isConfirmed) {
  if(result.value !== parseFloat(originalAmount).toFixed(2)){
    // User clicked "Confirm"
    console.log("if")
    const updatedAmount = parseFloat(result.value);
    transaction.currency_amount = updatedAmount;
    const newAmount = parseFloat(result.value);
    updateStatus(transaction, isAdmin.value, newAmount);
    
  } else if(result.value == parseFloat(originalAmount).toFixed(2)){
    console.log("else")
    const updatedAmount = parseFloat(originalAmount);
    transaction.currency_amount = updatedAmount;
    const newAmount = parseFloat(originalAmount);
    updateStatus(transaction, isAdmin.value, newAmount);
  }
  } else if (result.isDenied) {
    // Show required input prompt for text note
    const { value: note } = await Swal.fire({
      title: "Cancel",
      input: "text",
      inputLabel: "Provide a note (required)",
      inputPlaceholder: "Type your note here...",
      showCancelButton: true,
      inputValidator: (value) => {
        return !value && "Note is required!";
      },
    });

    if (note !== undefined) {
      updateStatus(transaction, 4, note);
    }
  } else {
    // User clicked "Cancel"
  }
};

const updateStatus = async (transaction, newStatus, note) => {
  try {
    //console.log('Optional note:', transaction.currency_amount);
    //console.log(token);
    //console.log("log", transaction.id, newStatus);

    const response = await axios.put(
      `${baseURL}/admin/change-status/${transaction.id}`,
      {
        currency_amount: transaction.currency_amount,
        note: note,
        newStatus: newStatus,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      },
    );

    console.log(response);

    // Handle the response accordingly
    // You may want to refresh the data or update the status directly in the component
    // For example: transaction.fps_status = response.data.newStatus;
    Swal.fire("Success", `The status has been changed `, "success").then(() => {
      // Reload the page after the user clicks "OK" on the success message
      window.location.reload();
    });
  } catch (error) {
    console.error("Error updating status:", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "An error occurred while updating the status.",
    });
  }
};

const getRefill = async () => {
  try {
    const response = await axios.get(`${baseURL}/admin/getrefill`, {
      params: {
        page: currentPage.value,
        pageSize: pageSize,
      },
      headers: {
        Authorization: token,
      },
    });
    console.log("response", response);
    Refill.value = response.data.data || [];
    totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
    totalRecords.value = response.data.totalRecords || [];
  } catch (error) {
    console.error("Error fetching My Wallet data:", error);
    // Handle the error appropriately, e.g., show an error message to the user.
  }
};
const prevPage = () => {
  prevOrNextPage("prev");
};

const nextPage = () => {
  prevOrNextPage("next");
};

const prevOrNextPage = (action) => {
  if (action == "prev") {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
  } else if (action == "next") {
    //console.log("pageSize", pageSize)
    //console.log("totalRecords", totalRecords.value)
    if (currentPage.value * pageSize <= totalRecords.value) {
      currentPage.value++;
    }
    //console.log("currentPage", currentPage.value)
  }

  // Check if it's a search or regular page navigation
  if (isSearch.value) {
    //console.log("isSearch", isSearch.value)
    search(selectedFilter.value, Quertsearch.value);
  } else {
    getRefill();
  }
};
const reload = async () => {
  fetchTransfer();
  getRefill();
  checkAdminRole();
};
const search = async (dateFilter, searchQuery) => {
  //console.log("search");
  //console.log("dateFilter", dateFilter);
  //console.log("selectedDate", searchQuery);
  Quertsearch.value = searchQuery;
  //console.log("Quertsearch", Quertsearch.value);
  //console.log("selectedOption", selectedOption.value);
  try {
    // Check if selectedFilter is null
    //console.log("try")
    if (
      dateFilter == "selected_month" &&
      !searchQuery &&
      !Quertsearch.value &&
      !selectedOption.value
    ) {
      getRefill();
      try {
        console.log("try pdf");
        const responsepdf = await axios.get(
          `${baseURL}/admin/getrefillbysearchpdf`,
          {
            params: { dateFilter, selectedDate: searchQuery },
            headers: {
              Authorization: token,
            },
          },
        );
        const datapdf = responsepdf.data.data;
        const totalAmount = responsepdf.data.totalAmountDue || 0;
        // เพิ่มลำดับในข้อมูล
        const dataWithIndex = datapdf.map((record, index) => {
          return {
            ลำดับ: index + 1,
            company_name: record.company_name,
            referent_number:record.referent_number,
            balance_type:record.balance_type,
            currency:record.currency,
            amount:parseFloat(record.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            note: record.note,
            remark: record.remark,
            dateTransfer:record.dateTransfer,
            datePublic:record.datePublic,
            status:record.status
          };
        });
        summaryData.value = parseFloat(totalAmount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) || 0;
        pdfContent.value = generatePDFContent(dataWithIndex);
        fetchedData.value = dataWithIndex;
        // printReport()
      } catch (error) {
        console.error("Error retrieving data or generating PDF", error);
      }
    } else {
      if (selectedOption.value === null) {
        //console.log("if")
        // Perform the search using the original API
        const response = await axios.get(`${baseURL}/admin/getrefillbysearch`, {
          params: {
            dateFilter,
            selectedDate: searchQuery,
            page: currentPage.value,
            pageSize: pageSize,
          },
          headers: {
            Authorization: token,
          },
        });

        // Destructure the response data
        const { data, total } = response.data;

        // Update values
        Refill.value = data || [];
        totalRecords.value = total || [];

        // Set the search flag to true
        isSearch.value = true;
        totalPages.value = Math.ceil(response.data.total / pageSize);
        // Update the current page to 1, as the search results start from the first page

        try {
          //console.log("try pdf")
          const responsepdf = await axios.get(
            `${baseURL}/admin/getrefillbysearchpdf`,
            {
              params: { dateFilter, selectedDate: searchQuery },
              headers: {
                Authorization: token,
              },
            },
          );
          const datapdf = responsepdf.data.data;
          const totalAmount = responsepdf.data.totalAmountDue || 0;
          // เพิ่มลำดับในข้อมูล
          const dataWithIndex = datapdf.map((record, index) => {
            return {
              ลำดับ: index + 1,
            company_name: record.company_name,
            referent_number:record.referent_number,
            balance_type:record.balance_type,
            currency:record.currency,
            amount:parseFloat(record.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            note: record.note,
            remark: record.remark,
            dateTransfer:record.dateTransfer,
            datePublic:record.datePublic,
            status:record.status
            };
          });
          summaryData.value = parseFloat(totalAmount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) || 0;
          pdfContent.value = generatePDFContent(dataWithIndex);
          fetchedData.value = dataWithIndex;
          // printReport()
        } catch (error) {
          console.error("Error retrieving data or generating PDF", error);
        }
      } else {
        //console.log("else")
        // Perform the search using the new API
        //console.log("selectedOption.value", selectedOption.value.id)
        const response = await axios.get(
          `${baseURL}/admin/getrefillbysearchmid/${selectedOption.value.id}`,
          {
            params: {
              dateFilter,
              selectedDate: searchQuery,
              page: currentPage.value,
              pageSize: pageSize,
            },
            headers: {
              Authorization: token,
            },
          },
        );

        // Destructure the response data
        const { data, total } = response.data;

        // Update values
        Refill.value = data || [];
        totalRecords.value = total || [];

        // Set the search flag to true
        isSearch.value = true;
        totalPages.value = Math.ceil(response.data.total / pageSize);

        // Update the current page to 1, as the search results start from the first page

        try {
          //console.log("try pdf")
          const responsepdf = await axios.get(
            `${baseURL}/admin/getrefillbysearchmidpdf/${selectedOption.value.id}`,
            {
              params: { dateFilter, selectedDate: searchQuery },
              headers: {
                Authorization: token,
              },
            },
          );
          const datapdf = responsepdf.data.data;
          const totalAmount = responsepdf.data.totalAmountDue || 0;

          // เพิ่มลำดับในข้อมูล
          const dataWithIndex = datapdf.map((record, index) => {
            return {
              ลำดับ: index + 1,
            company_name: record.company_name,
            referent_number:record.referent_number,
            balance_type:record.balance_type,
            currency:record.currency,
            amount:parseFloat(record.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            note: record.note,
            remark: record.remark,
            dateTransfer:record.dateTransfer,
            datePublic:record.datePublic,
            status:record.status
            };
          });
          summaryData.value = parseFloat(totalAmount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) || 0;
          
          pdfContent.value = generatePDFContent(dataWithIndex);
          fetchedData.value = dataWithIndex;
          // printReport()
        } catch (error) {
          console.error("Error retrieving data or generating PDF", error);
        }
      }
    }
  } catch (error) {
    console.error("Error fetching accounting data:", error);
    // Handle the error appropriately, e.g., show an error message to the user.
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "An error occurred while fetching data.",
    });
  }
};

const openFileModal = (fileUrl) => {
  console.log(fileUrl);
  if (fileUrl) {
    // Check if the file is an image (JPEG, PNG, GIF)
    const isImage = /\.(jpe?g|png|gif)$/i.test(fileUrl);

    if (isImage) {
      // Display the image in a smaller SweetAlert modal
      Swal.fire({
        imageUrl: fileUrl,
        imageAlt: "FPS",
        showCloseButton: true,
        customClass: {
          popup: "smaller-modal", // Add a custom CSS class for the modal
        },
      });
    } else {
      // Open the PDF in a new window or tab
      window.open(fileUrl, "_blank");
    }
  } else {
    // Handle the case where there is no file
    Swal.fire({
      icon: "info",
      title: "No File",
      text: "There is no file available for this transaction.",
    });
  }
};

const getImageUrl = (filename) => {
  console.log("/api/wallet/images/slip/", filename);
  // Replace 'localhost:4000' with your actual server address
  return `${baseURL}/api/wallet/images/slip/${filename}`;
};
const getStatusColor = (status) => {
  switch (status) {
    case 0:
      return "orange";
    case 1:
      return "orange";
    case 2:
      return "orange";
    case 3:
      return "green";
    case 4:
      return "red";
    default:
      return "red";
  }
};

const selectedOption = ref(null);
const isAdmin = ref(null);
const filteredOptions = ref([]);
const options = ref([]);
const handleChange = (value) => {
  // Handle the selected value here
  console.log("Selected Option:", value);
  // You can perform additional actions or update other variables as needed
};
const fetchTransfer = async () => {
  try {
    let currentPage = 1;
    let totalPages = 1;
    let allOptions = []; // เพิ่มตัวแปรเก็บข้อมูลทั้งหมด

    do {
      const response = await axios.get(
        `https://api.logizall.com/api/customers?pagination%5Bpage%5D=${currentPage}&pagination%5BpageSize%5D=100`,
      );
      //console.log("response", response.data.meta.pagination.pageCount);

      totalPages = response.data.meta.pagination.pageCount;

      const options = response.data.data
        .filter((option) => option.attributes.status === "bronze") // Filter out only active data
        .map((option) => ({
          id: option.id,
          company_name_en: option.attributes.companyNameEn,
        }));

      allOptions = allOptions.concat(options); // เพิ่ม options เข้าไปใน allOptions

      currentPage++;
    } while (currentPage <= totalPages);

    filteredOptions.value = allOptions; // ใส่ข้อมูลทั้งหมดลงใน filteredOptions.value
    //console.log(filteredOptions.value)
  } catch (error) {
    console.error("Error fetching balance types:", error);
  }
};

onMounted(() => {
  fetchTransfer();
  getRefill();
  checkAdminRole();
});

const pdfContent = ref("");
const summaryData = ref("");
const fetchedData = ref([]);
const tableHeaders = ref([
  "No",
  "Company Name",
  "REF",
  "Balance Type",
  "Currency",
  "Amount",
  "Note",
  "Remark",
  "Date Tranfer",
  "Date Public",
  "Status",
]);

const getDataAndGenerateRefillPDF = async () => {
  try {
    if (isSearch.value == false) {
      //console.log("isSearch", isSearch.value)
      const response = await axios.get(`${baseURL}/admin/getrefillingpdf`, {
        headers: {
          Authorization: token,
        },
      });
      const data = response.data.data;
      const totalAmountDue = response.data.summary.totalAmountDue || 0;
      // เพิ่มลำดับในข้อมูล
      const dataWithIndex = data.map((record, index) => {
        return {
          ลำดับ: index + 1,
            company_name: record.company_name,
            referent_number:record.referent_number,
            balance_type:record.balance_type,
            currency:record.currency,
            amount:parseFloat(record.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            note: record.note,
            remark: record.remark,
            dateTransfer:record.dateTransfer,
            datePublic:record.datePublic,
            status:record.status
        };
      });
      // เพิ่ม totalAmountDue ใน summary
      const summary = {
        totalAmountDue: totalAmountDue,
        // สามารถเพิ่ม properties อื่น ๆ ของ summary ตามที่ต้องการ
      };
      //console.log("sum", summary.totalAmountDue[0].totalAmountDue)
      summaryData.value = parseFloat(summary.totalAmountDue[0].totalAmountDue).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
      pdfContent.value = generatePDFContent(dataWithIndex);
      fetchedData.value = dataWithIndex;
      printReport();
    } else {
      printReport();
    }
  } catch (error) {
    console.error("Error retrieving data or generating PDF", error);
  }
};

const generatePDFContent = (data) => {
  let content = "ข้อมูลจาก API:\n\n";
  data.forEach((record, index) => {
    // //console.log(formatDate(record.dateCreate))
    // Format ข้อมูลวันที่ด้วย formatDate function
    const formattedDateCreate = formatDate(record.dateCreate);

    content += `Record ${index + 1}:\n`;
    for (const [key, value] of Object.entries(record)) {
      // เช็คว่า key เป็น 'dateCreate' หรือไม่ ถ้าใช่ให้ใช้ formattedDateCreate
      const displayValue = key == "dateCreate" ? formattedDateCreate : value;
      // //console.log("displayValue",displayValue)
      content += `${key}: ${displayValue}\n`;
    }
    content += "\n";
  });
  content += "Summary:\n";
  for (const [key, value] of Object.entries(summaryData)) {
    content += `${key}: ${value}\n`;
  }
  return content;
};

const printReport = () => {
  if (pdfContent.value && fetchedData.value) {
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print Report</title>");
    // Add custom styles for the table
    printWindow.document.write(`
      <style>
        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 40px;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
          font-size: 11px; 
        }
        th {
          background-color: #f2f2f2;
        }
        .summary-row {
          font-weight: bold;
          background-color: #e6e6e6; /* Light gray background color */
        }
      </style>
    `);
    printWindow.document.write("</head><body>");

    // Create the table
    printWindow.document.write("<table>");
    printWindow.document.write("<thead><tr>");
    // Use tableHeaders instead of fetching from the first record
    tableHeaders.value.forEach((header) => {
      printWindow.document.write(`<th>${header}</th>`);
    });
    printWindow.document.write("</tr></thead><tbody>");

    // Populate the table with data
    fetchedData.value.forEach((record, index) => {
      printWindow.document.write("<tr>");
      for (const [key, value] of Object.entries(record)) {
        // Check if the key is 'dateCreate' or 'balance_type'
        const displayValue =
          key === "dateTransfer"
            ? formatDate(value)
            : key === "balance_type"
              ? value === 1
                ? "Deposit"
                : value
              : key === "datePublic"
                ? formatDate(value)
                : value;
        printWindow.document.write(`<td>${displayValue}</td>`);
      }
      printWindow.document.write("</tr>");
      // if ((index + 1) % 10 === 0) {
      //   printWindow.document.write('</tbody></table><div style="page-break-after: always;"></div><table><tbody>');
      // }
    });
    tableHeaders.value.forEach((header) => {
      printWindow.document.write(`<th>${header}</th>`);
    });
    printWindow.document.write('<tr class="summary-row">');

    for (const [key, value] of Object.entries(summaryData)) {
      // Handle display logic for summary keys if needed
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td>Summary</td>`);
      printWindow.document.write(`<td>${value}</td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
    }
    printWindow.document.write("</tr>");

    printWindow.document.write("</tbody></table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  } else {
    console.warn("No content to print. Generate a report first.");
  }
};

const checkAdminRole = async () => {
  try {
    const response = await axios.get(
      "https://api2.logizall.com/auth/admin/verify-token",
      {
        headers: {
          Authorization: token,
        },
      },
    );

    // Check if user is admin with role 1

    console.log("res", response.data.role_admin);
    isAdmin.value = response.data.role_admin;
    // return isAdmin ;
  } catch (error) {
    console.error("Failed to check admin role:", error.message);
    return false; // Default to false if role check fails
  }
};
</script>

<style>
.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.search-container {
  display: flex;
  /* align-items: right; */
  margin-left: auto;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  /* Align items at the start of the container */
  align-items: stretch;
  /* Stretch items vertically */
  text-align: center;
  /* Center text within each item */
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.smaller-modal {
  max-width: 50%;
  /* Adjust the maximum width as needed */
}

.bea-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  /* Adjust the font size as needed */
  color: #333;
  /* Text color */

  width: 200px;
}

.bea-select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  /* Adjust the font size as needed */
  color: #333;
  /* Text color */
  width: 200px;
}

.bold-text {
  font-weight: bold;
}
</style>
