<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">{{ routeName }}</a>
      <button
        class="navbar-toggler navbar-burger"
        type="button"
        @click="toggleSidebar"
        :aria-expanded="$sidebar.showSidebar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
      </button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="ti-panel"></i>
              <p>Stats</p>
            </a>
          </li>
          <drop-down
            class="nav-item"
            title="5 Notifications"
            title-classes="nav-link"
            icon="ti-bell"
          >
            <a class="dropdown-item" href="#">Notification 1</a>
            <a class="dropdown-item" href="#">Notification 2</a>
            <a class="dropdown-item" href="#">Notification 3</a>
            <a class="dropdown-item" href="#">Notification 4</a>
            <a class="dropdown-item" href="#">Another notification</a>
          </drop-down> -->
          <li class="nav-item">
            <a   class="nav-link pointer">
              <i class="ti-user"></i>
              <p>{{isAdmin == 1 ? 'admin': isAdmin == 2 ? 'accounting' : isAdmin == 3 ? 'manager' : 'admin' }}</p>
            </a>
          </li>
          <li class="nav-item">
            <a  @click="logout" class="nav-link pointer">
              <i class="ti-power-off"></i>
              <p>Logout</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import axios from 'axios';
export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      isAdmin: null,
    };
  },
  async created() {
    // Perform token verification and get user role
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get('https://api2.logizall.com/auth/admin/verify-token', {
          headers: {
            Authorization: token,
          },
        });

        // สมมติว่าข้อมูลผู้ใช้มีการส่งค่าบทบาทกลับมาแบบนี้ { role: 3 } เพื่อตรวจสอบบทบาทของผู้ใช้
        const role = response.data.role_admin;
        
        this.isAdmin = role
        console.log(this.isAdmin)
        // return role
      } catch (error) {
        console.error('Failed to verify token:', error.message);
      }
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      // console.log("logout",localStorage.getItem('token'))
      this.$notify({
        title: 'You have been Logout!!',
        text: 'You have been successfully logged out.',
        type: 'danger',
        duration: 3000, // Adjust the duration as needed

      });
      this.$router.push('/login');
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>
<style>
.nav-item a.pointer {
  cursor: pointer;
}

</style>
