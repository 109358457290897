<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Partners Link Section</h4>
            </div>
            <div class="card-body">
          <form @submit.prevent="updateconference">
            <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle1">Phone Number: </label>
                      <input v-model="updatedData.partners_phone " id="valueBarTitle1" type="text"
                        class="form-control bordered-input " />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar1">Email:</label>
                      <input v-model="updatedData.partners_email" id="valueBar1" type="text"
                        class="form-control bordered-input bordered-input " />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle1">Facebook Link:</label>
                      <input v-model="updatedData.partners_facebook" id="valueBarTitle1" type="text"
                        class="form-control bordered-input " />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar1">Youtube Link:</label>
                      <input v-model="updatedData.partners_youtube" id="valueBar1" type="text"
                        class="form-control bordered-input bordered-input " />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle1">Instagram Link:</label>
                      <input v-model="updatedData.partners_instagram" id="valueBarTitle1" type="text"
                        class="form-control bordered-input " />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar1">Linkedin Link:</label>
                      <input v-model="updatedData.partners_linkedin" id="valueBar1" type="text"
                        class="form-control bordered-input bordered-input " />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle1">Tictok Link:</label>
                      <input v-model="updatedData.partners_tictok" id="valueBarTitle1" type="text"
                        class="form-control bordered-input " />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="valueBar1">Line.me Link:</label>
                      <input v-model="updatedData.partners_line" id="valueBar1" type="text"
                        class="form-control bordered-input bordered-input " />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="valueBarTitle1">Whatapps Link:</label>
                      <input v-model="updatedData.partners_whatsapp" id="valueBarTitle1" type="text"
                        class="form-control bordered-input " />
                    </div>
                    <!-- <div class="form-group col-md-6">
                      <label for="valueBar1">Line.me Link:</label>
                      <input v-model="updatedData.partners_line" id="valueBar1" type="text"
                        class="form-control bordered-input bordered-input " />
                    </div> -->
                  </div>

            <button type="submit" class="btn btn-outline-danger">Update Data</button>
          </form>
        </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { Card, PaperTable } from "@/components";
import Modal from '@/components/Modal/MembershipsIcon.vue'; // แก้ไขตามโค้ดจริง
import axios from 'axios';
import Swal from 'sweetalert2';

const partnerslinkData = ref({});

// const baseURL = "https://api2.logizall.com"
const baseURL = "https://api2.logizall.com";
const token = localStorage.getItem('token');
onMounted(() => {
  getpartnerslinkDataData();
});


const updatedData = ref({
  partners_phone: '',
  partners_email: '',
  partners_facebook: '',
  partners_youtube: '',
  partners_instagram: '',
  partners_linkedin: '',
  partners_tictok: '',
  partners_line: '',
  partners_whatsapp: '',


  // Add other form fields as needed
});

const getpartnerslinkDataData = async () => {
  try {
    const response = await axios.get(`${baseURL}/landingpage/partnerslink/getpartnerslinkdata`);
    partnerslinkData.value = response.data[0];
    updatedData.value = { ...partnerslinkData.value }; // Assign data to updatedData
    //console.log(partnerslinkData.value);
  } catch (error) {
    console.error('Error fetching conference data:', error);
  }
};
const updateconference = async () => {
  try {
    const formData = new FormData();
    formData.append('partners_phone', updatedData.value.partners_phone);
    formData.append('partners_email', updatedData.value.partners_email);
    formData.append('partners_facebook', updatedData.value.partners_facebook);
    formData.append('partners_youtube', updatedData.value.partners_youtube);
    formData.append('partners_instagram', updatedData.value.partners_instagram);
    formData.append('partners_linkedin', updatedData.value.partners_linkedin);
    formData.append('partners_tictok', updatedData.value.partners_tictok);
    formData.append('partners_line', updatedData.value.partners_line);
    formData.append('partners_whatsapp', updatedData.value.partners_whatsapp);

    //console.log("formData", formData);
    const response = await axios.put(`${baseURL}/landingpage/partnerslink/updatepartnerslink`, formData, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    });
    //console.log(response.data.message);

    if (response.data.message === "Data updated successfully.") {
      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Update Successful!',
        text: response.data.message,
      });

      // Refresh data after successful update
      getpartnerslinkDataData();
    } else {
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: response.data.message,
      });
    }
  } catch (error) {
    console.error('Error updating conference data:', error);

    // Show error alert
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while updating conference data.',
    });
  }
};
</script>

<style scoped>
/* Add additional styles as needed */
.card {
  margin-top: 20px;
}

.bordered-input {
  border: 1px solid #b6b6b6;
}
</style>
