<template>
    <div class="col-md-12">
      <Card>
        <div class="d-flex justify-content-end mb-3">
          <button @click="openAddServicechargeModal" class="btn btn-outline-primary">
            <i class="ti-plus"></i> Add Servicecharge
          </button>
        </div>
  
        <div slot="raw-content" class="table-responsive">
          <table class="table table-bordered">
            <thead class="bg-light bold-text">
              <tr>
                <th style="font-weight: bold;">#</th>
                <th style="font-weight: bold;">Service Charge (%)</th>
                <!-- <th style="font-weight: bold;">Create Date</th>
                <th style="font-weight: bold;">Create By</th> -->
                <th style="font-weight: bold;">Edit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(servicecharge, index) in Servicecharges" :key="servicecharge.id">
                <td class="py-2 pl-6 md-2" style="width: 8%;">{{ index + 1 }}</td>
                <td class="py-2 pl-6 md-2" style="width: 20%;">{{ servicecharge.servicecharge }}%</td>
                <!-- <td>{{ formatDate(servicecharge.created_at) }}</td>
                <td>{{ servicecharge.created_by }}</td> -->
                <td style="text-align: center; width: 20%;  ">
                  <button @click="openEditServicechargeModal(index)" class="btn btn-outline-primary mr-2">
                    <i class="ti-pencil"></i>
                    Edit
                  </button>
                  <button  @click="deleteServicecharge(servicecharge.id)" class="btn btn-outline-danger mr-2">
      <i class="ti-trash"></i>
      Delete
  </button>
  
                </td>
              </tr>
            </tbody>
          </table>
          <div class="main-container">
            <div class="pagination-container">
              <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
                Prev
              </div>
              <div v-else class="btn btn-secondary" disabled>
                Prev
              </div>
              <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
              <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
                Next
              </div>
              <div v-else @click="nextPage()" class="btn btn-outline-danger">
                Next
              </div>
            </div>
          </div>
        </div>
      </Card>
  
      <!-- Add Servicecharge Modal -->
      <div v-if="isAddServicechargeModalOpen" class="modal-overlay" @click="closeAddServicechargeModal"></div>
      <Modal v-if="isAddServicechargeModalOpen" @close="closeAddServicechargeModal">
    <h3>Add New Servicecharge</h3>
    <form @submit.prevent="submitAddServicechargeForm">
      <div class="form-group">
        <label for="servicecharge">Service Charge (percent):</label>
        <input type="number" v-model="addServicechargeFormData.servicecharge" class="form-control bordered-input p-2" placeholder="Enter Service Charge (percent)" required min="0"/>
      </div>
  
  
      <button type="submit" class="btn btn-primary mt-3">Add Servicecharge</button>
    </form>
  </Modal>
  
  
      <!-- Edit Servicecharge Modal -->
      <div v-if="isEditServicechargeModalOpen" class="modal-overlay" @click="closeEditServicechargeModal"></div>
      <Modal v-if="isEditServicechargeModalOpen" @close="closeEditServicechargeModal">
    <h3>Edit Servicecharge</h3>
    <form @submit.prevent="submitEditServicechargeForm">
      <div class="form-group">
        <label for="servicecharge">Service Charge (percent):</label>
        <input type="number" v-model="editServicechargeFormData.servicecharge" class="form-control bordered-input p-2" placeholder="Enter Service Charge (percent)" required min="0" />
      </div>
  
  
      <button type="submit" class="btn btn-primary">Save Changes</button>
    </form>
  </Modal>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { Card, PaperTable } from "@/components";
  import Modal from '@/components/Modal/MembershipsIcon.vue'; 
  const Servicecharges = ref([]);
  const isAddServicechargeModalOpen = ref(false);
  const isEditServicechargeModalOpen = ref(false);
  const token = localStorage.getItem('token');
  
  const addServicechargeFormData = {
    servicecharge: '',
  };
  
  const editServicechargeFormData = {
    id: '',
    servicecharge: '',

  };
  
  const currentPage = ref(1);
  const pageSize = 10;
  const totalPages = ref(1);
  const totalRecords = ref(0);
  
  const baseURL = 'https://api2.logizall.com';

  const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Asia/Bangkok',
  };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};
  
  onMounted(() => {
    fetchServicecharges(); // Uncommented this line
  });
  
  const openAddServicechargeModal = () => {
    isAddServicechargeModalOpen.value = true;
  };
  
  const closeAddServicechargeModal = () => {
    resetAddServicechargeFormData();
    isAddServicechargeModalOpen.value = false;
  };
  
  const resetAddServicechargeFormData = () => {
    addServicechargeFormData.servicecharge = '';
  };
  
  const openEditServicechargeModal = (index) => {
    const servicecharge = Servicecharges.value[index];
    editServicechargeFormData.id = servicecharge.id;
    editServicechargeFormData.servicecharge = servicecharge.servicecharge;
    isEditServicechargeModalOpen.value = true;
  };
  
  const closeEditServicechargeModal = () => {
    resetEditServicechargeFormData();
    isEditServicechargeModalOpen.value = false;
  };
  
  const resetEditServicechargeFormData = () => {
    editServicechargeFormData.id = '';
    editServicechargeFormData.servicecharge = '';
  };
  
  const submitAddServicechargeForm = async () => {
    try {
      const response = await axios.post(`${baseURL}/admin/createservicecharge`, addServicechargeFormData, {
        headers: {
          Authorization: `${token}`,
        },
      });
      fetchServicecharges();
      closeAddServicechargeModal();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
    } catch (error) {
      console.error('Error adding servicecharge:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add servicecharge. Please try again.',
      });
    }
  };
  
  const submitEditServicechargeForm = async () => {
    try {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'You are about to edit this servicecharge.',
        showCancelButton: true,
        confirmButtonText: 'Yes, edit it!',
        cancelButtonText: 'No, cancel!',
      });
  
      if (confirmed.isConfirmed) {
        const response = await axios.put(`${baseURL}/admin/editservicecharge/`, editServicechargeFormData, {
          headers: {
            Authorization: `${token}`,
          },
        });
        fetchServicecharges();
        closeEditServicechargeModal();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
        });
      }
    } catch (error) {
      console.error('Error updating servicecharge:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update servicecharge. Please try again.',
      });
    }
  };
  
  const deleteServicecharge = async (id) => {
    try {
      
  
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'You are about to delete this servicecharge.',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });
  
      if (confirmed.isConfirmed) {
        const response = await axios.delete(`${baseURL}/admin/deleteservicecharge/${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        fetchServicecharges();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
        });
      }
    } catch (error) {
      console.error('Error deleting servicecharge:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete servicecharge. Please try again.',
      });
    }
  };
  
  
  
  const fetchServicecharges = async () => {
    try {
      const response = await axios.get(`${baseURL}/admin/getservicecharge`, {
        params: {
          page: currentPage.value,
          pageSize: pageSize,
        },
        headers: {
          Authorization: `${token}`,
        },
      });
      console.log(response.data)
      totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
      totalRecords.value = response.data.totalRecords || [];
      Servicecharges.value = response.data.servicechargeUsers || [];
      ////console.log(response.data.servicechargeUsers)
    } catch (error) {
      console.error('Error fetching admins:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch admins data. Please try again.',
      });
    }
  };
  
  const prevPage = () => {
    if (currentPage.value > 1) {
      currentPage.value--;
      fetchServicecharges();
    }
  };
  
  const nextPage = () => {
    if (currentPage.value < totalPages.value) {
      currentPage.value++;
      fetchServicecharges();
    }
  };
  </script>
  <style scoped>
  .bordered-input {
      border: 1px solid #a1a1a1;
    }
  </style>
  