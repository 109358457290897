<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Membership Section</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="updateconference">
                <div class="form-group">
                  <label for="welcomeTitle">memberships Title:</label>
                  <input v-model="updatedData.memberships_title" id="welcomeTitle" type="text" class="form-control bordered-input" />
                </div>
                <div class="form-group">
                  <label for="mainTitle">Main Sub Title:</label>
                  <input v-model="updatedData.memberships_sub_title" id="mainTitle" type="text" class="form-control bordered-input" />
                </div>

                <button type="submit" class="btn btn-outline-danger">Update Data</button>
              </form>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <Card>
            <h4 class="card-title">Membership Icon </h4>
            <div class="d-flex justify-content-start mt-3 mb-3">
              <button @click="openAddModal" class="btn btn-outline-primary">
                <i class="ti-plus"></i> Add Membership Icon
              </button>
            </div>
            <div slot="raw-content" class="table-responsive">
              <!-- Your table and loop code here -->
              <table class="table table-bordered">
                <thead class="bg-light  bold-text">
                  <tr>
                    <th style="font-weight: bold;">#</th>
                    <th style="font-weight: bold;">Icon title</th>
                    <th style="font-weight: bold;">Icon File</th>
                    <th style="font-weight: bold;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(transaction, index) in Myicon" :key="transaction.id">
                    <!-- Your table row code here -->
                    <td class="py-2 pl-6 md-2" style="width: 5%; ">{{ index + 1 }}</td>
                    <td class="py-2 pl-6 md-2" style="width: 15%;">{{ transaction.memberships_icon_title }}</td>
                    <!-- <td style="text-align: center; width: 15%;">
                      <button v-if="transaction.memberships_icon"
                        @click="openFileModal(getImageUrl(transaction.memberships_icon))" class="btn btn-outline-info">
                        <i class="ti-eye"></i> View File
                      </button>
                      <span v-else class="text-muted">No File</span>
                    </td> -->
                    <td style="text-align: center; width: 15%;">
                    <img :src="getImageUrl(transaction.memberships_icon)" @click="openFileModal(getImageUrl(transaction.memberships_icon))" alt="Partner Image" style="max-width: 150px; max-height: 150px;" />
                  </td>
                    <td style="text-align: center; width: 15%;">
                      <button @click="openEditModal(index)" class="btn btn-outline-primary mr-2">
                        <i class="ti-pencil"></i>
                        Edit
                      </button>

                      <button @click="deleteItem(index)" class="btn btn-outline-danger mr-2">
                        <i class="ti-trash"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- End of your table and loop code -->

              <!-- Pagination code if needed -->
              <div class="main-container">
                <div class="pagination-container">
                  <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
                    Prev
                  </div>
                  <div v-else class="btn btn-secondary" disabled>
                    Prev
                  </div>
                  <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
                  <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
                    Next
                  </div>
                  <div v-else @click="nextPage()" class="btn btn-outline-danger">
                    Next
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div v-if="isEditModalOpen" class="modal-overlay" @click="closeEditModal"></div>
        <Modal v-if="isEditModalOpen" @close="closeEditModal">
          <h3>Edit Membership Icon</h3>
          <form @submit.prevent="submitEditForm">
            <div class="form-group">
              <label for="editTitle">Icon Title:</label>
              <input v-model="editFormData.memberships_icon_title" id="editTitle" type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label for="editImage">Icon Image:</label>
              <input type="file" @change="handleEditFileUpload" class="form-control" />
              <!-- Image preview -->
              <div v-if="existingImage || previewImage" class="image-preview">
                <img :src="previewImage || existingImage" alt="Preview Image"
                  style="max-width: 256px; max-height: 256px;" />
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Save Changes</button>
          </form>
        </Modal>


        <div v-if="isAddModalOpen" class="modal-overlay" @click="closeAddModal"></div>
    <Modal v-if="isAddModalOpen" @close="closeAddModal">
      <h3>Add New Membership Icon</h3>
      <form @submit.prevent="submitAddForm">
        <div class="form-group">
          <label for="addTitle">Icon Title:</label>
          <input v-model="addFormData.memberships_icon_title" id="addTitle" type="text" class="form-control" />
        </div>
        <div class="form-group">
          <label for="addImage">Icon Image (Image 176 x 176 px):</label>
          <input type="file" @change="handleAddFileUpload" class="form-control" />
          <!-- Image preview -->
          <div v-if="addPreviewImage" class="image-preview">
            <img :src="addPreviewImage" alt="Preview Image" style="max-width: 256px; max-height: 256px;" />
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Add Data</button>
      </form>
    </Modal>



      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { Card, PaperTable } from "@/components";
import Modal from '@/components/Modal/MembershipsIcon.vue';
import Swal from 'sweetalert2';

const baseURL = "https://api2.logizall.com";
const token = localStorage.getItem('token');
const currentPage = ref(1);
const pageSize = 10; // Set your desired page size
const totalPages = ref(1);
const totalRecords = ref(0);
const Myicon = ref([]);

const membershipsData = ref({});
const updatedData = ref({
  memberships_title: '',
  memberships_sub_title: '',
  imageFile: null,
  // Add other form fields as needed
});

const getmembersData = async () => {
  try {
    const response = await axios.get(`${baseURL}/landingpage/memberships/getmemberships`);
    membershipsData.value = response.data[0];
    updatedData.value = { ...membershipsData.value }; // Assign data to updatedData
    //console.log(membershipsData.value);
  } catch (error) {
    console.error('Error fetching conference data:', error);
  }
};

const geticon = async () => {
  try {
    const response = await axios.get(`${baseURL}/landingpage/memberships/getmembershipsiconpage`, {
      params: {
        page: currentPage.value,
        pageSize: pageSize,
      },
    });
    //console.log("response", response)
    Myicon.value = response.data.data || [];
    totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
    totalRecords.value = response.data.totalRecords || [];
  } catch (error) {
    console.error('Error fetching conference data:', error);
  }
};


const updateconference = async () => {
  try {
    const formData = new FormData();
    formData.append('memberships_title', updatedData.value.memberships_title);
    formData.append('memberships_sub_title', updatedData.value.memberships_sub_title);

    //console.log("formData", formData);
    const response = await axios.put(`${baseURL}/landingpage/memberships/updatememberships`, formData, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    });
    //console.log(response.data.message);

    if (response.data.message === "Data updated successfully.") {
      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Update Successful!',
        text: response.data.message,
      });

      // Refresh data after successful update
      getmembersData();
    } else {
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: response.data.message,
      });
    }
  } catch (error) {
    console.error('Error updating conference data:', error);

    // Show error alert
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while updating conference data.',
    });
  }
};


const prevPage = () => {
  prevOrNextPage('prev');
};

const nextPage = () => {
  prevOrNextPage('next');
};

const prevOrNextPage = (action) => {
  if (action == 'prev') {
    if (currentPage.value > 1) {
      currentPage.value--;
      geticon();
    }
  } else if (action == 'next') {

    //console.log("pageSize", pageSize)
    //console.log("totalRecords", totalRecords.value)
    if (currentPage.value * pageSize <= totalRecords.value) {
      currentPage.value++;
    }
    //console.log("currentPage", currentPage.value)
    geticon();
  }
};

const openFileModal = (fileUrl) => {
  //console.log(fileUrl);
  if (fileUrl) {
    // Check if the file is an image (JPEG, PNG, GIF)
    const isImage = /\.(jpe?g|png|gif)$/i.test(fileUrl);

    if (isImage) {
      // Display the image in a smaller SweetAlert modal
      Swal.fire({
        imageUrl: fileUrl,
        imageAlt: '',
        showCloseButton: true,
        customClass: {
          popup: 'smaller-modal', // Add a custom CSS class for the modal
        },
      });
    } else {
      // Open the PDF in a new window or tab
      window.open(fileUrl, '_blank');
    }
  } else {
    // Handle the case where there is no file
    Swal.fire({
      icon: 'info',
      title: 'No File',
      text: 'There is no file available for this transaction.',
    });
  }
};


const getImageUrl = (filename) => {
  //console.log("landingpage/memberships/image/", filename)
  // Replace 'localhost:4000' with your actual server address
  return `${baseURL}/landingpage/memberships/image/${filename}`;
};

const isEditModalOpen = ref(false);
const editFormData = ref({
  memberships_icon_title: '',
  editImageFile: null,
});
const existingImage = ref('');
const previewImage = ref('');

const openEditModal = (index) => {
  const item = Myicon.value[index];
  editFormData.value = {
    id:item.id,
    memberships_icon_title: item.memberships_icon_title,
  };
  //console.log(editFormData.value.memberships_icon_title)
  existingImage.value = getImageUrl(item.memberships_icon); // Set existing image URL
  isEditModalOpen.value = true;
};

const closeEditModal = () => {
  isEditModalOpen.value = false;
  existingImage.value = ''; // Clear existing image URL when closing modal
  previewImage.value = ''; // Clear preview image URL
};

const handleEditFileUpload = (event) => {
  const file = event.target.files[0];
  editFormData.editImageFile = event.target.files[0];
  //console.log(editFormData)

  // Read the file as a data URL
  const reader = new FileReader();
  reader.onload = () => {
    previewImage.value = reader.result;
  };
  reader.readAsDataURL(file);
};

const submitEditForm = async () => {
  try {
    //console.log(editFormData.value.memberships_icon_title)
    // Validate required data
    if (!editFormData.value.memberships_icon_title) {
      console.error('Error: Missing required data');
      // You might want to display an error message to the user here
      return;
    }

    // Prepare data for API call
    const formData = new FormData();
    formData.append('memberships_icon_title', editFormData.value.memberships_icon_title);
    formData.append('memberships_icon', editFormData.editImageFile);

    // Make the API call
    const response = await axios.put(`${baseURL}/landingpage/memberships/updatemembershipsicon/${editFormData.value.id}`, formData, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    //console.log(response.data.message);

    // Perform additional actions if needed

    // Close the modal
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Data updated successfully!',
    });

    // Reload the window after a short delay (adjust as needed)
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error) {
    console.error('Error submitting edit form:', error);
    // Handle errors or display an error message to the user
  }
};


const deleteItem = async (index) => {
  const itemId = Myicon.value[index].id;

  try {
    const response = await axios.delete(`${baseURL}/landingpage/memberships/deletemembershipsicon/${itemId}`,{
      headers: {
        Authorization: `${token}`,
      },
    });

    if (response.status === 200) {
      // Delete the item from the local array
      Myicon.value.splice(index, 1);
      //console.log('Item deleted successfully');
    } else {
      console.error('Error deleting item:', response.data.error);
    }
  } catch (error) {
    console.error('Error deleting item:', error);
  }
};

const isAddModalOpen = ref(false);
const addFormData = ref({
  memberships_icon_title: '',
});
const addPreviewImage = ref(null);

const openAddModal = () => {
  // Reset form data and preview image when opening the modal
  addFormData.memberships_icon_title = '';

  isAddModalOpen.value = true;
};

const closeAddModal = () => {
  // Logic to close the modal for adding new data
  isAddModalOpen.value = false;
};

const handleAddFileUpload = (event) => {
  // Logic to handle file upload and preview for adding new data
  const file = event.target.files[0];
  addFormData.addImageFile = event.target.files[0];
  if (file) {
    addPreviewImage.value = URL.createObjectURL(file);
  }
};

const submitAddForm = async () => {
  try {
    // Validate required data
    if (!addFormData.value.memberships_icon_title) {
      console.error('Error: Missing required data');
      // You might want to display an error message to the user here
      return;
    }

    // Prepare data for API call
    const formData = new FormData();
    formData.append('memberships_icon_title', addFormData.value.memberships_icon_title);
    formData.append('memberships_icon', addFormData.addImageFile); // Assuming you have a property for the image file

    // Make the API call to add new data
    // Replace the following with your actual API endpoint
    const response = await axios.post(`${baseURL}/landingpage/memberships/createmembershipsicon`, formData, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    //console.log(response.data.message);

    // Perform additional actions if needed

    // Close the modal
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Data added successfully!',
    });

    // Reload the window after a short delay (adjust as needed)
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error) {
    console.error('Error submitting add form:', error);
    // Handle errors or display an error message to the user
  }
};

onMounted(() => {
  geticon();
  getmembersData();
});
</script>


<style scoped>
/* Add additional styles as needed */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.card {
  margin-top: 20px;
}
.bordered-input {
    border: 1px solid #b6b6b6;
  }
</style>
