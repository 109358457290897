<template>
  <div class="row">
    <div class="col-md-12">
      <Card :title="table1.title" :subTitle="table1.subTitle">
        <div slot="raw-content" class="table-responsive">
          <button class="btn btn-outline-default mr-3 ml-3 mt-2" @click="openUpdateStatusModal">
            <i class="ti-settings mr-2"></i> Invoice Management
          </button>
          <div class="mb-3 d-flex justify-content-end">
            <div class="mb-3 d-flex justify-content-start ">


            </div>
            <form class="form-inline">
              <div class="form-group mr-3">
                <v-select v-model="selectedOption" :options="filteredOptions" label="company_name_en"
                  placeholder="Select account" @input="handleChange" style="width: 250px;"></v-select>
              </div>
              <div class="form-group mr-3">
                <select v-model="selectedFilterType"
                  class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                  style="width: 100px; height: 38px;">
                  <option value="1">ALL</option>
                  <option value="Invoice">INVOICE</option>
                  <option value="FPS">FPS</option>
                  <!-- Add more options as needed -->
                </select>
              </div>

              <div class="form-group mr-3">
                <select v-model="selectedFilter"
                  class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                  style="width: 200px; height: 38px;">
                  <option value="selected_month">Selected Month</option>
                  <option value="selected_year">Selected Year</option>
                  <option value="selected_day">Selected Day</option>
                  <!-- Add more options as needed -->
                </select>
              </div>

              <div class="form-group mr-3">
                <div v-if="selectedFilter === 'selected_day'">
                  <input type="date" v-model="searchQuery" placeholder="Select Date..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" />
                </div>

                <div v-else-if="selectedFilter === 'selected_month'">
                  <input type="month" v-model="searchQuery" placeholder="Select Month..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" />
                </div>
                <div v-else-if="selectedFilter === 'selected_year'">
                  <input type="number" v-model="searchQuery" placeholder="Select year..."
                    class="bea-select text-sm rounded-md border-transparent placeholder-gray-400"
                    style="width: 200px; height: 38px;" />
                </div>
              </div>

              <button @click="search(selectedFilter, searchQuery, selectedFilterType)"
                class="btn btn-outline-danger mr-2 ">
                <i class="ti-search"></i> Search
              </button>

              <button @click="getDataAndGenerateFpsPDF" class="btn btn-outline-default mr-2 ">
                <i class="ti-printer"></i> Print Report
              </button>
              <button @click="reload()" class="btn btn-outline-info mr-2 ">
                <i class="ti-reload"></i>
              </button>
            </form>
          </div>
          <table class="table table-bordered">
            <thead class="bg-light  bold-text">
              <tr>
                <th style="font-weight: bold;">#</th>
                <th style="font-weight: bold;font-size: medium">Type</th>
                <th style="font-weight: bold;font-size: medium">Invoice Number</th>
                <th style="font-weight: bold;font-size: medium">Company</th>

                <th style="font-weight: bold;font-size: medium">Issued To</th>
                <th style="font-weight: bold;font-size: medium">Issue Date</th>
                <th style="font-weight: bold;font-size: medium">Due Date</th>
                <th style="font-weight: bold; font-size: medium"> Currency</th>
                <th style="font-weight: bold; font-size: medium">Amount Due</th>
                <th style="font-weight: bold; font-size: medium">Charge(%)</th>
                <th style="font-weight: bold;font-size: medium">Total Amount</th>
                <th style="font-weight: bold;font-size: medium">Note</th>
                <th style="font-weight: bold;font-size: medium">Remark</th>


                <th style="font-weight: bold;font-size: medium">Charge</th>
                <th style="font-weight: bold;font-size: medium">Invoice File</th>
                <th style="font-weight: bold;font-size: medium">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction, index) in Myinvoice" :key="transaction.id">
                <td class="py-2 pl-6 md-2 " style="width: 1%; ">{{ index + 1 }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width: 8%;">{{ transaction.Invoice_type }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width: 5%;">{{ transaction.invoiceNo }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width: 15%; ">{{ transaction.company_name }}</td>
                <td class="py-2 pl-6 md-2 text-left" style="width: 15%;">{{ transaction.issuedTo }}</td>
                <td class="py-2 pl-6 md-2" style="width: 8%;">{{ formatDate(transaction.issueDate) }}</td>
                <td class="py-2 pl-6 md-2 " style="width: 8%;">{{ formatDate(transaction.dueDate)}}</td>
                <td class="py-2 pl-6 md-2 " style="width: 3%;">{{ transaction.currency }}</td>
                <td class="py-2 pl-6 md-2 text-right" style="width: 3%;">{{
        parseFloat(transaction.amountDue).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) }}</td>

                <td class="py-2 pl-6 md-2 " style="width: 3%;">{{ transaction.service_charge ?
        parseFloat(transaction.service_charge_amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + ("(" + transaction.service_charge + "%)") : '-' }}</td>
                <td v-if="transaction.service_charge_amount" class="py-2 pl-6 md-2 text-right" style="width: 5%;">{{
        parseFloat(transaction.totalamount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) }}</td>
                <td v-else class="py-2 pl-6 md-2 text-right" style="width: 5%;">-</td>
                <td class="py-2 pl-6 md-2 text-left" style="width: 15%;">{{ transaction.note }}</td>
                <td
                  v-if="((transaction.approve_status == 2 && transaction.fps_status == 'Active') || (transaction.approve_status == 1 && transaction.fps_status == 'Active') || (transaction.approve_status == 0 && transaction.fps_status == 'Active'))"
                  class="py-2 pl-6 md-2 text-left" style="width: 15%;">- Active by {{ transaction.fps_status_by }}</td>
                <td v-else-if="(transaction.approve_status == 0 && transaction.fps_status == 'Pending')"
                  class="py-2 pl-6 md-2 text-left" style="width: 15%;">-</td>
                <td v-else class="py-2 pl-6 md-2 text-left" style="width: 15%;">- InActive by {{
        transaction.fps_status_by }}</td>


                <td v-if="(transaction.approve_status == 0 && transaction.fps_status == 'Pending')"
                  style="text-align: center; width: 5%;">
                  <button @click="openEditServicechargeModal(index)" class="btn btn-outline-default ">
                    <i class="ti-pencil"></i>
                    Charge
                  </button>

                </td>
                <td v-else style="text-align: center; width: 5%;">
                  <button v-if="transaction.invoiceFile"
                    @click="openFileModal(getImageUrlFPS(transaction.attached_file))" class="btn btn-outline-default">
                    <i class="ti-eye"></i> View File
                  </button>

                </td>

                <td style="text-align: center; width: 5%;">
                  <button v-if="transaction.invoiceFile" @click="showWithDrawModal(transaction.invoiceFile)"
                    class="btn btn-outline-info">
                    <i class="ti-eye"></i> View File
                  </button>
                  <span v-else class="text-muted">No File</span>
                </td>
                <td style="text-align: center; width: 5%;">
                  <button :style="{
        backgroundColor: getStatusColor(transaction.fps_status),
        color: 'white',
        padding: '0.5rem',
        borderRadius: '5px',
        border: '1px solid ' + getStatusColor(transaction.fps_status),
        cursor: 'pointer'
      }" class="btn btn-outline" @click="changeStatus(transaction)"
                    :disabled="transaction.fps_status === 'Active' || transaction.fps_status === 'Inactive'">

                    {{ transaction.fps_status }}
                  </button>
                </td>

              </tr>
            </tbody>
          </table>
          <div class="main-container">
            <!-- ... (your existing template code) ... -->
            <div class="pagination-container">
              <div v-if="currentPage > 1" @click="prevPage()" class="btn btn-outline-danger">
                Prev
              </div>
              <div v-else class="btn btn-secondary" disabled>
                Prev
              </div>
              <div class="px-4 py-2">{{ currentPage }}/{{ totalPages }}</div>
              <div v-if="currentPage == totalPages" class="btn btn-secondary" disabled>
                Next
              </div>
              <div v-else @click="nextPage()" class="btn btn-outline-danger">
                Next
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div v-if="isEditServicechargeModalOpen" class="modal-overlay" @click="closeEditServicechargeModal"></div>
      <Modal v-if="isEditServicechargeModalOpen" @close="closeEditServicechargeModal">
        <h3>Service Charge</h3>
        <form @submit.prevent="submitEditServicechargeForm">
          <div class="form-group">
            <label for="servicecharge">Service Charge (%):</label>
            <select v-model="editServicechargeFormData.servicecharge" class="form-control bordered-input p-2" required>
              <option v-for="(option, index) in fpsOptions" :value="option.servicecharge">{{
        option.servicecharge + "%" }}</option>
            </select>
          </div>


          <div class="form-group">
            <label for="editServicechargeFormData">SVC File:</label>
            <input type="file" @change="handleAddFileUpload" class="form-control" />
            <div v-if="editServicechargeFormData.attached_file">
              <p>File: {{ editServicechargeFormData.attached_file }}</p>
            </div>
            <button v-if="editServicechargeFormData.attached_file"
              @click.prevent="openFileModal(getImageUrlFPS(editServicechargeFormData.attached_file))"
              class="btn btn-outline-default">
              <i class="ti-eye"></i> View File
            </button>
          </div>



        </form>
        <button @click="submitEditServicechargeForm" class="btn btn-outline-success mr-2 mb-3 w-full">Charge</button>

      </Modal>

      <div v-if="isUpdateStatusModalOpen" class="modal-overlay" @click="closeUpdateStatusModal"></div>
      <AccountingModal v-if="isUpdateStatusModalOpen" @closeModal="closeUpdateStatusModal" />

      <div v-if="showTranfer" class="modal-overlay" @click="closeWithDrawModal"></div>
      <Modal v-if="showTranfer" @close="closeWithDrawModal">
        <h5>View File</h5>


        <div class="form-group">
          <div v-for="(fileName, index) in fileToDisplay" :key="index">
            <span @click="openFileModal(getImageUrl(fileName))" class="file-name" style="cursor: pointer;">{{ fileName
              }} </span>
          </div>
        </div>



      </Modal>
      <!-- <div v-if="showTranfer" class="modal-overlay" @click="closeWithDrawModal"></div> -->
      <!-- Modal for transfer form -->
      <!-- <WithDrawModal v-if="showTranfer" :fileToDisplay="fileToDisplay" @closeModal="closeWithDrawModal" /> -->
    </div>
  </div>
  <!-- Edit Servicecharge Modal -->


</template>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { Card, PaperTable } from "@/components";
import Swal from 'sweetalert2';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
import Modal from '@/components/Modal/MembershipsIcon.vue';
import AccountingModal from '@/components/Modal/AccountingModal.vue';
import WithDrawModal from '@/components/Modal/FileModal.vue'

const selectedFilter = ref('selected_month');
const baseURL = "https://api2.logizall.com";
const Myinvoice = ref([]);
const Quertsearch = ref()
const token = localStorage.getItem('token');
const table1 = ref({
  title: "Member Invoice",
  subTitle: "Member Invoice",

});

const fileToDisplay = ref({});

const showTranfer = ref(false);
const showWithDrawModal = (file) => {
  const fileNames = file.split(',').map(name => name.trim());
  fileToDisplay.value = fileNames;
  console.log(fileToDisplay.value);
  showTranfer.value = true;
};

const closeWithDrawModal = () => {
  showTranfer.value = false;
};



const currentPage = ref(1);
const pageSize = 10; // Set your desired page size
const totalPages = ref(1);
const totalRecords = ref(0);
const isSearch = ref(false);
const selectedFilterType = ref('1');

const changeStatus = async (transaction) => {
  try {
    if (transaction.service_charge !== null && transaction.attached_file !== null) {
      const result = await Swal.fire({
        title: 'Change Status',
        text: 'Select the new status:',
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Active',
        denyButtonText: 'Inactive',
        cancelButtonText: 'Cancel',
      });

      if (result.isConfirmed) {
        updateStatus(transaction, 'Active');
      } else if (result.isDenied) {
        updateStatus(transaction, 'Inactive');
      } else if (result.isDismissed && result.dismiss === 'cancel') {
        // Do nothing
      } else {
        // Do nothing
      }
    }  else if (transaction.service_charge == 0 ) {
      const result = await Swal.fire({
        title: 'Change Status',
        text: 'Select the new status:',
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Active',
        denyButtonText: 'Inactive',
        cancelButtonText: 'Cancel',
      });

      if (result.isConfirmed) {
        updateStatus(transaction, 'Active');
      } else if (result.isDenied) {
        updateStatus(transaction, 'Inactive');
      } else if (result.isDismissed && result.dismiss === 'cancel') {
        // Do nothing
      } else {
        // Do nothing
      }
    }else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please Update your Charger and Attach file before Activating.',
      });
    }
  } catch (error) {
    console.error('Error updating status:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while updating the status.',
    });
  }
};


const updateStatus = async (transaction, newStatus) => {
  try {
    //console.log(token);
    //console.log("log", transaction.id, newStatus);

    const response = await axios.put(
      `${baseURL}/admin/updateStatus/${transaction.id}`,
      {
        newStatus: newStatus,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    //console.log(response);

    // Handle the response accordingly
    // You may want to refresh the data or update the status directly in the component
    // For example: transaction.fps_status = response.data.newStatus;
    Swal.fire('Success', `The status has been changed to ${newStatus} successfully.`, 'success')
      .then(() => {
        // Reload the page after the user clicks "OK" on the success message
        window.location.reload();
      });
  } catch (error) {
    console.error('Error updating status:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while updating the status.',
    });
  }
};


const getMyinvoice = async () => {
  try {

    const response = await axios.get(`${baseURL}/admin/getfps`, {
      params: {
        page: currentPage.value,
        pageSize: pageSize,
      },
      headers: {
        Authorization: token,
      },
    });
    console.log("response", response)
    Myinvoice.value = response.data.data || [];
    totalPages.value = Math.ceil(response.data.totalRecords / pageSize);
    totalRecords.value = response.data.totalRecords || [];
  } catch (error) {
    console.error('Error fetching My Wallet data:', error);
    // Handle the error appropriately, e.g., show an error message to the user.
  }
};
const prevPage = () => {
  prevOrNextPage('prev');
};

const nextPage = () => {
  prevOrNextPage('next');
};

const prevOrNextPage = (action) => {
  if (action == 'prev') {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
  } else if (action == 'next') {

    //console.log("pageSize",pageSize)
    //console.log("totalRecords",totalRecords.value)
    if (currentPage.value * pageSize <= totalRecords.value) {
      currentPage.value++;
    }
    //console.log("currentPage",currentPage.value)
  }


  // Check if it's a search or regular page navigation
  if (isSearch.value) {
    //console.log("isSearch",isSearch.value)
    search(selectedFilter.value, Quertsearch.value, selectedFilterType.value);
  } else {
    getMyinvoice();
  }
};

const search = async (dateFilter, searchQuery, selectedtype) => {
  console.log("search")
  // console.log("search");
  // console.log("dateFilter", dateFilter);
  // console.log("selectedDate", searchQuery);
  Quertsearch.value = searchQuery;
  // console.log("Quertsearch", Quertsearch.value);
  // console.log("selectedOption", selectedOption.value);
  try {
    // Check if selectedFilter is null
    if (dateFilter == "selected_month" && searchQuery == null && selectedtype == 1 && Quertsearch.value == null && selectedOption.value == null) {
      getMyinvoice();
      try {
        console.log("try pdf")
        const responsepdf = await axios.get(`${baseURL}/admin/getfpsbysearchpdf`, {
          params: { dateFilter, selectedDate: searchQuery, selectedFilterType: selectedtype },
          headers: {
            Authorization: token,
          },
        });
        const datapdf = responsepdf.data.data;
        const totalAmount = responsepdf.data.totalAmountDue || 0;
        // เพิ่มลำดับในข้อมูล
        const dataWithIndex = datapdf.map((record, index) => {
          return {
            ลำดับ: index + 1,
            company_name: record.company_name,
            invoiceNo: record.invoiceNo,
            issuedTo: record.issuedTo,
            issueDate: record.issueDate,
            dueDate: record.dueDate,
            currency: record.currency,
            amountDue: parseFloat(record.amountDue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            note: record.note,
            fps_status: record.fps_status,

          };
        });
        summaryData.value = parseFloat(totalAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0;
        pdfContent.value = generatePDFContent(dataWithIndex);
        fetchedData.value = dataWithIndex;
        // printReport()

      } catch (error) {
        console.error('Error retrieving data or generating PDF', error);
      }
    }
    else {
      if (selectedOption.value === null) {
        // Perform the search using the original API
        const response = await axios.get(`${baseURL}/admin/getfpsbysearch`, {
          params: { dateFilter, selectedDate: searchQuery, selectedFilterType: selectedtype, page: currentPage.value, pageSize: pageSize },
          headers: {
            Authorization: token,
          },
        });

        // Destructure the response data
        const { data, total } = response.data;




        // Update values
        Myinvoice.value = data || [];
        totalRecords.value = total || [];

        // Set the search flag to true
        isSearch.value = true;
        totalPages.value = Math.ceil(response.data.total / pageSize);
        // Update the current page to 1, as the search results start from the first page


        try {
          console.log("try pdf")
          const responsepdf = await axios.get(`${baseURL}/admin/getfpsbysearchpdf`, {
            params: { dateFilter, selectedDate: searchQuery, selectedFilterType: selectedtype },
            headers: {
              Authorization: token,
            },
          });
          const datapdf = responsepdf.data.data;
          const totalAmount = responsepdf.data.totalAmountDue || 0;
          // เพิ่มลำดับในข้อมูล
          const dataWithIndex = datapdf.map((record, index) => {
            return {
              ลำดับ: index + 1,
              company_name: record.company_name,
              invoiceNo: record.invoiceNo,
              issuedTo: record.issuedTo,
              issueDate: record.issueDate,
              dueDate: record.dueDate,
              currency: record.currency,
              amountDue: parseFloat(record.amountDue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              note: record.note,
              fps_status: record.fps_status,

            };
          });
          summaryData.value = parseFloat(totalAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0;
          pdfContent.value = generatePDFContent(dataWithIndex);
          fetchedData.value = dataWithIndex;
          // printReport()

        } catch (error) {
          console.error('Error retrieving data or generating PDF', error);
        }
      } else {
        // Perform the search using the new API
        console.log("selectedOption.value", selectedOption.value.id)
        const response = await axios.get(`${baseURL}/admin/getfpsbysearchmid/${selectedOption.value.id}`, {
          params: { dateFilter, selectedDate: searchQuery, selectedFilterType: selectedtype, page: currentPage.value, pageSize: pageSize },
          headers: {
            Authorization: token,
          },
        });

        // Destructure the response data
        const { data, total } = response.data;

        // Update values
        Myinvoice.value = data || [];
        totalRecords.value = total || [];

        // Set the search flag to true
        isSearch.value = true;
        totalPages.value = Math.ceil(response.data.total / pageSize);

        // Update the current page to 1, as the search results start from the first page
        try {
          console.log("try pdf")
          const responsepdf = await axios.get(`${baseURL}/admin/getfpsbysearchmidpdf/${selectedOption.value.id}`, {
            params: { dateFilter, selectedDate: searchQuery, selectedFilterType: selectedtype },
            headers: {
              Authorization: token,
            },
          });
          const datapdf = responsepdf.data.data;
          const totalAmount = responsepdf.data.totalAmountDue || 0;
          // เพิ่มลำดับในข้อมูล
          const dataWithIndex = datapdf.map((record, index) => {
            return {
              ลำดับ: index + 1,
              company_name: record.company_name,
              invoiceNo: record.invoiceNo,
              issuedTo: record.issuedTo,
              issueDate: record.issueDate,
              dueDate: record.dueDate,
              currency: record.currency,
              amountDue: parseFloat(record.amountDue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              note: record.note,
              fps_status: record.fps_status,

            };
          });
          summaryData.value = parseFloat(totalAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0;
          pdfContent.value = generatePDFContent(dataWithIndex);
          fetchedData.value = dataWithIndex;
          // printReport()

        } catch (error) {
          console.error('Error retrieving data or generating PDF', error);
        }
      }
    }
  } catch (error) {
    console.error('Error fetching accounting data:', error);
    // Handle the error appropriately, e.g., show an error message to the user.
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while fetching data.',
    });
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};
const openFileModal = (fileUrl) => {
  console.log(fileUrl);
  if (fileUrl) {
    // Check if the file is an image (JPEG, PNG, GIF)
    const isImage = /\.(jpe?g|png|gif)$/i.test(fileUrl);

    if (isImage) {
      // Display the image in a smaller SweetAlert modal
      Swal.fire({
        imageUrl: fileUrl,
        imageAlt: 'FPS',
        showCloseButton: true,
        customClass: {
          popup: 'smaller-modal', // Add a custom CSS class for the modal
        },
      });
    } else {
      // Open the PDF in a new window or tab
      window.open(fileUrl, '_blank');
    }
  } else {
    // Handle the case where there is no file
    Swal.fire({
      icon: 'info',
      title: 'No File',
      text: 'There is no file available for this transaction.',
    });
  }
};


const getImageUrl = (filename) => {
  console.log("/fps/images/FPS/", filename)
  // Replace 'localhost:4000' with your actual server address
  return `${baseURL}/fps/images/FPS/${filename}`;
};

const getImageUrlFPS = (filename) => {
  console.log("/admin/images/FPS/Servicecharge/", filename)
  // Replace 'localhost:4000' with your actual server address
  return `${baseURL}/admin/images/FPS/Servicecharge/${filename}`;
};
const getStatusColor = (status) => {
  switch (status) {
    case 'Pending':
      return 'orange';
    case 'Active':
      return 'green';
    default:
      return 'red';
  }
};

const selectedOption = ref(null);
const filteredOptions = ref([]);
const options = ref([]);
const handleChange = (value) => {
  // Handle the selected value here
  //console.log('Selected Option:', value);
  // You can perform additional actions or update other variables as needed
};
const fetchTransfer = async () => {
  try {
    let currentPage = 1;
    let totalPages = 1;
    let allOptions = []; // เพิ่มตัวแปรเก็บข้อมูลทั้งหมด

    do {
      const response = await axios.get(`https://api.logizall.com/api/customers?pagination%5Bpage%5D=${currentPage}&pagination%5BpageSize%5D=100`);
      //console.log("response", response.data.meta.pagination.pageCount);

      totalPages = response.data.meta.pagination.pageCount;

      const options = response.data.data
        .filter(option => option.attributes.status === "bronze") // Filter out only active data
        .map(option => ({
          id: option.id,
          company_name_en: option.attributes.companyNameEn
        }));

      allOptions = allOptions.concat(options); // เพิ่ม options เข้าไปใน allOptions

      currentPage++;

    } while (currentPage <= totalPages);

    filteredOptions.value = allOptions; // ใส่ข้อมูลทั้งหมดลงใน filteredOptions.value
    //console.log(filteredOptions.value)
  } catch (error) {
    console.error('Error fetching balance types:', error);
  }
};

const reload = async () => {
  fetchTransfer();
  getMyinvoice();
  getservicecharge()
};
onMounted(() => {
  fetchTransfer();
  getMyinvoice();
  getservicecharge()
});


const pdfContent = ref('');
const summaryData = ref('');
const fetchedData = ref([]);
const tableHeaders = ref(['No', 'Company Name', 'InvoiceNo', 'IssuedTo', 'IssueDate', 'DueDate', 'Currency', 'Amount Due', 'Note', 'Status']);
let searchQuery

const getDataAndGenerateFpsPDF = async () => {
  try {
    if (isSearch.value == false) {
      //console.log("isSearch",isSearch.value)
      const response = await axios.get(`${baseURL}/admin/getfpspdf`, {
        headers: {
          Authorization: token,
        },
      });

      const data = response.data.data;
      const totalAmountDue = response.data.summary.totalAmountDue || 0;

      // เพิ่มลำดับในข้อมูล
      const dataWithIndex = data.map((record, index) => {
        return {
          ลำดับ: index + 1,
          company_name: record.company_name,
          invoiceNo: record.invoiceNo,
          issuedTo: record.issuedTo,
          issueDate: record.issueDate,
          dueDate: record.dueDate,
          currency: record.currency,
          amountDue: parseFloat(record.amountDue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          note: record.note,
          fps_status: record.fps_status,

        };
      });


      // เพิ่ม totalAmountDue ใน summary
      const summary = {
        totalAmountDue: totalAmountDue,
        // สามารถเพิ่ม properties อื่น ๆ ของ summary ตามที่ต้องการ
      };
      //console.log("sum",summary.totalAmountDue[0].totalAmountDue)
      summaryData.value = parseFloat(summary.totalAmountDue[0].totalAmountDue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      pdfContent.value = generatePDFContent(dataWithIndex, summary);
      fetchedData.value = dataWithIndex;
      printReport();
    } else {
      printReport();
    }
  } catch (error) {
    console.error('Error retrieving data or generating PDF', error);
  }
};



const generatePDFContent = (data) => {
  let content = 'ข้อมูลจาก API:\n\n';
  data.forEach((record, index) => {
    // console.log(formatDate(record.dateCreate))
    // Format ข้อมูลวันที่ด้วย formatDate function
    const formattedDateCreate = formatDate(record.dateCreate);

    content += `Record ${index + 1}:\n`;
    for (const [key, value] of Object.entries(record)) {
      // เช็คว่า key เป็น 'dateCreate' หรือไม่ ถ้าใช่ให้ใช้ formattedDateCreate
      const displayValue = key == 'dateCreate' ? formattedDateCreate : value;
      // console.log("displayValue",displayValue)
      content += `${key}: ${displayValue}\n`;
    }
    content += '\n';
  });
  content += 'Summary:\n';
  for (const [key, value] of Object.entries(summaryData)) {
    content += `${key}: ${value}\n`;
  }
  return content;
};




const printReport = () => {
  if (pdfContent.value && fetchedData.value && summaryData) {
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Print Report</title>');
    // Add custom styles for the table
    printWindow.document.write(`
      <style>
        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 40px;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
          font-size: 12px; 
        }
        th {
          background-color: #f2f2f2;
        }
        /* Add a specific style for the summary row */
        .summary-row {
          font-weight: bold;
          background-color: #e6e6e6; /* Light gray background color */
        }
      </style>
    `);
    printWindow.document.write('</head><body>');

    // Create the table
    printWindow.document.write('<table>');
    printWindow.document.write('<thead><tr>');
    // Use tableHeaders instead of fetching from the first record
    tableHeaders.value.forEach(header => {
      printWindow.document.write(`<th>${header}</th>`);
    });
    printWindow.document.write('</tr></thead><tbody>');

    // Populate the table with data
    fetchedData.value.forEach((record, index) => {
      printWindow.document.write('<tr>');
      for (const [key, value] of Object.entries(record)) {
        // Check if the key is 'dateTransfer', 'balance_type', 'datePublic', or other specific keys
        const displayValue = key === 'dateTransfer' ? formatDate(value) :
          (key === 'balance_type' ? (value === 1 ? 'Deposit' : value) :
            (key === 'datePublic' ? formatDate(value) : value));
        printWindow.document.write(`<td>${displayValue}</td>`);
      }
      printWindow.document.write('</tr>');
    });

    // Add the summary row with enhanced styling
    tableHeaders.value.forEach(header => {
      printWindow.document.write(`<th>${header}</th>`);
    });
    printWindow.document.write('<tr class="summary-row">');
    for (const [key, value] of Object.entries(summaryData)) {
      // Handle display logic for summary keys if needed
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td>Summary</td>`);
      printWindow.document.write(`<td>${value}</td>`);
      printWindow.document.write(`<td></td>`);
      printWindow.document.write(`<td></td>`);
    }
    printWindow.document.write('</tr>');

    printWindow.document.write('</tbody></table>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  } else {
    console.warn('No content to print. Generate a report first.');
  }
};

const selectedFPSOptions = ref([]);
const fpsOptions = ref([]);

const getservicecharge = async (value) => {


  try {
    // console.log("selectedOption.value",selectedOption.value.id)
    const response = await axios.get(`${baseURL}/admin/getservicecharge`, {
      headers: {
        'Authorization': token,
      },
    });
    // console.log("response getallfps", response.data);
    fpsOptions.value = response.data.servicechargeUsers;
    console.log("fpsOptions", fpsOptions.value);
  } catch (error) {
    console.error('Error fetching customers:', error);
  }
  // You can perform additional actions or update other variables as needed
};

const isEditServicechargeModalOpen = ref(false);
const editServicechargeFormData = {
  id: '',
  servicecharge: null,
  attached_file: null
};
const openEditServicechargeModal = (index) => {
  const servicecharge = Myinvoice.value[index];
  editServicechargeFormData.id = servicecharge.id;
  editServicechargeFormData.servicecharge = servicecharge.service_charge ? servicecharge.service_charge : fpsOptions.value[0].servicecharge;
  editServicechargeFormData.attached_file = servicecharge.attached_file;
  isEditServicechargeModalOpen.value = true;
  console.log("servicecharge", servicecharge);
};


const closeEditServicechargeModal = () => {
  resetEditServicechargeFormData();
  isEditServicechargeModalOpen.value = false;
};
const resetEditServicechargeFormData = () => {
  editServicechargeFormData.id = '';
  editServicechargeFormData.servicecharge = '';
  editServicechargeFormData.attached_file = null;
};
const submitEditServicechargeForm = async () => {
  try {
    const confirmed = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'You are about to edit this servicecharge.',
      showCancelButton: true,
      confirmButtonText: 'Yes, edit it!',
      cancelButtonText: 'No, cancel!',
    });
    console.log(editServicechargeFormData)
    if (confirmed.isConfirmed) {
      const formData = new FormData();
      formData.append('id', editServicechargeFormData.id);
      formData.append('servicecharge', editServicechargeFormData.servicecharge);
      formData.append('attached_file', editServicechargeFormData.attached_file);
      const response = await axios.put(`${baseURL}/admin/getfps/updateservicecharge/${editServicechargeFormData.id}`, formData, {
        headers: {
          Authorization: `${token}`,
        },
      });
      getMyinvoice();
      closeEditServicechargeModal();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
    }
  } catch (error) {
    console.error('Error updating servicecharge:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to update servicecharge. Please try again.',
    });
  }
};

const handleAddFileUpload = (event) => {
  // Logic to handle file upload and preview for adding new data
  const file = event.target.files[0];
  editServicechargeFormData.attached_file = event.target.files[0];
  // if (file) {
  //   addPreviewImage.value = URL.createObjectURL(file);
  // }
};

const isUpdateStatusModalOpen = ref(false);

// Method to open the update status modal
const openUpdateStatusModal = () => {
  isUpdateStatusModalOpen.value = true;
};

// Method to close the update status modal
const closeUpdateStatusModal = () => {
  isUpdateStatusModalOpen.value = false;
};



</script>


<style>
.file-name:hover {
    color: red; /* เปลี่ยนสีของชื่อไฟล์เมื่อโฮเวอร์อยู่ที่นั้น */
}
.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.search-container {
  display: flex;
  /* align-items: right; */
  margin-left: auto;
}


.pagination-container {
  display: flex;
  justify-content: flex-end;
  /* Align items at the start of the container */
  align-items: stretch;
  /* Stretch items vertically */
  text-align: center;
  /* Center text within each item */
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.smaller-modal {
  max-width: 50%;
  /* Adjust the maximum width as needed */
}

.bea-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  /* Adjust the font size as needed */
  color: #333;
  /* Text color */

  width: 200px;
}

.bea-select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  /* Adjust the font size as needed */
  color: #333;
  /* Text color */
  width: 200px;
}

.bold-text {
  font-weight: bold;
}
</style>
